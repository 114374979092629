import React, { Component, ReactElement, ReactNode } from 'react';

class Obfuscation extends Component<ObfuscationProps, State> {

    handleClick(event: any): void {
        const { link } = this.props;
        event.preventDefault();
        if (link) {
            window.location.href = link;
        }
    }

    renderLink(): ReactElement {
        const { link, children, ...others } = this.props;
        return (
            <a href={link} {...others}>
                {children}
            </a>
        );
    }

    renderObfuscatedLink(): ReactElement {
        const { obfuscationString, children, ...others } = this.props;
        return (
            <a onClick={this.handleClick.bind(this)} href={obfuscationString} {...others}>
                {children}
            </a>
        );
    }

    render(): ReactElement {
        const { obfuscate } = this.props;
        return obfuscate ?
            this.renderObfuscatedLink() :
            this.renderLink();
    }
}

interface State {
}

export type ObfuscationProps = {
    obfuscate: boolean;
    children: ReactNode,
    link: string,
    obfuscationString: string
};

export default Obfuscation;
