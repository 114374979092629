import React, { Component } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import LineAwesome from '../LineAwesome/LineAwesome';
import DropDownTitle from '../Dropdown/DropDownTitle';
import UserActions from '../../stores/user/actions';
import BasketAction from '../../stores/basket/actions';
import CheckoutAction from '../../stores/checkout/actions';
import BookmarkCollectionAction from '../../stores/bookmarkCollections/actions';
import DSGVOAction from '../../stores/dsgvo/actions';

class CustomNavigation extends Component {

  constructor(props) {
    super(props);

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleDSGVOClick = this.handleDSGVOClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLinkClick() {
    if (typeof this.props.onLinkClick === 'function') {
      this.props.onLinkClick();
    }
  }

  handleLogout() {
    this.props.logout();
    this.props.clearBasket();
    this.props.clearCollections();
    this.props.clearCheckout();
    this.props.history.push('/');
  }

  handleDSGVOClick() {
    this.props.setCookieOverlayState(true);
  }


  render() {
    return (
      <Nav className='ml-auto' onSelect={this.props.handleSelect}>
        {this.props.isMobiel ?
          this.props.isLoggedIn ?
            <NavDropdown
              className='login-nav-item'
              alignRight
              title={
                <><LineAwesome
                  icon='user' />&nbsp;
                  <span>{`${this.props.user.FirstName ?? ''  } ${  this.props.user.Surname ?? ''}`}</span>
                </>
              }
              id='account'>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/orders'>
                <LineAwesome icon='clipboard-list' /> <Trans i18nKey="navbar.orders">Bestellungen</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/bookmarks'>
                <LineAwesome icon='certificate' /> <Trans i18nKey="navbar.bookmarks">Merklisten</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/'>
                <LineAwesome icon='user' /> <Trans i18nKey="navbar.accountManagement">Accountverwaltung</Trans>
              </NavDropdown.Item>
              {this.props.user.VendorID !== 0 ?
                <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                  to='/myaccount/vendorregistration'>
                  <LineAwesome icon='home' />
                   <Trans i18nKey="navbar.editVendorData">Unternehmerdaten bearbeiten</Trans>
                </NavDropdown.Item>
                :
                null
              }
              <NavDropdown.Item
                onClick={this.handleDSGVOClick}
              >
                <LineAwesome icon='cookie' /> <Trans i18nKey="navbar.cookieSettings">Cookie Einstellungen</Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  this.handleLogout();
                  this.handleLinkClick();
                }}
              >
                <LineAwesome icon='sign-out-alt' />  <Trans i18nKey="navbar.logout">Logout</Trans>
              </NavDropdown.Item>
            </NavDropdown>
            :
            <Nav.Link as={NavLink} activeClassName='active' className='login-nav-item' to='/login'
                      onClick={this.handleLinkClick}>
              <LineAwesome icon='sign-in-alt' />  <Trans i18nKey="navbar.loginRegister">Login / Registrieren</Trans>
            </Nav.Link>
          :
          null
        }
        <Nav.Link onClick={this.handleLinkClick} as={NavLink} exact activeClassName='active' to='/'>
          <Trans i18nKey="navbar.home">Home</Trans>
        </Nav.Link>
        <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName='active' to='/region'>
          <Trans i18nKey="navbar.region">Region</Trans>
        </Nav.Link>
        <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName='active' to='/produkte'>
          <Trans i18nKey="navbar.onlineShop">Onlineshop</Trans>
        </Nav.Link>
        <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName='active' to='/händlerübersicht'>
          <Trans i18nKey="navbar.operators">Unternehmer</Trans>
        </Nav.Link>
        <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName='active' to='/händler-werden'>
          <Trans i18nKey="navbar.information">Informationen</Trans>
        </Nav.Link>
        {this.props.isMobiel ?
          <>
            <div className='pt-3 w-100' />
            <Nav.Link className='mobile-footer-links' onClick={this.handleLinkClick} as={NavLink}
                      activeClassName='active' to='/content/impressum'>
              <Trans i18nKey="navbar.imprint">Impressum</Trans>
            </Nav.Link>
            <Nav.Link className='mobile-footer-links' onClick={this.handleLinkClick} as={NavLink}
                      activeClassName='active' to='/content/datenschutzerklaerung'>
              <Trans i18nKey="navbar.dsgvo">DSGVO</Trans>
            </Nav.Link>
            <Nav.Link className='mobile-footer-links' onClick={this.handleLinkClick} as={NavLink}
                      activeClassName='active' to='/content/agb-2'>
              <Trans i18nKey="navbar.agb">AGB</Trans>
            </Nav.Link>
          </>
          :
          <>
            {this.props.isLoggedIn ?
              <NavDropdown
                className='login-nav-item'
                alignRight
                title={
                  <><LineAwesome
                    icon='user' />&nbsp;
                    <span>{`${this.props.user.FirstName ?? ''  } ${  this.props.user.Surname ?? ''}`}</span>
                  </>
                }
                id='account'>
                <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                  to='/myaccount/orders'>
                  <LineAwesome icon='clipboard-list' /> <Trans i18nKey="navbar.orders">Bestellungen</Trans>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                  to='/myaccount/bookmarks'>
                  <LineAwesome icon='certificate' /> <Trans i18nKey="navbar.bookmarks">Merklisten</Trans>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                  to='/myaccount/'>
                  <LineAwesome icon='user' /> <Trans i18nKey="navbar.accountManagement">Accountverwaltung</Trans>
                </NavDropdown.Item>
                {this.props.user.VendorID !== 0 ?
                  <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                    to='/myaccount/vendorregistration'>

                    <LineAwesome icon='home' />
                     <Trans i18nKey="navbar.editVendorData">Unternehmerdaten bearbeiten</Trans>
                  </NavDropdown.Item>
                  :
                  null
                }
                <NavDropdown.Item
                  onClick={this.handleDSGVOClick}
                >
                  <LineAwesome icon='cookie' /> <Trans i18nKey="navbar.cookieSettings">Cookie Einstellungen</Trans>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    this.handleLogout();
                    this.handleLinkClick();
                  }}
                >
                  <LineAwesome icon='sign-out-alt' /> <Trans i18nKey="navbar.logout">Abmelden</Trans>
                </NavDropdown.Item>
              </NavDropdown>
              :
              <Nav.Link as={NavLink} activeClassName='active' className='login-nav-item' to='/login'
                        onClick={this.handleLinkClick}>
                <LineAwesome icon='sign-in-alt' /> <Trans i18nKey="navbar.loginRegister">Login / Registrieren</Trans>
              </Nav.Link>}
            {this.props.memberID ?
              <Nav.Link className='icon-only' onClick={this.props.toggleBookmarks}>
                <DropDownTitle
                  className='d-inline-block'
                  icon='heart'
                  iconClass='mr-1'
                  title=''
                />
              </Nav.Link>
              :
              null
            }

            <Nav.Link className='icon-only' onClick={this.props.toggleCard}>
              <DropDownTitle
                className='d-inline-block'
                icon='shopping-cart'
                iconClass='mr-1'
                title=''
                badgeClass='ml-1'
                badgeVariant='success'
                badgeValue={typeof this.props.itemCount !== 'undefined' ? this.props.itemCount : 0}
              />
            </Nav.Link>
            <Nav.Link className='icon-only searchIcon' onClick={this.props.searchToggle}>
              <LineAwesome icon='search' />
            </Nav.Link>
          </>
        }
      </Nav>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  itemCount: state.basket.itemCount,
  memberID: state.user.memberID,
  user: state.user.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  clearBasket: () => dispatch(BasketAction.clearBasket()),
  clearCollections: () => dispatch(BookmarkCollectionAction.clearCollections()),
  setCookieOverlayState: (setCookieOverlayState) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState)),
  clearCheckout: () => dispatch(CheckoutAction.clearCheckout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomNavigation));
