import React, { ReactElement } from 'react';
import Helmet from 'react-helmet';

export const HeadTags = (props: HeadTagsProps): ReactElement => {
  const { metaDescription, imageUrl, title } = props;

  return (
    <Helmet>
      <meta
        property='og:description'
        key='og:description'
        content={metaDescription}
      />
      <meta
        property='og:title'
        key='og:title'
        content={title}
      />
      <meta
        property='og:image'
        key='og:image'
        content={imageUrl}
      />
      <meta
        property='og:url'
        key='og:url'
        content={document.URL}
      />
    </Helmet>
  );
};

export type HeadTagsProps = {
  metaDescription: string;
  imageUrl: string;
  title: string;
}
