/**
 * The initial values for the redux state.
 */
import { SilverStripeUser } from '../../models/silver-stripe-user.model';

export const INITIAL_STATE: RootAppState = {
  basketItems: [],
  list: [],
  basketID: 0,
  basketLoading: false,
  lastEdited: null,
  itemCount: 0,
  total: 0,
  user: undefined,
  dsgvo: undefined,
  coords: undefined
};

export interface RootAppState {
  dsgvo: DsgvoState | undefined,
  user: UserState | undefined,
  basketItems: Array<any>,
  list: Array<any>,
  basketID: number;
  basketLoading: boolean;
  lastEdited: string | null;
  itemCount: number;
  total: number;
  coords: CoordsState | undefined
}

export interface UserState {
  user: SilverStripeUser;
  authKey: string;
  isLoggedIn: boolean;
  keyValidUntil: string;
  memberID: number;
  userErrorMessage: string;
  userIsLoading: boolean;
}

export interface DsgvoState {
  performanceCookies: boolean,
  functionalCookies: boolean,
  targetingCookies: boolean,
}

export interface CoordsState {
  latitude: number;
  longitude: number;
  geocodeAddress: GeocodeAddressState;
  geolocationAvailable: boolean;
  custom: string;
}

export interface GeocodeAddressState {
  // eslint-disable-next-line camelcase
  formatted_address: string;
}
