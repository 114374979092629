import React, { ReactElement } from 'react';
import { Image } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel3';

export const PhotoThumbnails = (props: PhotoThumbnailsProps): ReactElement => {
  const { images, onThumbnailClick } = props;

  return (
    <OwlCarousel
      id='thumbs'
      className={`${images.length === 1 ? 'single-image' : ''}`}
      items={Math.min(images.length, 5)}
      navText={[
        '<i class="la la-arrow-left" aria-hidden="true"/>',
        '<i class="la la-arrow-right" aria-hidden="true"/>'
      ]}
      smartSpeed={200}
      autoWidth
      autoplaySpeed={500}
      responsiveRefreshRate={100}
    >
      {images.map((item, index) => (
        <div className='item' key={item.ID}
             onClick={() => onThumbnailClick(index)}>
          <Image src={item.AbsoluteURL} />
        </div>
      ))}
    </OwlCarousel>
  );
};

export type PhotoThumbnailsProps = {
  images: Array<any>;
  onThumbnailClick: (imageIndex: number) => void
}
