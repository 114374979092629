const deg2rad = (deg: number): number => deg * (Math.PI / 180);

const getDistanceFromLatLonInKm = (lat1: number | undefined, lon1: number | undefined, lat2: number | undefined, lon2: number | undefined): string => {
    if(!lat1 || !lon1 || !lat2 || !lon2){
        return '---';
    }
    if (lat1 === 0 && lon1 === 0) {
        return '---';
    }
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d.toFixed(1);
}


export { getDistanceFromLatLonInKm }
