import React from 'react';
import PropTypes from 'prop-types';
import {Button, Image} from 'react-bootstrap';
import {Link, withRouter} from "react-router-dom";
import LineAwesome from "../LineAwesome/LineAwesome";
import DefaultImage from "../../assets/img/default_img.png";

class MayLikeItem extends React.Component {

    render() {
        return (
            <div className={"position-relative " + this.props.boxClass}>
                <Button as={Link} to={'/produkt/' + this.props.urlsegment}
                        className="btn btn-primary btn-sm position-absolute">
                    <LineAwesome icon='plus'/>
                </Button>
                <Image
                    src={this.props.image ?? DefaultImage}
                    className={this.props.imageClass}
                    alt={this.props.imageAlt}/>
                {this.props.title ?
                    <h6>{this.props.title}</h6>
                    : ""
                }
                {this.props.price ?
                    <small>{this.props.price}</small>
                    : ""
                }
            </div>
        );
    }
}


MayLikeItem.propTypes = {
    onAdd: PropTypes.func,
    image: PropTypes.string.isRequired,
    imageClass: PropTypes.string,
    imageAlt: PropTypes.string,
    boxClass: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
};
MayLikeItem.defaultProps = {
    imageAlt: '',
    image: '',
    imageClass: '',
    boxClass: 'mall-category-item',
    title: '',
    price: '',
}

export default (withRouter(MayLikeItem));
