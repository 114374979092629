/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {BasketActionTypes} from './actions';

export const setBasketItems = (state, {basketItems}) => {
    return {
        ...state,
        basketItems: basketItems
    };
};

export const setList = (state, {list}) => {
    return {
        ...state,
        list: list
    };
};

export const setBasketId = (state, {basketID}) => {
    return {
        ...state,
        basketID: basketID
    };
};

export const setLastEdited = (state, {lastEdited}) => {
    return {
        ...state,
        lastEdited: lastEdited
    };
};

export const setBasketLoading = (state) => {
    return {
        ...state,
        basketLoading: true
    };
};
export const setBasketDoneLoading = (state) => {
    return {
        ...state,
        basketLoading: false
    };
};

export const clearBasket = (state) => {
    return {
        ...state,
        basketItems: [],
        basketID: 0,
        basketLoading: false,
        list: [],
        itemCount: 0,
    };
};

export const setItemCount = (state, {itemCount}) => {
    return {
        ...state,
        itemCount: itemCount
    };
};
export const setTotal = (state, {total}) => {
    return {
        ...state,
        total: total
    };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [BasketActionTypes.SET_ITEM_COUNT]: setItemCount,
    [BasketActionTypes.SET_TOTAL]: setTotal,
    [BasketActionTypes.SET_LIST]: setList,
    [BasketActionTypes.SET_BASKET_ITEMS]: setBasketItems,
    [BasketActionTypes.SET_BASKET_ID]: setBasketId,
    [BasketActionTypes.SET_BASKET_LOADING]: setBasketLoading,
    [BasketActionTypes.SET_BASKET_DONE_LOADING]: setBasketDoneLoading,
    [BasketActionTypes.SET_LAST_EDITED]: setLastEdited,
    [BasketActionTypes.CLEAR_BASKET]: clearBasket
});
