import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import MarketplaceBackground from "../../assets/img/marketplaceBackground.jpeg";
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

export default class Categories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            loading: true
        }
    }

    componentDidMount() {
        ApiService.client.get('/Category/?filter[ParentID]=0')
            .then((result) => {
                this.setState({
                    categories: result.data,
                    loading: false
                })
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        loading: false
                    }
                );
                toastr.error('Fehler beim laden der Kategorien', msg)
            })
    }

    render() {
        return (
            <>
                <CustomMetaTags
                    title={"Region im Netz -Alle Kategorien"}
                    desc={"Entdecke die Welt der Region Im Netz"}
                />
                <PageTitle
                    title="Alle Kategorien"
                    subTitle="Entdecke die Welt von Region im Netz"
                    image={MarketplaceBackground}
                />
                <Container>
                    <Row className='py-5'>
                        {this.state.loading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto d-block align-self-center'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            this.state.categories.map((item) =>
                                <Col lg={4} key={item.ID} className='py-2'>
                                    <h5 className='mb-2'><Link
                                        to={'/produkte/' + item.ID + '/' + item.Title}>{item.Title}</Link></h5>
                                    <ul className='category-list'>
                                        {typeof item.Children !== "undefined" ?
                                            item.Children.map((cat) =>
                                                <li key={cat.ID}>
                                                    <Link
                                                        to={'/produkte/' + cat.ID + '/' + cat.Title}
                                                        className='text-black'>{cat.Title}
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            null
                                        }

                                    </ul>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </>

        )
    }
}
