/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
    user: {},
    isLoggedIn: false,
    authKey: '',
    memberID: 0,
    userIsLoading: false,
    userErrorMessage: null,
};
