import React, {Component} from "react";
import BasketAction from "../../stores/basket/actions";
import CheckoutAction from "../../stores/checkout/actions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Alert, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import CheckoutBasket from "../../components/Basket/CheckoutBasket";
import CheckoutAddressFrom from "./CheckoutAddressFrom";
import PaymentForm from "./PaymentForm";
import CheckoutLoginForm from "./CheckoutLoginForm";
import CheckoutRegistration from "./CheckoutRegistration";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import queryString from "query-string";
import UserActions from "../../stores/user/actions";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import {logError} from "../../utils/Logging";


class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            billingAddressID: this.props.checkout.billingAddressID ?? 0,
            shippingAddressID: this.props.checkout.shippingAddressID ?? 0,
            hasDifferentShippingAddress: false,
            remark: '',
            regShowing: false,
            billingOptionID: 1,
            editMode: true,
            billingEdit: !this.props.checkout.billingAddressID,
            shippingEdit: false,
            memberID: 0,
            orderDone: false,
            orderID: 0,
            epsSofortError: '',
            AGB: false,
            Widerrufsrecht: false,
        }

        this.changeBillingAddressID = this.changeBillingAddressID.bind(this)
        this.changeShippingAddressID = this.changeShippingAddressID.bind(this)
        this.changeBillingOptionID = this.changeBillingOptionID.bind(this)
        this.changeEditMode = this.changeEditMode.bind(this)
        this.changeRemark = this.changeRemark.bind(this)
        this.handleSubmitOrder = this.handleSubmitOrder.bind(this)
        this.handleSoftSubmitOrder = this.handleSoftSubmitOrder.bind(this)
        this.changeShippingEditMode = this.changeShippingEditMode.bind(this)
        this.changeBillingEditMode = this.changeBillingEditMode.bind(this)
        this.handleHasDifferentShippingAddress = this.handleHasDifferentShippingAddress.bind(this)
        this.handleEPSorSofort = this.handleEPSorSofort.bind(this)
    }


    componentDidMount() {
        this.props.fetchUser();
        this.props.fetchBasket();
        if (this.props.checkout.checkoutPreFill) {
            this.setState({
                remark: this.props.checkout.remarks,
                editMode: false
            }, this.handleEPSorSofort)
        }
    }

    handleEPSorSofort() {
        let params = queryString.parse(this.props.location.search)
        if (
            typeof params.payment_intent !== 'undefined' ||
            typeof params.redirect_status !== 'undefined' ||
            typeof params.tgcode !== 'undefined'
        ) {
            this.setState({
                loading: true
            }, () => {
                if (params.redirect_status !== 'succeeded') {
                    this.setState({
                        epsSofortError: 'Die Zahlung ist leider fehlgeschlagen! Bitte versuchen Sie es erneut.',
                        loading: false
                    })
                } else {
                    this.setState({
                        epsSofortError: '',
                        loading: false
                    })
                    if (typeof this.props.list !== 'undefined' && this.props.list.length) {
                        this.handleSubmitOrder()
                    }
                }
            });
        }
    }

    changeBillingAddressID(id) {
        this.setState({
            billingAddressID: id
        })
        this.props.setCheckout(
            this.props.basketID,
            id,
            this.state.shippingAddressID,
            this.state.remark,
            true
        );
    }

    changeShippingAddressID(id) {
        this.setState({
            shippingAddressID: id
        })
        this.props.setCheckout(
            this.props.basketID,
            this.state.billingAddressID,
            id,
            this.state.remark,
            true
        );
    }

    changeBillingOptionID(id) {
        this.setState({
            billingOptionID: id
        })
    }

    changeRemark(e) {
        this.setState({
            remark: e.target.value
        })
        this.props.setCheckout(
            this.props.basketID,
            this.state.billingAddressID,
            this.state.shippingAddressID,
            e.target.value,
            true
        );
    }

    changeEditMode(value) {
        this.setState({
            editMode: value
        })
    }

    changeShippingEditMode(value) {
        this.setState({
            editMode: value,
            shippingEdit: value,
        })
    }

    changeBillingEditMode(value) {
        this.setState({
            editMode: value,
            billingEdit: value,
        })
    }

    handleSoftSubmitOrder(setLoading = true, callback = null) {
        this.setState({epsSofortError: ''});

        let billingAddressID = this.state.billingAddressID;
        let shippingAddressID = this.state.shippingAddressID;
        let hasDifferentShippingAddress = this.state.hasDifferentShippingAddress;
        let basketID = this.props.basketID;
        let authKey = this.props.authKey;
        let remark = this.state.remark;
        if (!hasDifferentShippingAddress) {
            shippingAddressID = billingAddressID;
        }
        if (
            basketID &&
            authKey &&
            billingAddressID &&
            shippingAddressID
        ) {
            if (setLoading) {
                this.setState({
                    loading: true
                });
            }

            ApiService.authorizedClient(authKey).post(
                '/Order/processSoft',
                {
                    BasketID: basketID,
                    BillingAddressID: billingAddressID,
                    ShippingAddressID: shippingAddressID,
                    Remarks: remark,
                }
            ).then((result) => {
                if (typeof callback === "function") {
                    callback();
                }
            }).catch((error) => {
                logError(error);
            })
        } else {
            toastr.error('Ein Fehler ist aufgetreten', 'Fehler beim Verarbeiten der Daten')
        }
    }

    handleSubmitOrder() {
        this.setState({epsSofortError: ''});

        let billingAddressID = this.state.billingAddressID;
        let shippingAddressID = this.state.shippingAddressID;
        let hasDifferentShippingAddress = this.state.hasDifferentShippingAddress;
        let basketID = this.props.basketID;
        let authKey = this.props.authKey;
        let remark = this.state.remark;

        if (!hasDifferentShippingAddress) {
            shippingAddressID = billingAddressID;
        }

        if (
            basketID &&
            authKey &&
            billingAddressID &&
            shippingAddressID
        ) {
            this.setState({
                loading: true
            });

            ApiService.authorizedClient(authKey).post(
                '/Order/process',
                {
                    BasketID: basketID,
                    BillingAddressID: billingAddressID,
                    ShippingAddressID: shippingAddressID,
                    Remarks: remark,
                }
            )
                .then((result) => {
                    this.props.clearCheckout()
                    toastr.success('Danke für deine Bestellung', 'Die Bestellung wurde erfolgreich übermittelt!')
                    this.props.clearBasket();
                    this.setState({
                        orderDone: true,
                        orderID: result.data.ID,
                        loading: false
                    })
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                toastr.error('Fehler', msg)
            })
        } else {
            toastr.error('Ein Fehler ist aufgetreten', 'Fehler beim verabiten der Daten')
        }
    }

    handleHasDifferentShippingAddress() {
        if (!this.state.hasDifferentShippingAddress) {
            this.setState({
                hasDifferentShippingAddress: !this.state.hasDifferentShippingAddress,
                shippingAddressID: 0,
                editMode: false
            })
        } else {
            this.setState({
                hasDifferentShippingAddress: !this.state.hasDifferentShippingAddress
            })
        }
    }

    render() {
        return (
            <Container>
                <CustomMetaTags
                    title={"Region im Netz - Checkout"}
                />
                <Row>
                    <Col xs={12}>
                        {this.state.epsSofortError ?
                            <Alert variant="danger" className="my-5">{this.state.epsSofortError}</Alert> : null}
                        {this.state.loading ?
                            <Row>
                                <Col xs={12} className='p-3 my-5'>
                                    <Spinner animation="border" role="status"
                                             className='mx-auto d-block align-self-center'>
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </Col>
                            </Row>
                            :
                            this.state.orderDone ?
                                <Row className='my-5'>
                                    <Col lg={12}>
                                        <div className='p-3 bg-white shadow-sm w-100 text-center py-3'>
                                            <h1>Vielen Dank für Deine Bestellung!</h1>
                                            <p className='mt-3'>
                                                Du kannst deine Bestellungen unter "<Link
                                                to={'/myaccount/order/' + this.state.orderID}>Mein Account</Link>"
                                                einsehen.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                typeof this.props.list !== 'undefined' && this.props.list.length ?
                                    <Row className='my-5'>
                                        <Col lg={7}>
                                            {this.props.memberID ?
                                                <>
                                                    <div className='p-3 bg-white shadow-sm w-100 mb-3'>
                                                        <CheckoutAddressFrom addressType='Billing'
                                                                             setAddress={this.changeBillingAddressID}
                                                                             addressID={this.state.billingAddressID}
                                                                             changeEditMode={this.changeBillingEditMode}
                                                                             checkWithAddressID={this.state.shippingAddressID}

                                                        />

                                                        <Form.Group controlId="formBasicCheckbox" className="mt-3">
                                                            <Form.Check type="checkbox"
                                                                        label="Abweichende Lieferadresse?"
                                                                        checked={this.state.hasDifferentShippingAddress}
                                                                        disabled={this.state.billingEdit}
                                                                        onChange={this.handleHasDifferentShippingAddress}/>
                                                            {this.state.billingEdit ?
                                                                <p className='mt-2'>Bitte speichere zuerst deine
                                                                    Adresse</p>
                                                                :
                                                                null
                                                            }
                                                        </Form.Group>
                                                    </div>

                                                    {this.state.hasDifferentShippingAddress ?
                                                        <div className='p-3 bg-white shadow-sm w-100 my-3'>
                                                            <CheckoutAddressFrom addressType='Shipping'
                                                                                 setAddress={this.changeShippingAddressID}
                                                                                 addressID={this.state.shippingAddressID}
                                                                                 changeEditMode={this.changeShippingEditMode}
                                                                                 checkWithAddressID={this.state.billingAddressID}

                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className='p-3 bg-white shadow-sm w-100 my-3'>
                                                        <div className="form-group m-0 mt-1">
                                                            <Form.Label htmlFor="inputMessage"
                                                                        style={{color: '#777'}}>Anmerkungen</Form.Label>
                                                            <Form.Control as="textarea" rows="4"
                                                                          id="inputMessage"
                                                                          placeholder="Anmerkungen"
                                                                          onChange={this.changeRemark}
                                                                          disabled={this.state.loading}
                                                                          value={this.state.remark}
                                                                          className='input-foreground'
                                                            />
                                                        </div>
                                                        <Form.Group className="mt-3" controlId="AGB">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={"Ich akzeptiere die AGB's der einzelen Händerl und der Seite."}
                                                                checked={this.state.AGB}
                                                                onChange={() => this.setState({AGB: !this.state.AGB})}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlId="Widerrufsrecht">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={'Ich akzeptiere das Wiederrufsrecht der einzelnen Unternehmer.'}
                                                                checked={this.state.Widerrufsrecht}
                                                                onChange={() => this.setState({Widerrufsrecht: !this.state.Widerrufsrecht})}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='p-3 bg-white shadow-sm w-100 my-3'>
                                                        {this.state.Widerrufsrecht && this.state.AGB ?

                                                            <PaymentForm
                                                                handleSubmit={this.handleSubmitOrder}
                                                                buttonDisabled={this.state.editMode}
                                                                handleSoftSubmitOrder={this.handleSoftSubmitOrder}
                                                                changeBillingOption={this.changeBillingOptionID}
                                                                billingID={this.state.billingOptionID}
                                                                basketTotal={this.props.total}
                                                                billingAddressID={this.state.billingAddressID}
                                                                shippingAddressID={this.state.shippingAddressID}
                                                                remark={this.state.remark}
                                                            />
                                                            :
                                                            <>
                                                                <h4 className="mb-1">Zahlungsmethode wählen</h4>
                                                                <p>Bitte akzeptieren Sie die AGB's und die
                                                                    Widerrufsrechte</p>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <div className='p-3 bg-white shadow-sm w-100 mb-3'>

                                                    {this.state.regShowing ?
                                                        <>
                                                            <CheckoutRegistration/>
                                                            <div className="text-center pt-3">
                                                                Du warst schonmal bei uns?
                                                                <button className="font-weight-bold"
                                                                        onClick={() => this.setState({regShowing: false})}>
                                                                    Zum Login
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <CheckoutLoginForm/>
                                                            <div className="text-center pt-3">
                                                                Noch kein Account? <button
                                                                className="font-weight-bold"
                                                                onClick={() => this.setState({regShowing: true})}>
                                                                Zur Registrierung
                                                            </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={5}>
                                            <div className='p-3 bg-white shadow-sm w-100'>
                                                {this.props.basketLoading ?
                                                    <Spinner animation="border" role="status"
                                                             className='mx-auto d-block align-self-center'>
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                    :
                                                    <CheckoutBasket/>
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <div className='p-3 bg-white shadow-sm w-100 my-3'>
                                        <p>Du hast keine Produkte im Warenkorb</p>
                                    </div>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    list: state.basket.list,
    basketID: state.basket.basketID,
    basketLoading: state.basket.basketLoading,
    total: state.basket.total,
    checkout: state.checkout,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
    fetchBasket: () => dispatch(BasketAction.fetchBasket()),
    clearBasket: () => dispatch(BasketAction.clearBasket()),
    clearCheckout: () => dispatch(CheckoutAction.clearCheckout()),
    setCheckout: (basketID, billingAddressID, shippingAddressID, remarks, checkoutPreFill) => dispatch(CheckoutAction.setCheckout(basketID, billingAddressID, shippingAddressID, remarks, checkoutPreFill))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Checkout));
