//TODO: create return type and use object as parameter
const createSettings = (itemsAtZero: any, itemsAtSixHundred: any, itemsAtOneThousand: any, itemsAtOneThousandTwoHundred: any, autoPlay = false, mouseDrag = true, loop = false): unknown => {
  return {
    responsive: {
      '0': {
        items: itemsAtZero,
        nav: false
      },
      '600': {
        items: itemsAtSixHundred,
        nav: false
      },
      '1000': {
        items: itemsAtOneThousand,
        nav: false
      },
      '1200': {
        items: itemsAtOneThousandTwoHundred,
        nav: true
      }
    },

    lazyLoad: true,
    pagination: false.toString(),
    loop,
    dots: true,
    autoplay: autoPlay,
    mouseDrag,
    autoplayTimeout: 2000,
    nav: true,
    navText: ['<i class=\'las la-angle-left\'></i>', '<i class=\'las la-angle-right\'></i>']
  };
};

const OWL_CAROUSEL_CATEGORY_OPTIONS = {
  responsive: {
    '0': {
      items: 3
    },
    '600': {
      items: 4
    },
    '1000': {
      items: 6
    },
    '1200': {
      items: 10
    }
  },
  loop: true,
  lazyLoad: true,
  autoplay: false,
  dots: false,
  margin: 5,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: true,
  navText: [
    '<i class="icofont-rounded-left"></i>',
    '<i class="icofont-rounded-right"></i>'
  ]
};


const OWL_CAROUSEL_OPTIONS = createSettings(1, 2, 3, 3);

const OWL_CAROUSEL_ITEM_CAROUSEL_OPTIONS = createSettings(1, 2, 4, 4, false);

const OWL_CAROUSEL_HOME_OPTIONS = createSettings(1, 2, 4, 4, false);

const OWL_CAROUSEL_BLACK_BOARD_OPTIONS = createSettings(1, 2, 4, 4);

export {
  OWL_CAROUSEL_OPTIONS,
  OWL_CAROUSEL_ITEM_CAROUSEL_OPTIONS,
  OWL_CAROUSEL_CATEGORY_OPTIONS,
  OWL_CAROUSEL_HOME_OPTIONS,
  OWL_CAROUSEL_BLACK_BOARD_OPTIONS
};
