import React, { Component, ReactNode } from 'react';
import { Button, Modal, Col, Form } from 'react-bootstrap';
import './SocialShareModal.scss';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import LazyImage from '../LazyImage/LazyImage';

export type SocialShareModalProps = {
  onHide: () => void,
  show: boolean,
  url: string;
  title: string;
  previewImage?: string;
}
type State = {}

class SocialShareModal extends Component<SocialShareModalProps, State> {

  static defaultProps = {
    previewImage: undefined
  };

  constructor(props: SocialShareModalProps) {
    super(props);
    this.state = {};
  }

  handleCopied = (): void => {
    toastr.success('Kopiert', 'Link wurde erfolgreich kopiert');
  };

  render(): ReactNode {
    const { onHide, show, url, title, previewImage } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        className='sozial-share-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title as='h5'
                       id='add-address'>Teilen</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex modal-body-wrapper'>
            <div className='title'>{title}</div>
            {previewImage && <LazyImage
              src={previewImage}
              fluid
              className='preview-image'
            />}
            <div className='share-row'>
              <EmailShareButton url={url} subject={title}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FacebookShareButton url={url} quote={title} hashtag="#regionimnetz">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={url}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={url} title={title} source={url}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <PinterestShareButton url={url} media={previewImage ?? ''}>
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <TelegramShareButton url={url}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
            <div className='copy-row'>
              <Form.Control
                type='text'
                readOnly
                value={url}
                className='url-form'
              />
              <CopyToClipboard text={url}
                               onCopy={this.handleCopied}>
                <Button variant='primary'>Kopieren</Button>
              </CopyToClipboard>
            </div>
        </Modal.Body>
      </Modal>
    );
  }
}


export default SocialShareModal;
