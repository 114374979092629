
const parseVideo = (data) => {
    if(!data){
        return data;
    }
    const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})[^"]*"><\/oembed>/g;
    data = data.replace(regex, '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>');
    const regex2 = /\[embed.+url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})[^"]*".*\]*\[\/embed\]/g;
    data = data.replace(regex2, '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>');
    // const regex3 = /\[image.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)image"\]/g;
    const regex3 = /\[image.+src="([^"]*)" id="([^"]*)" width="([^"]*)" height="([^"]*)" class="([^"]*)"\]/g;
    data = data.replace(regex3, '<img src="https://api.region-im-netz.com$1" width="$3" height="$4" class="$5">');
    return data;
}



export const HTMLService = {
    parseVideo,
};
