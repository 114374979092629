import React, { ErrorInfo } from 'react';
import { ErrorBoundaryProps } from '@sentry/react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { captureException } from './sentry';
import NotFoundImage from '../assets/img/img_error404.png';
import { logError } from './Logging';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: ErrorBoundaryProps): State {
    logError(error);
    captureException(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    captureException(errorInfo);
  }

  render(): any {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <Container>
        <Row>
          <Col md={12} className='text-center pt-5 pb-5'>
            <Image className='img-fluid mb-4' style={{ maxWidth: '500px' }} src={NotFoundImage}
                   alt='404' />
            <h1 className='mt-2 mb-2'>Hoppla, da ist etwas schief gelaufen</h1>
            <p>
              Oh-oh! Sieht so aus als wäre etwas schief gelaufen. Bitte versuche es
              noch einmal.
            </p>
            <Link className='btn btn-primary btn-lg mt-3' to='/'>ZUR STARTSEITE</Link>
          </Col>
        </Row>
      </Container>;
    }
    return children;
  }
}

type State = {
  hasError: boolean;
}
