/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {BookmarkCollectionActionTypes} from './actions';

export const fetchCollectionsLoading = (state) => ({
    ...state,
    collectionsAreLoading: true,
    collectionsErrorMessage: null,
});

export const fetchCollectionsSuccess = (state, {collections}) => ({
    ...state,
    bookmarkCollections: collections,
    collectionsAreLoading: false,
    collectionsErrorMessage: null,
});

export const fetchCollectionsFailure = (state, {errorMessage}) => ({
    ...state,
    bookmarkCollections: [],
    collectionsAreLoading: false,
    collectionsErrorMessage: errorMessage,
});

export const clearCollections = (state) => ({
    ...state,
    bookmarkCollections: [],
    collectionsAreLoading: false,
    collectionsErrorMessage: null,
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [BookmarkCollectionActionTypes.FETCH_COLLECTIONS_LOADING]: fetchCollectionsLoading,
    [BookmarkCollectionActionTypes.FETCH_COLLECTIONS_SUCCESS]: fetchCollectionsSuccess,
    [BookmarkCollectionActionTypes.FETCH_COLLECTIONS_FAILURE]: fetchCollectionsFailure,
    [BookmarkCollectionActionTypes.CLEAR_COLLECTIONS]: clearCollections
});
