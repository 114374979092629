import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'react-bootstrap';
import { ApiService } from '../../services/ApiService';
import './Product.style.scss';
import { Trans } from 'react-i18next';

class ProductRequestForm extends Component<ProductRequestFormProps, State> {
  static defaultProps = {
    product: undefined
  };

  constructor(props: ProductRequestFormProps) {
    super(props);

    this.state = {
      name: '',
      email: '',
      emailError: '',
      address: '',
      message: '',
      error: '',
      success: '',
      loading: false,
      disabled: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    const { user, product } = this.props;
    if (user && typeof user.FirstName !== 'undefined') {
      const firstname = user.FirstName ?? '';
      const surname = user.Surname ?? '';
      this.setState({
        name: `${firstname} ${surname}`,
        email: user.Email
      });
    }

    if (typeof product !== 'undefined' && typeof product.Title !== 'undefined') {
      this.setState({
        message: `Ich interessiere mich für Ihr Produkt (${product.Title}). Bitte lassen Sie mir dazu Informationen zukommen`
      });
    }
  }

  componentDidUpdate(prevProps: ProductRequestFormProps, prevState: State): void {
    const { product } = this.props;
    if (typeof product !== 'undefined' && typeof product.Title !== 'undefined' && JSON.stringify(prevProps.product) !== JSON.stringify(product)) {
      this.setState({
        message: `Ich interessiere mich für Ihr Produkt (${product.Title}).\nBitte lassen Sie mir dazu Informationen zukommen`
      });
    }
  }

  handleSubmit = (): void => {
    const { name, email, emailError, address, message } = this.state;
    const { product } = this.props;
    if (emailError) {
      this.setState({ error: 'Überprüfe deine Eingabe' });
      return;
    }
    if (
      name.length <= 0 ||
      email.length <= 0 ||
      address.length <= 0 ||
      message.length <= 0
    ) {
      this.setState({ error: 'Bitte fülle alle Felder aus!', success: '' });
      return;
    }
    ApiService.baseClient.post(
      `/sendRequest/${product.ID}`,
      {
        Name: name,
        Email: email,
        Address: address,
        Message: message
      }
    )
      .then((response) => {
        this.setState({ error: '', success: 'Vielen Dank für deine Nachricht' });
      })
      .catch((error) => {
        let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
        if (error.response && error.response.data && error.response.data.message) {
          errorMsg = error.response.data.message;
        }
        this.setState({ loading: false, error: errorMsg });
      });


  };

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    this.setState({ name: text });
  };

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    this.setState({ email: text });
    const emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    if (emailValidation === null && text.length > 0) {
      this.setState({ emailError: 'Bitte gib eine gültige E-Mail-Adresse ein!' });
    } else {
      this.setState({ emailError: '' });
    }
  };

  handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    this.setState({ address: text });
  };

  handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    this.setState({ message: text });
  };

  render(): ReactNode {
    const { name, email, emailError, address, message, error, success, loading, disabled } = this.state;
    return (
      <>
        {success ?
          <Alert variant='success'>{success}</Alert>
          :
          <Form>
            <div className='form-label-group m-0'>
              <Form.Control
                type='text'
                id='inputName'
                placeholder='Name'
                onChange={this.handleNameChange}
                disabled={(loading || disabled)}
                value={name}
                className='input-foreground'
              />
              <Form.Label htmlFor='inputName'>Name</Form.Label>
            </div>
            <div className='form-label-group m-0'>
              <Form.Control
                type='text'
                id='inputEmail'
                placeholder='E-Mail'
                onChange={this.handleEmailChange}
                disabled={(loading || disabled)}
                value={email}
                className='input-foreground'
              />
              <Form.Label htmlFor='inputEmail'><Trans i18nKey={'general.email'}>E-Mail</Trans></Form.Label>
              <p className='text-danger m-0'>{emailError}&nbsp;</p>
            </div>
            <div className='form-label-group m-0'>
              <Form.Control
                type='text'
                id='inputAddress'
                placeholder='Addresse'
                onChange={this.handleAddressChange}
                disabled={(loading || disabled)}
                value={address}
                className='input-foreground'
              />
              <Form.Label htmlFor='inputAddress'>Adresse</Form.Label>
            </div>
            <div className='form-group m-0 mt-1'>
              <Form.Label htmlFor='inputMessage' className='custom-label'>Nachricht</Form.Label>
              <Form.Control as='textarea' rows={4}
                            id='inputMessage'
                            placeholder='Nachricht'
                            onChange={this.handleMessageChange}
                            disabled={loading}
                            value={message}
                            className='input-foreground'
              />
            </div>
            {error ?
              <Alert variant='danger' className='mt-2'>{error}</Alert>
              : null
            }
            <Button onClick={this.handleSubmit} className='btn-block mt-3'>
              Anfragen
            </Button>

          </Form>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  coords: state.coords,
  user: state.user.user,
  memberID: state.user.memberID
});


export default connect(
  mapStateToProps,
  null
)(ProductRequestForm);

export type ProductRequestFormProps = {
  user: any;
  product?: any;
};

type State = {
  name: string,
  email: string,
  emailError: string,
  address: string,
  message: string,
  error: string,
  success: string,
  loading: boolean,
  disabled: boolean
}
