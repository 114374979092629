/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {CookieActionTypes} from './actions';

export const updateCookies = (state, {performanceCookies, functionalCookies, targetingCookies}) => {
    return {
        ...state,
        performanceCookies: performanceCookies,
        functionalCookies: functionalCookies,
        targetingCookies: targetingCookies,
    };
};

export const setCookieOverlayState = (state, {setCookieOverlayState}) => {
    return {
        ...state,
        showCookieOverlay: setCookieOverlayState
    };
};
export const resetCookie = (state) => {
    return {
        ...state,
        showCookieOverlay: true,
        performanceCookies: false,
        functionalCookies: false,
        targetingCookies: false,
    }
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [CookieActionTypes.UPDATE_COOKIES]: updateCookies,
    [CookieActionTypes.SET_COOKIE_OVERLAY_STATE]: setCookieOverlayState,
    [CookieActionTypes.RESET_COOKIE]: resetCookie,
});
