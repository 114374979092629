import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';
import searchEmptyAnimation from '../../assets/animations/search_empty.json';
import LineAwesome from '../LineAwesome/LineAwesome';
import './NotFound.scss';

export const NotFound = (props: NotFoundProps): ReactElement => {
  const { text } = props;
  return (
    <div className='m-5'>
      <h4 className='text-center'>{text}</h4>
      <Lottie animationData={searchEmptyAnimation} style={ANIMATION_STYLE} />
      <div className='back-to-home-wrapper'>
        <Button as={Link} variant='link' className='btn-primary back-to-home-button'
                to='/'><LineAwesome
          icon='home' /> Zurück zur Startseite</Button>
      </div>
    </div>
  );
};

const ANIMATION_STYLE: React.CSSProperties = {
  height: 500
};

export type NotFoundProps = {
  text: string;
};
