import {all, call, spawn, takeEvery, takeLatest} from 'redux-saga/effects';
import {BookmarkCollectionActionTypes} from '../stores/bookmarkCollections/actions';
import {UserActionTypes} from '../stores/user/actions';
import {BasketActionTypes} from '../stores/basket/actions';
import {
    addItem,
    deleteCollection,
    fetchCollections,
    removeItem,
    updateOrCreateCollection
} from './BookmarkCollectionSaga';
import {fetchUser} from './UserSaga';
import {fetchBasket,setAndFetchBasket} from './BasketSaga';
import {watchCurrentPosition, watchLocationChannel} from "redux-saga-location";
import {REDUX_SAGA_LOCATION_ACTION_SET_POSITION} from "redux-saga-location/actions";
import {setGeoAddress, updateLocation} from './CoordsSaga';
import {CoordsActionTypes} from "../stores/coords/actions";

export default function* root() {
    yield all([
        /**
         * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
         */
        // Call `fetchUser()` when a `FETCH_USER` action is triggered
        takeLatest(BookmarkCollectionActionTypes.FETCH_COLLECTIONS, fetchCollections),
        takeLatest(BookmarkCollectionActionTypes.ADD_ITEM, addItem),
        takeLatest(BookmarkCollectionActionTypes.REMOVE_ITEM, removeItem),
        takeLatest(BookmarkCollectionActionTypes.UPDATE_OR_CREATE_COLLECTION, updateOrCreateCollection),
        takeLatest(BookmarkCollectionActionTypes.DELETE_COLLECTION, deleteCollection),
        takeLatest(UserActionTypes.FETCH_USER, fetchUser),
        takeLatest(BasketActionTypes.FETCH_BASKET, fetchBasket),
        takeLatest(BasketActionTypes.SET_AND_FETCH_BASKET, setAndFetchBasket),
        takeLatest(UserActionTypes.LOGIN, fetchUser),
        spawn(watchLocationChannel),
        call(watchCurrentPosition),
        takeLatest(REDUX_SAGA_LOCATION_ACTION_SET_POSITION, updateLocation),
        takeLatest(CoordsActionTypes.RESET, updateLocation),
        takeLatest(CoordsActionTypes.UPDATE_LOCATION, setGeoAddress)
    ]);
}
