import React, { Component, ReactElement, ReactNode } from "react";
import GoogleMapReact, { ChangeEventValue, Coords } from 'google-map-react';
import { connect, ConnectedProps } from "react-redux";
import Config from "../../config";
import DSGVOAction from "../../stores/dsgvo/actions";
import { RootAppState } from '../../stores/basket/initialState';

class Map extends Component<MapProps, State> {
    static defaultProps = {
        center: {
            lat: 48.247590,
            lng: 14.303290
        },
        searchLat: 0,
        zoom: 14,
        heightStyle: '80vh'
    };

    constructor(props: MapProps) {
        super(props);

        this.handleMapChange = this.handleMapChange.bind(this)
        this.handleDSGVOClick = this.handleDSGVOClick.bind(this)
    }

    handleMapChange(value:  ChangeEventValue): void {
        const { handleMapChange } = this.props;
        if (typeof handleMapChange === "function") {
            handleMapChange(value)
        }
    }

    handleDSGVOClick(): void {
        const { setCookieOverlayState } = this.props;
        if (setCookieOverlayState) {
            setCookieOverlayState(true)
        }
    }

    render(): ReactElement {
        const { dsgvo, heightStyle, zoom, center, searchLat, children } = this.props;
        return (
            dsgvo.functionalCookies ?
                <div
                    style={{ height: heightStyle, width: '100%', borderRadius: '20px', overflow: 'hidden' }}>
                    <GoogleMapReact
                        key={searchLat}
                        bootstrapURLKeys={{
                            key: Config.GOOGLE_PLACES_API_KEY,
                            language: 'de',
                            region: 'at',
                        }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                        onChange={this.handleMapChange}
                        options={undefined}
                    >
                        {children}
                    </GoogleMapReact>
                </div>
                :
                <p onClick={this.handleDSGVOClick} className='text-center cursor-pointer'><b>Bitte erlauben Sie
                    funktionale Cookies um diesen Inhalt anzuzeigen</b></p>
        );
    }
}

const mapStateToProps = (state: RootAppState): MappedProps => ({
    dsgvo: state.dsgvo
});
const mapDispatchToProps = (dispatch: any): MappedDispatch => ({
    setCookieOverlayState: (setCookieOverlayState: boolean) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState))
});
const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default connector(Map);

type PropsFromRedux = ConnectedProps<typeof connector>

type MappedProps = {
    dsgvo: any
};

type MappedDispatch = {
    setCookieOverlayState: any
}

type State = {
    userIsLoading: boolean;
}

type OwnProps = {
    children:  ReactNode,
    searchLat?: number,
    center?: Coords,
    zoom?: number,
    heightStyle?: string;
    handleMapChange: (value: ChangeEventValue) => void
}

export type MapProps = PropsFromRedux & MappedProps & OwnProps;
