import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from 'react-bootstrap';
import config from "../../config";
import FooterMenu from "../FooterMenu/FooterMenu";
import {ApiService} from '../../services/ApiService';
import LineAwesome from "../LineAwesome/LineAwesome";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {logError} from "../../utils/Logging";

class Footer extends React.Component {
    static defaultProps = {
        sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
    }

    state = {
        menus: []
    }

    componentDidMount() {
        ApiService.client.get('/Menu')
            .then((response) => {
                this.setState({menus: response.data});
            })
            .catch((error) => {
                logError(error)
            })
    }

    render() {
        return (
            <>
                <section className="section pt-4 pb-4 text-center bg-white">
                    <Container>
                        <Row className="top-footer">
                            <Col sm={12}>
                                <h5 className="m-0">
                                    Online einfach regionaler. Werde Teil eines großartigen Projekts.
                                    <Link to="/händler-werden" > Arbeite mit uns!</Link>

                                </h5>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="footer pt-5 pb-5">
                    <Container>
                        <Row>
                            <Col md={4} sm={12} className="pb-3 reg-text">
                                <p style={{fontSize:'14px', textTransform: 'upperCase'}} className="font-weight-bold link-p">
                                    <Link to="/register">Jetzt Registrieren! <LineAwesome icon="arrow-alt-circle-right" style={{fontSize:'18px'}}/></Link>
                                </p>
                                <p>Um den Angeboten, Informationen und Menüplänen Deines Lieblingsunternehmers auf dem „Schwarzen Brett“ zu folgen.</p><br/>
                                <p style={{fontSize:'14px', textTransform: 'upperCase'}} className="font-weight-bold link-p">
                                    {this.props.isLoggedIn && this.props.user.VendorID ?
                                        <Link to="/myaccount/vendorregistration">Jetzt Unternehmer werden <LineAwesome icon="arrow-alt-circle-right" style={{fontSize:'18px'}}/></Link>
                                        :
                                        <Link to="/händler-anmeldung">Jetzt Unternehmer werden <LineAwesome icon="arrow-alt-circle-right" style={{fontSize:'18px'}}/></Link>
                                    }
                                </p>
                                <p>
                                    <span style={{color:'#fff',fontSize: '1rem', marginRight: '5px'}}><b>Folge uns auf:</b></span>
                                    <br/>
                                    <a href='https://www.facebook.com/regionimnetz' target="_blank" style={{color:'#fff',fontSize: '1.5rem', marginRight: '5px'}}><LineAwesome icon={"facebook"} iconStyle={'lab'}/></a>
                                    <a href='https://www.instagram.com/region_im_netz/' target="_blank" style={{color:'#fff',fontSize: '1.5rem', marginRight: '5px'}}><LineAwesome icon={"instagram"} iconStyle={'lab'}/></a>
                                </p>

                            </Col>
                            <Col md={1} sm={6} className="mobile-none">
                            </Col>
                            {this.state.menus.map((item, index) => {
                                return <FooterMenu key={item.ID} id={item.ID} title={item.Title}/>;
                            })}
                        </Row>
                    </Container>
                </section>
                <footer className="pt-4 pb-4 text-center bg-white">
                    <Container>
                        <p className="mt-0 mb-0">© Copyright {new Date().getFullYear()} {config.projectName}</p>
                    </Container>
                </footer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
});


export default connect(
    mapStateToProps,
    null
)(withRouter(Footer));
