import React, {Component} from 'react';
import {PaymentRequestButtonElement} from "@stripe/react-stripe-js";

export default class PaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            disabled: false,
            succeeded: false,
            paymentRequest: '',
            stripeError: '',
        }

        this.getElement = this.getElement.bind(this)
    }

    getElement() {
        let totalEuro = parseFloat(this.props.basketTotal);
        let totalcent = Math.round(totalEuro * 100)
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            return 'Dies kann leider im localhost nicht getestet werden!';
        }

        if (this.props.stripe && !this.state.paymentRequest) {
            const pr = this.props.stripe.paymentRequest({
                country: 'AT',
                currency: 'eur',
                total: {
                    label: 'Gesamt',
                    amount: totalcent,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            pr.canMakePayment().then(result => {
                if (result) {
                    pr.on('paymentmethod', async (ev) => {
                        // Confirm the PaymentIntent without handling potential next actions (yet).
                        const {error: confirmError} = await this.props.stripe.confirmCardPayment(
                            this.props.stripeClientSecret,
                            {payment_method: ev.paymentMethod.id},
                            {handleActions: false}
                        );

                        if (confirmError) {
                            ev.complete('fail');
                        } else {
                            ev.complete('success');

                            const {error} = await this.props.stripe.confirmCardPayment(this.props.stripeClientSecret);
                            if (error) {
                                this.setState({
                                    stripeError: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es mit einer anderen Zahlungsmethode.',
                                    processing: false,
                                    succeeded: false
                                })
                            } else {
                                this.setState({
                                    stripeError: '',
                                    processing: false,
                                    succeeded: true
                                })
                                if (typeof this.props.onSubmitAction === 'function') {
                                    this.props.onSubmitAction();
                                }
                            }
                        }
                    });

                    this.setState({
                        paymentRequest: pr
                    })
                }
            });

        }
        if (this.state.paymentRequest) {
            return <PaymentRequestButtonElement options={{paymentRequest: this.state.paymentRequest}}/>
        }
        return 'Dein Browser unterstützt diese Zahlungsart nicht'

    }

    render() {
        return (
            <form id="payment-button-form" onSubmit={this.handleSubmit}>
                {this.props.buttonDisabled ?
                    <p className='text-center'>Bitte füllen Sie zuerst alle notwendigen Daten aus</p>
                    :
                    this.getElement()
                }
                {/* Show any error that happens when processing the payment */}
                {this.state.stripeError && (
                    <div className="card-error" role="alert">
                        {this.state.stripeError}
                    </div>
                )}
            </form>
        );
    }
}
