import React from 'react';
import LineAwesome from '../LineAwesome/LineAwesome';
import { Col, Form, Nav, NavDropdown, Row } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import UserActions from '../../stores/user/actions';
import BasketAction from '../../stores/basket/actions';
import BookmarkCollectionAction from '../../stores/bookmarkCollections/actions';
import DSGVOAction from '../../stores/dsgvo/actions';
import CheckoutAction from '../../stores/checkout/actions';
import { connect } from 'react-redux';
import DropDownTitle from '../Dropdown/DropDownTitle';
import LocationField from '../LocationField/LocationField';
import { Trans } from 'react-i18next';

class NavIcons extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openOverlay: false,
      type: '',
      searchTerm: ''
    };
  }

  handleLinkClick = () => {
    if (typeof this.props !== 'undefined' && typeof this.props.onLinkClick === 'function') {
      this.props.onLinkClick();
    }
  };

  handleDSGVOClick = () => {
    this.props.setCookieOverlayState(true);
  };

  handleLogout = () => {
    this.props.logout();
    this.props.clearBasket();
    this.props.clearCollections();
    this.props.clearCheckout();
    this.props.history.push('/');
  };


  toggleOverlay = (type) => {
    if (type === this.state.type) {
      this.setState({
        openOverlay: false,
        type: ''
      });
    } else {
      this.setState({
        openOverlay: true,
        type: type
      });
    }

  };

  handleSearch = () => {
    this.setState({
      openOverlay: false,
      type: ''
    });
    this.props.history.push('/suche?search=' + this.state.searchTerm);
  };

  handleSearchTermChange = (e) => {
    this.setState({
      searchTerm: e.target.value
    });
  };


  render() {
    return (
      <div className='d-block d-lg-none'>
        <div className='mobile-nav-icons'>
          <Nav.Link className='icon-only' onClick={() => this.toggleOverlay('search')}>
            <DropDownTitle
              className='d-inline-block'
              icon='search'
              iconClass='mr-1'
              title=''
            />
          </Nav.Link>
          <Nav.Link className='icon-only' onClick={() => this.toggleOverlay('location')}>
            <DropDownTitle
              className='d-inline-block'
              icon='map-marker'
              iconClass='mr-1'
              title=''
            />
          </Nav.Link>
          {this.props.memberID ?
            <Nav.Link className='icon-only' onClick={this.props.toggleBookmarks}>
              <DropDownTitle
                className='d-inline-block'
                icon='heart'
                iconClass='mr-1'
                title=''
              />
            </Nav.Link>
            :
            null
          }
          <Nav.Link className='icon-only mobile-basket' onClick={this.props.toggleCard}>
            <DropDownTitle
              className='d-inline-block'
              icon='shopping-cart'
              iconClass='mr-1'
              title=''
              badgeClass='ml-1'
              badgeVariant='success'
              badgeValue={typeof this.props.itemCount !== 'undefined' ? this.props.itemCount : 0}
            />
          </Nav.Link>
          {this.props.isLoggedIn ?
            <NavDropdown
              className='login-nav-item'
              alignRight
              title={<LineAwesome icon='user' />}
              id='account-mobile'>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/orders'>
                <LineAwesome icon='clipboard-list' /> Bestellungen
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/bookmarks'>
                <LineAwesome icon='certificate' /> Merklisten
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                to='/myaccount/'>
                <LineAwesome icon='user' /> <Trans i18nKey="navbar.accountManagement">Accountverwaltung</Trans>
              </NavDropdown.Item>
              {this.props.user.VendorID !== 0 ?
                <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                  to='/myaccount/vendorregistration'>
                  <LineAwesome icon='home' />
                  Unternehmerdaten bearbeiten
                </NavDropdown.Item>
                :
                null
              }
              <NavDropdown.Item
                onClick={this.handleDSGVOClick}
              >
                <LineAwesome icon='cookie' /> Cookie Einstellungen
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  this.handleLogout();
                  this.handleLinkClick();
                }}
              >
                <LineAwesome icon='sign-out-alt' /> Abmelden
              </NavDropdown.Item>
            </NavDropdown>
            :
            <Nav.Link
              as={NavLink}
              activeClassName='active'
              className='login-nav-item'
              to='/login'
              onClick={this.handleLinkClick}
            >
              <LineAwesome icon='sign-in-alt' />
            </Nav.Link>
          }
        </div>
        {this.state.openOverlay ?
          <div className='moblie-ovleray'>
            <div className='fields'>
              {this.state.type === 'search' ?
                <Row className='align-items-center'>
                  <Col xs={10}>
                    <div className='searchimput-group'>
                      <Form.Control
                        id='searchInput-mobile'
                        type='text'
                        placeholder='Suchbegriff suchen...'
                        value={this.state.searchTerm}
                        onChange={this.handleSearchTermChange}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                        size='sm'
                      />
                      <LineAwesome icon={'search'} onClick={this.handleSearch} />
                    </div>
                  </Col>
                  <Col xs={2} className='d-flex justify-content-center'>
                    <LineAwesome className='close-overlay' icon={'times'}
                                 onClick={() => this.toggleOverlay('search')} />
                  </Col>
                </Row>
                :
                null
              }
              {this.state.type === 'location' ?
                <Row className='align-items-center'>
                  <Col xs={10}>
                    <LocationField
                      className={'mb-0'}
                      onHandleSelect={() => this.toggleOverlay('location')}
                    />
                  </Col>
                  <Col xs={2} className='d-flex justify-content-center'>
                    <LineAwesome className='close-overlay' icon={'times'}
                                 onClick={() => this.toggleOverlay('location')} />
                  </Col>
                </Row>
                :
                null
              }
            </div>
          </div>
          :
          null
        }

      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  itemCount: state.basket.itemCount,
  memberID: state.user.memberID,
  user: state.user.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  clearBasket: () => dispatch(BasketAction.clearBasket()),
  clearCollections: () => dispatch(BookmarkCollectionAction.clearCollections()),
  setCookieOverlayState: (setCookieOverlayState) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState)),
  clearCheckout: () => dispatch(CheckoutAction.clearCheckout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavIcons));
