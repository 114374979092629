import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { retryLazy } from 'react-retry-lazy';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Product from '../Product/Product';
import VendorDetail from '../Vendor/VendorDetail';
import Checkout from '../Checkout/Checkout';
import Categories from '../Categories/categories';
import DSGVOOverlay from '../../components/DSGVO/DSGVOOverlay';
import ScrollToTop from '../../components/ScrollTop/ScrollTop';
import { Loader } from '../../components/Loader/Loader';

const RegionPage = retryLazy(() => import('../RegionPage/RegionPage'));
const Marketplace = retryLazy(() => import('../Marketplace/Marketplace'));
const VendorOverview = retryLazy(() => import('../VendorOverview/VendorOverview'));
const SearchPage = retryLazy(() => import('../SearchPage/SearchPage'));
const Login = retryLazy(() => import('../Login/Login'));
const RegionNewsPage = retryLazy(() => import('../RegionPage/RegionNewsPage'));
const VendorRegisterForm = retryLazy(() => import('../Register/VendorRegisterForm'));
const CustomerRegister = retryLazy(() => import('../Register/CustomerRegister'));
const MyAccount = retryLazy(() => import('../MyAccount/MyAccount'));
const RegionEventPage = retryLazy(() => import('../RegionPage/RegionEventPage'));
const VendorHowToPage = retryLazy(() => import('../VendorHowToPage/VendorHowToPage'));
const Register = retryLazy(() => import('../Register/Register'));
const NotFound = retryLazy(() => import('../NotFound/NotFound'));
const Home = retryLazy(() => import('../Home/Home'));
const PasswordRest = retryLazy(() => import('../Login/PasswordRest'));
const Page = retryLazy(() => import('../Page/Page'));


export default class Wrapper extends Component {
  render() {
    return (
      <>
        <ReduxToastr
          timeOut={5000}
          newestOnTop
          position='top-right'
          getState={(state) => state.toastr} // This is the default
          transitionIn='fadeIn'
          transitionOut='fadeOut'
          progressBar
          closeOnToastrClick />
        <Header />
        <Suspense fallback={<Loader text='Seite wird geladen...' />}>
          <div
            key={this.props.location.key}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <DSGVOOverlay />
            <ScrollToTop>
              <Switch>
                <Route path='/login'>
                  <Login />
                </Route>
                <Route path='/resetpw/:hash'>
                  <PasswordRest />
                </Route>
                <Route path='/register'>
                  <Register />
                </Route>
                <Route path='/kategorien' component={Categories} />
                <Route path='/suche/' component={SearchPage} />
                <Route path='/produkte/:id/:category' component={Marketplace} />
                <Route path='/produkte' component={Marketplace} />
                <Route path='/händlerübersicht' component={VendorOverview} />
                <Route path='/myaccount' component={MyAccount} />
                <Route path='/haendler/:urlsegment/' component={VendorDetail} />
                <Route path='/händler-werden/' component={VendorHowToPage} />
                <Route path='/händler-anmeldung/' component={VendorRegisterForm} />
                <Route path='/kunden-anmeldung/' component={CustomerRegister} />
                <Route path='/produkt/:urlsegment' component={Product} />
                <Route path='/region/' exact component={RegionPage} />
                <Route path='/region/:tab' exact component={RegionPage} />
                <Route path='/news/:urlsegment' component={RegionNewsPage} />
                <Route path='/events/:urlsegment' component={RegionEventPage} />
                <Route exact path='/produkt-not-found'>
                  <NotFound />
                </Route>
                <Route exact path='/vendor-not-found'>
                  <NotFound />
                </Route>
                <Route exact path='/content/:urlsegment' component={Page} />
                <Route exact path='/checkout' component={Checkout} />
                <Route exact path='/'>
                  <Home />
                </Route>
                <Route default>
                  <NotFound />
                </Route>
              </Switch>
            </ScrollToTop>
          </div>
        </Suspense>
        <Footer />
      </>
    );
  }
}
