import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';

export const Loader = (props: LoaderProps): ReactElement => {

  const { text } = props;

  return (
    <div className='m-2 custom-loader'>
      <Spinner animation='border' role='status' className='mx-auto my-3 d-block'>
        <span className='sr-only'>{text}</span>
      </Spinner>
      <div className='text-center loading-text'>{text}</div>
    </div>
  );
};

Loader.defaultProps = {
  text: 'Loading...'
};

export type LoaderProps = {
  text?: string;
}
