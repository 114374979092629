import React, { Component, ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import SocialShareModal from '../Modals/SocialShareModal';
import './ShareSocials.scss';
import { ReactComponent as ShareIcon } from '../../assets/icons/Symbol_Teilen_neg.svg';

export type ShareSocialsProps = {
  link: string;
  title: string;
  previewImage?: string
}
type State = {
  showSocialModal: boolean;
}

class ShareSocials extends Component<ShareSocialsProps, State> {

  static defaultProps = {
    previewImage: undefined
  }

  constructor(props: ShareSocialsProps) {
    super(props);
    this.state = {
      showSocialModal: false
    };
  }

  hideSocialShareModal = (): void => this.setState({ showSocialModal: false });

  render(): ReactNode {
    const { showSocialModal } = this.state;
    const { link, title, previewImage } = this.props;
    return (
      <>
        <div className='social-share-wrapper'>
          <Button className='card-basket-ic btn btn-primary'
                  onClick={() => this.setState({ showSocialModal: true })}>
            <ShareIcon className='share-icon' />
          </Button>
          <SocialShareModal
            previewImage={previewImage}
            show={showSocialModal}
            title={title}
            onHide={this.hideSocialShareModal}
            url={`https://Region-im-Netz.at${link}`}
          />
        </div>
      </>


    );
  }
}

export default ShareSocials;
