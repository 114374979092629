import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Col, Container, Form, Image, Nav, Navbar} from 'react-bootstrap';
import Logo from "../../assets/img/logo_nav.png";
import MobileLogo from "../../assets/img/logo_region_mobilenav.png";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import Basket from "../Basket/BasketDropdown";
import BasketAction from "../../stores/basket/actions";
import {AnimatePresence, motion} from 'framer-motion'
import BookmarkCollectionAction from "../../stores/bookmarkCollections/actions";
import LineAwesome from "../LineAwesome/LineAwesome";
import SideBookMarks from "../SideBookMarks/SideBookMarks";
import CustomNavigation from "./CustomNavigation";
import NavIcons from "../newNav/NavIcons";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavExpanded: false,
            openCard: false,
            openBookmarks: false,
            searchOpen: false,
            showMobile: false,
            searchTerm: ''
        };

        this.searchTimeout = null;

        this.toggleCard = this.toggleCard.bind(this)
        this.toggleBookmarks = this.toggleBookmarks.bind(this)
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searchTerm !== prevState.searchTerm) {
            this.handleSearchTimeout();
        }
    }

    handleSearchTimeout = () => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.handleSearch(), 1000);
    }

    handleSearchTermChange = e => {
        this.setState({
            searchTerm: e.target.value
        })
    }

    handleSearch() {
        this.setState({
            searchOpen: false
        })
        this.props.history.push('/suche?search=' + this.state.searchTerm);
    }


    setIsNavExpanded = (isNavExpanded) => {
        this.setState({isNavExpanded: isNavExpanded});
    }

    closeMenu = () => {
        this.setState({isNavExpanded: false});
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            // if clicked inside menu do something
        } else {
            // If clicked outside menu, close the navbar.
            this.setState({isNavExpanded: false});
        }
    }

    componentDidMount() {
        this.props.setBasketDoneLoading();
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
        clearTimeout(this.searchTimeout);
    }

    toggleCard() {
        this.setState({
            openCard: !this.state.openCard,
            openBookmarks: false,
            showMobile: false
        })
    }

    toggleBookmarks() {
        this.setState({
            openBookmarks: !this.state.openBookmarks,
            openCard: false,
            showMobile: false
        })
    }

    toggleSearch = ()  => {
        this.setState({searchOpen: !this.state.searchOpen});
    };

    onLinkClick = () =>{
            this.setState({showMobile: false});
    };

    render() {
        return (
            <div ref={node => this.node = node}>
                <div className={"header-spacer"}/>
                <Navbar onToggle={this.setIsNavExpanded}
                        expanded={this.state.isNavExpanded} color="light" expand='lg'
                        className="navbar-light osahan-nav">
                    <Container>
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src={Logo}
                                    className="img-fluid"
                                    onClick={() => this.setState({showMobile: false})}
                                    style={{maxHeight: '60px'}}
                                />
                            </Link>
                        </Navbar.Brand>
                        <div className="d-flex align-items-center">
                            <NavIcons
                                handleSelect={this.closeMenu}
                                onLinkClick={this.onLinkClick}
                                toggleBookmarks={this.toggleBookmarks}
                                toggleCard={this.toggleCard}
                            />
                            <Navbar.Toggle onClick={() => this.setState({showMobile: !this.state.showMobile})}/>
                        </div>

                        <AnimatePresence exitBeforeEnter={true}>
                            {this.state.showMobile ?
                                <motion.div key={1}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            className="mobile-nav"
                                            id="mobile-nav"
                                >
                                    <div className="d-flex justify-content-between mb-3">
                                        <Navbar.Brand>
                                            <Link to="/">
                                                <Image
                                                    src={MobileLogo}
                                                    className="img-fluid"
                                                    onClick={() => this.setState({showMobile: false})}
                                                    style={{maxHeight: '60px'}}
                                                />
                                            </Link>
                                        </Navbar.Brand>
                                        <div className={"pt-1 pr-2"}>
                                            <LineAwesome icon={"times"} onClick={() => this.setState({showMobile: false})} className="close-nav-mobile" />
                                        </div>
                                    </div>
                                    <CustomNavigation
                                        handleSelect={this.closeMenu}
                                        onLinkClick={this.onLinkClick}
                                        isMobiel={true}
                                        toggleBookmarks={this.toggleBookmarks}
                                        toggleCard={this.toggleCard}
                                        searchToggle={this.toggleSearch}
                                    />
                                </motion.div>
                                :
                                null
                            }
                        </AnimatePresence>
                        <AnimatePresence exitBeforeEnter={true}>
                            {this.state.searchOpen && (
                                <motion.div key={1}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            className="navbar-collapse collapse"
                                            id="navbarNavDropdown"
                                >
                                    <Nav className="ml-auto" onSelect={this.closeMenu}>
                                        <div className="headerForm d-flex">
                                            <Form.Row className="align-items-center w-100 m-0 justify-content-end">
                                                <Col xs={11} lg={9}>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            id='searchInput'
                                                            type="text"
                                                            placeholder="Suche"
                                                            value={this.state.searchTerm}
                                                            onChange={this.handleSearchTermChange}
                                                            onKeyDown={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.handleSearch()
                                                                }
                                                            }}
                                                            size='sm'
                                                        />
                                                        <Form.Label htmlFor="searchInput">Suche</Form.Label>
                                                    </div>

                                                </Col>
                                                <Col xs={1} lg={1}>
                                                    <LineAwesome
                                                        icon='times'
                                                        className='ml-2 cursor-pointer search-close'
                                                        onClick={() => {
                                                            this.setState({searchOpen: !this.state.searchOpen})
                                                        }}/>
                                                </Col>
                                            </Form.Row>
                                        </div>
                                    </Nav>
                                </motion.div>
                            )}
                            {!this.state.searchOpen && (
                                <motion.div key={2}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            className="navbar-collapse collapse"
                                            id="navbarNavDropdown"
                                >
                                    <CustomNavigation
                                        handleSelect={this.closeMenu}
                                        toggleBookmarks={this.toggleBookmarks}
                                        toggleCard={this.toggleCard}
                                        searchToggle={this.toggleSearch}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>

                    </Container>
                </Navbar>
                <Basket openCard={this.state.openCard} toggleCard={this.toggleCard}/>
                {this.props.memberID ?
                    <SideBookMarks openBookmarks={this.state.openBookmarks} toggleBookmarks={this.toggleBookmarks}/>
                    :
                    null
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    basketItems: state.basket.basketItems,
    memberID: state.user.memberID,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
    clearBasket: () => dispatch(BasketAction.clearBasket()),
    setBasketDoneLoading: () => dispatch(BasketAction.setBasketDoneLoading()),
    clearCollections: () => dispatch(BookmarkCollectionAction.clearCollections()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Header));

