import axios from "axios";


const modifyItems = (payload, action, basketID, auth) => {
    if (auth) {
        const client = axios.create({
            baseURL: process.env.REACT_APP_BASKET_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorize': auth,
            },
            timeout: 15000,
        })
        return client.post('/modify/', {
            Action: action,
            Payload: payload,
            BasketID: basketID
        });
    }
    const client = axios.create({
        baseURL: process.env.REACT_APP_BASKET_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 15000,
    })
    return client.post('/modify/', {
        Action: action,
        Payload: payload,
        BasketID: basketID
    });
}

const checkBulkShipping = (basketID, bulkID) => {
    const client = axios.create({
        baseURL: process.env.REACT_APP_BASKET_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 15000,
    })
    return client.get(basketID + '/hasBulk/' + bulkID);
}


const fetchBasket = (auth) => {
    if (auth) {
        const client = axios.create({
            baseURL: process.env.REACT_APP_BASKET_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorize': auth,
            },
            timeout: 15000,
        })
        return client.get('/fetch/');
    }
    const client = axios.create({
        baseURL: process.env.REACT_APP_BASKET_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 15000,
    })
    return client.get('/fetch/');
}

export const BasketService = {
    modifyItems,
    checkBulkShipping,
    fetchBasket
};
