import React, {Component} from 'react';
import BasketAction from "../../stores/basket/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import Select2 from "react-select2-wrapper";
import LineAwesome from "../LineAwesome/LineAwesome";
import DefaultImage from "../../assets/img/default_img.png";

class CartDropdownItem extends Component {


    constructor(props) {
        super(props);

        this.state = {
            quantity: props.quantity ?? 0,
        }

        this.removeProduct = this.removeProduct.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.quantity !== this.state.quantity) {
            this.props.onChangeQuantity(this.props.product, this.state.quantity);
        }
    }

    removeProduct() {
        if (typeof this.props.onChangeQuantity === 'function') {
            this.props.onChangeQuantity(this.props.product,0);
        }
    }


    render() {
        return (
            <Row>
                <Col xs={3}>
                    {this.props.product.PreviewImage ?
                        <LazyImage src={this.props.product.PreviewImage} fluid/>
                        :
                        <LazyImage src={DefaultImage} fluid/>
                    }
                </Col>
                <Col xs={9}>
                    <Row>
                        <Col xs={12}><b>{this.props.title}</b></Col>
                        <Col xs={8}>
                            <div className='d-flex justify-content-start mt-1 select2-smaller align-items-center'>
                                <div className="text-black d-flex align-self-center mr-2 ">
                                    Menge:
                                </div>
                                <Select2
                                    data={Array.from(Array(99), (_, i) => i + 1)}
                                    value={this.state.quantity}
                                    options={{
                                        minimumResultsForSearch: Infinity
                                    }}
                                    onChange={(e) =>
                                        this.setState({quantity: e.target.value})

                                    }
                                />
                            </div>
                        </Col>
                        <Col xs={4} className='d-flex align-items-center justify-content-end'>
                            <span
                                className="text-secondary">€ {(this.props.product.Price * this.state.quantity).toFixed(2)}</span>
                            <LineAwesome
                                icon='trash'
                                className='ml-2 mr-2 cursor-pointer'
                                onClick={this.removeProduct}
                            />
                        </Col>
                        {this.props.additionalInfo ?
                            <Col xs={12}>
                                Zusatzinfo:<br/>
                                {this.props.additionalInfo}
                            </Col>
                            :
                            null
                        }
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    null,
    null,
)(withRouter(CartDropdownItem));
