import React, { Component } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApiService } from '../../services/ApiService';
import SlidingPanel from 'react-sliding-side-panel';
import LineAwesome from '../LineAwesome/LineAwesome';
import { toastr } from 'react-redux-toastr';
import LazyImage from '../LazyImage/LazyImage';
import UserActions from '../../stores/user/actions';
import BookmarkCollectionAction from '../../stores/bookmarkCollections/actions';
import DefaultImage from '../../assets/img/default_img.png';
import './SideBookMarks.scss';

class SideBookMarks extends Component {


  constructor(props) {
    super(props);

    this.state = {
      selectedCollectionID: 0,
      selectedCollectionTitle: '',
      currentCollection: {},
      loadingCollection: false,
      editTitle: false,
      deleteConfirm: false,
      newListTitle: 'Neue Merkliste',
      addNewList: false,
      chooseLastList: false
    };

    this.handleCollectionChange = this.handleCollectionChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleNewListTitleChange = this.handleNewListTitleChange.bind(this);
    this.handleSaveList = this.handleSaveList.bind(this);
    this.handleTitleSave = this.handleTitleSave.bind(this);
    this.fetchCollection = this.fetchCollection.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleBookmarks = this.toggleBookmarks.bind(this);
  }

  fetchCollection() {
    if (this.state.selectedCollectionID) {
      ApiService.authorizedClient(this.props.authKey).get('/BookmarkCollection/' + this.state.selectedCollectionID)
        .then((response) => {
          this.setState({
            currentCollection: response.data,
            selectedCollectionTitle: response.data.Title,
            loadingCollection: false
          });
        })
        .catch((error) => {
          let msg = 'Ein Fehler ist aufgetreten';
          if (error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message;
          }
          this.setState({ loadingCollection: false });
          toastr.error('Fehler beim Laden der Merkliste', msg);
        });
    }

  }

  componentDidMount() {
    this.props.fetchCollections();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newWidth = this.state.windowWidth;
    if (typeof this.state.width !== 'undefined') {
      if (this.state.width > 1399) {
        newWidth = 30;
      }
      if (this.state.width > 991 && this.state.width < 1399) {
        newWidth = 60;
      }
      if (this.state.width < 769) {
        newWidth = 90;
      }
      if (this.state.windowWidth !== newWidth) {
        this.setState({
          windowWidth: newWidth
        });
      }
    }

    if (prevProps.collectionsErrorMessage !== this.props.collectionsErrorMessage) {
      if (this.props.collectionsErrorMessage) {
        toastr.error('Fehler', this.props.collectionsErrorMessage);
      }
    }
    if (!this.state.selectedCollectionID && typeof this.props.bookmarkCollections[0] !== 'undefined') {
      this.setState({
        selectedCollectionID: this.props.bookmarkCollections[0].ID,
        selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
        loadingCollection: true
      }, () => this.fetchCollection());
    }
    if (prevState.selectedCollectionID !== this.state.selectedCollectionID) {
      this.setState({
        currentCollection: {},
        loadingCollection: true
      }, () => this.fetchCollection());
    }
    if (typeof this.props.bookmarkCollections[0] !== 'undefined') {
      if (JSON.stringify(prevProps.bookmarkCollections) !== JSON.stringify(this.props.bookmarkCollections)) {
        this.setState({
          selectedCollectionID: this.state.selectedCollectionID ?? this.props.bookmarkCollections[0].ID,
          selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
          loadingCollection: true
        }, () => this.fetchCollection());

      }
    }
    if (JSON.stringify(prevProps.bookmarkCollections) !== JSON.stringify(this.props.bookmarkCollections)) {
      if (this.state.chooseLastList) {
        this.setState({
          selectedCollectionID: this.props.bookmarkCollections[this.props.bookmarkCollections.length - 1].ID,
          selectedCollectionTitle: this.props.bookmarkCollections[this.props.bookmarkCollections.length - 1].Title,
          chooseLastList: false,
          loadingCollection: true
        }, () => this.fetchCollection());
      }
    }


  }

  handleCollectionChange(e) {
    var index = e.target.selectedIndex;
    this.setState({
      selectedCollectionID: e.target.value,
      selectedCollectionTitle: e.target[index].getAttribute('title') ?? '',
      editTitle: false,
      deleteConfirm: false,
      addNewList: false
    });

  }

  handleTitleChange(e) {
    this.setState({
      selectedCollectionTitle: e.target.value
    });
  }

  handleNewListTitleChange(e) {
    this.setState({
      newListTitle: e.target.value
    });
  }

  handleSaveList() {
    this.props.updateOrCreateCollection(0, this.state.newListTitle);
    this.setState({
      addNewList: false,
      chooseLastList: true
      // selectedCollectionID: 0 ?? this.state.selectedCollectionID
    });
    // setTimeout(() => {
    //     this.setState({
    //         chooseLastList: true
    //     })
    // },400)
  }

  handleTitleSave() {
    this.props.updateOrCreateCollection(this.state.selectedCollectionID, this.state.selectedCollectionTitle);
    this.setState({
      editTitle: false
    });
  }

  removeProduct(productID, title) {
    //this.props.addItem(this.state.selectedCollectionID,402)
    this.props.removeItem(this.state.selectedCollectionID, productID);
    toastr.success('"' + title + '" wurde entfernt', this.state.selectedCollectionTitle);
  }

  handleDelete() {
    let id = this.state.selectedCollectionID;
    this.props.deleteCollection(id);
    toastr.success('Merkliste wurde gelöscht', this.state.selectedCollectionTitle);
    this.setState({
      selectedCollectionID: 0,
      currentCollection: {},
      selectedCollectionTitle: '',
      deleteConfirm: false
    });
  }

  toggleBookmarks() {
    if (typeof this.props.toggleBookmarks === 'function') {
      this.props.toggleBookmarks();
    }
  }

  render() {
    return (
      <SlidingPanel
        type={'right'}
        isOpen={this.props.openBookmarks}
        size={this.state.windowWidth}
        backdropClicked={this.toggleBookmarks}
      >
        <div className='bookmark-overlay'>
          <div className='close-card p-3'>
            <LineAwesome icon='times-circle' onClick={this.toggleBookmarks} />
          </div>
          {this.props.collectionsAreLoading ?
            <Spinner animation='border' role='status' className='mx-auto my-3 d-block'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
            :
            this.props.bookmarkCollections.length ?
              <>
                <div className='p-3'>
                  <Row className='align-items-center'>
                    {this.state.addNewList ?
                      <>
                        <Col lg={8}>
                          <div className='form-label-group m-0'>
                            <Form.Control
                              type='text'
                              id='inputTitle'
                              placeholder='Titel'
                              onChange={this.handleNewListTitleChange}
                              disabled={this.state.loading}
                              value={this.state.newListTitle}
                              className='input-foreground'
                            />
                            <Form.Label htmlFor='inputTitle'>Titel</Form.Label>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className='d-flex align-items-center mt-3'>
                            <Button className='btn btn-primary'
                                    onClick={this.handleSaveList}>Anlegen</Button>
                            <LineAwesome
                              icon='times-circle'
                              className='ml-2 cursor-pointer search-close'
                              onClick={() => {
                                this.setState({ addNewList: !this.state.addNewList });
                              }} />
                          </div>
                        </Col>
                      </>
                      :
                      <>
                        <Col xs={10}>
                          <Form.Group controlId='title' className='m-0 d-flex  align-items-center'>
                            <Form.Control as='select'
                                          value={this.state.selectedCollectionID}
                                          onChange={this.handleCollectionChange}
                            >
                              {this.props.bookmarkCollections.map((item) =>
                                <option
                                  key={item.ID}
                                  value={item.ID}
                                  title={item.Title}
                                >
                                  {item.Title + ' (' + item.Count + ')'}
                                </option>
                              )}
                            </Form.Control>
                            <LineAwesome
                              title='Neue Merkliste hinzufügen'
                              icon='plus'
                              className='add-collection'
                              onClick={() => {
                                this.setState({ addNewList: !this.state.addNewList });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Link title='Name der Merkliste ändern' to='/myaccount/bookmarks'
                                onClick={this.toggleBookmarks}><LineAwesome title='Name der Merkliste ändern' icon='pen'
                                                                            size='3x' /></Link>
                        </Col>

                      </>
                    }

                  </Row>
                </div>
                {this.state.addNewList ?
                  null
                  :
                  <Row className='pt-4 pb-3'>
                    <Col lg={12}>
                      {this.state.loadingCollection ?
                        <Spinner animation='border' role='status' className='mx-auto my-3 d-block'>
                          <span className='sr-only'>Loading...</span>
                        </Spinner>
                        :
                        typeof this.state.currentCollection.Products === 'undefined' ?
                          <div class='text-center'>Du hast keine Produkte in dieser Merkliste</div>
                          :
                          this.state.currentCollection.Products.map((item, index) =>
                            <div key={index} className='py-2 px-3 border-top'>
                              <Row>
                                <Col xs={2}>
                                  <Link
                                    to={'/produkt/' + item.URLSegment + ''}>
                                    <LazyImage
                                      src={item.PreviewImage ?? DefaultImage}
                                      fluid
                                      className='checkout-product-img' />
                                  </Link>
                                </Col>
                                <Col xs={4} className='d-flex align-items-center'>
                                                                <span>
                                                                    <b className='d-block w-100 mb-1'>
                                                                    <Link
                                                                      to={'/produkt/' + item.URLSegment + ''}>{
                                                                      item.Title}
                                                                    </Link>
                                                                </b>
                                                                <span className='d-block w-100'>
                                                                <Link to={'/haendler/' + item.VendorURLSegment}>
                                                                    {item.VendorTitle}
                                                                </Link>
                                                                </span>
                                                                </span>

                                </Col>
                                <Col xs={4} className='d-flex align-items-center'>
                                  € {item.Price.toFixed(2)}
                                </Col>
                                <Col xs={2}
                                     className='d-flex align-items-center justify-content-end text-right'>
                                  <LineAwesome icon='trash'
                                               className='ml-2 mr-2 cursor-pointer'
                                               size='2x'
                                               onClick={() => this.removeProduct(item.ID, item.Title)} />
                                </Col>
                              </Row>
                            </div>
                          )
                      }
                    </Col>
                  </Row>
                }

              </>
              :
              <p>{this.props.collectionsErrorMessage ?? ''}</p>
          }
        </div>
      </SlidingPanel>
    );

  }

}

const mapStateToProps = (state) => ({
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  bookmarkCollections: state.bookmarkCollection.bookmarkCollections,
  collectionsAreLoading: state.bookmarkCollection.collectionsAreLoading,
  collectionsErrorMessage: state.bookmarkCollection.collectionsErrorMessage
});


const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(UserActions.fetchUser()),
  fetchCollections: () => dispatch(BookmarkCollectionAction.fetchCollections()),
  updateOrCreateCollection: (listID, title) => dispatch(BookmarkCollectionAction.updateOrCreateCollection(listID, title)),
  removeItem: (listID, productID) => dispatch(BookmarkCollectionAction.removeItem(listID, productID)),
  addItem: (listID, productID) => dispatch(BookmarkCollectionAction.addItem(listID, productID)),
  deleteCollection: (listID) => dispatch(BookmarkCollectionAction.deleteCollection(listID))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideBookMarks));
