import React, {Component} from 'react';
import {CardElement} from "@stripe/react-stripe-js";
import {Alert, Button, Spinner} from "react-bootstrap";
import './CreditCrad.style.scss';

export default class CreditCardPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            disabled: true,
            succeeded: false,
            stripeError: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (event) => {
        this.setState({
            disabled: !event.complete,
            stripeError: event.error ? event.error.message : "",
        })
    };

    handleSubmit = async ev => {
        if (this.props.buttonDisabled) {
            return false;
        }
        ev.preventDefault();
        this.setState({
            processing: true
        })
        const payload = await this.props.stripe.confirmCardPayment(
            this.props.stripeClientSecret,
            {
                payment_method: {
                    card: this.props.elements.getElement(CardElement),
                    billing_details: {
                        name: ev.target.name.value
                    }
                }
            }
        )
        if (payload.error) {
            this.setState({
                stripeError: 'Zahlung fehlgeschlagen ' + payload.error.message,
                processing: false
            })
        } else {
            this.setState({
                stripeError: '',
                processing: false,
                succeeded: true
            })
            if (typeof this.props.onSubmitAction === 'function') {
                this.props.onSubmitAction()
            }
        }
    }

    render() {
        return (
            <form id="credit-card-form" onSubmit={this.handleSubmit}>
                {/* Show any error that happens when processing the payment */}
                {this.state.stripeError && (
                    <Alert variant="danger">
                        {this.state.stripeError}
                    </Alert>
                )}
                <CardElement
                    id="card-element"
                    className='card-element'
                    options={{
                        style: {
                            base: {
                                color: "#000",
                                fontFamily: 'Arial, sans-serif',
                                fontSmoothing: "antialiased",
                                fontSize: "16px",
                                "::placeholder": {
                                    color: "#6a6a6a"
                                }
                            },
                            invalid: {
                                color: "#b83118",
                                iconColor: "#b83118"
                            }
                        }
                    }
                    }
                    onChange={this.handleChange}
                />
                <Button
                    className='btn btn-success btn-block py-3 text-white text-center'
                    disabled={this.state.processing || this.state.disabled || this.state.succeeded || this.props.buttonDisabled}
                    id="submit"
                    type='submit'
                >
                    {this.state.processing ? (
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        "Zahlungspflichtig bestellen"
                    )}
                </Button>
            </form>
        );

    }
}
