import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ShareSocials from '../ShareSocials/ShareSocials';
import LineAwesome from '../LineAwesome/LineAwesome';
import './CardItemButtons.scss';

export const CardItemButtons = (props: CardItemButtonProps): ReactElement => {
  const { linkUrl, title, icon, previewImage } = props;

  return (
    <>
      <div className='card-item-buttons'>
          <ShareSocials
            link={linkUrl}
            previewImage={previewImage}
            title={title}
          />
        <Link to={linkUrl} className='card-basket-icon align-self-center'>
          <LineAwesome icon={icon} size='3x' />
        </Link>
      </div>

    </>
  );
};

CardItemButtons.defaultProps = {
  previewImage: undefined
}

export type CardItemButtonProps = {
  linkUrl: string;
  title: string;
  icon: string;
  previewImage?: string;
}
