export const breakpoints: DeviceBreakPoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920
};

export type DeviceBreakPoints = { [key in DeviceBreakPoint]: number }

export type DeviceBreakPoint = 'mobile' | 'mobileLandscape' | 'tablet' | 'tabletLandscape' | 'desktop' | 'desktopLarge' | 'desktopWide';
