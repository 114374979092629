import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

const abortController = new AbortController();
const defaultTimeout = 50000;

const cache = setupCache({
  maxAge: 5 * 60 * 1000
});

const client = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: defaultTimeout
});


const baseClient = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_BASE_URL_NO_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: defaultTimeout
});

const authorizedClient = (key) => axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorize': key
  },
  timeout: defaultTimeout
});

const vatClient = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_VAT_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: defaultTimeout
});

const geocodeFromLatLng = (lat, lng) => {
  const client = axios.create({
    adapter: cache.adapter,
    baseURL: process.env.REACT_APP_GEO_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: defaultTimeout
  });
  return client.get('?latlng=' + lat + ',' + lng);
};

const getAddressObject = (data) => {
  let address = {};
  data.map((item) => {
    address[item.types[0]] = item;
  });

  return address;
};

const serialize = (obj, prefix) => {
  let str = [],
    p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];

      str.push((v !== null && typeof v === 'object') ?
        serialize(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }

  return str.join('&');
};
const stripClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_STRIPE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: defaultTimeout
});

export const ApiService = {
  baseClient,
  client,
  vatClient,
  authorizedClient,
  serialize,
  geocodeFromLatLng,
  getAddressObject,
  stripClient,
  abortController
};

