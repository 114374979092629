import React, { ReactElement } from 'react';
// @ts-ignore
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './LocationField.scss';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import CoordsActions from '../../stores/coords/actions';
import LineAwesome from '../LineAwesome/LineAwesome';
import { logError } from '../../utils/Logging';
import { CoordsState, RootAppState } from '../../stores/basket/initialState';
import { geocodeLocation } from '../../utils/locationUtils';
import { GeocoderResult } from '../../types/googleMaps';

let timeout: any;

class LocationField extends React.Component<LocationFieldProps, State> {
  static defaultProps = {
    className: '',
    onHandleSelect: undefined,
    coords: undefined
  };

  constructor(props: LocationFieldProps) {
    super(props);
    this.state = {
      address: '',
      locating: false,
      latitude: 0,
      longitude: 0,
      sessionToken: null
    };

    this.updateGeoPosition = this.updateGeoPosition.bind(this);
    this.handleLocateClick = this.handleLocateClick.bind(this);
  }

  componentDidMount(): void {
    const { coords } = this.props;
    this.setSessionToken();
    if (coords && coords.latitude) {
      this.updateGeoPosition(coords);
      if (coords.geocodeAddress && coords.geocodeAddress.formatted_address) {
        this.setState({ address: coords.geocodeAddress.formatted_address },
          () => this.setState({
            locating: false
          })
        );
      }
    } else {
      this.handleLocateClick();
    }
  }

  handleLocateClick(): void {
    const { reset, updateLocation } = this.props;
    this.setState({ locating: true });
    if (reset) {
      reset();
    }
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        updateLocation(position.coords.latitude, position.coords.longitude, true);
        geocodeLocation(position.coords.latitude, position.coords.longitude).then((response: GeocoderResult) => {
          this.setState({ locating: false, address: response.formatted_address });
        }).catch((error) => {
          logError(error);
          this.setState({ locating: false });
        });
      }, (error) => {
        logError(error);
        this.setState({ locating: false });
      }, { timeout: 15000, enableHighAccuracy: true, maximumAge: Infinity });
    } catch (error) {
      logError(error);
      this.setState({ locating: false });
    }
  }

  handleChange = (address: string): void => {
    this.setState({ address });
  };

  handleSelect = (address: string): void => {
    const { onHandleSelect, updateLocation } = this.props;
    const query = address;
    geocodeByAddress(address)
      .then(results => {
        const firstResult = results[0];
        return getLatLng(firstResult);
      })
      .then(latLng => {
        this.setSessionToken();
        updateLocation(latLng.lat, latLng.lng, query);
      })
      .catch(error => logError('Error', error));

    this.handleChange(address);
    if (typeof onHandleSelect === 'function') {
      onHandleSelect();
    }
  };

  setSessionToken(): void {
    try {
      const token = new (window as any).google.maps.places.AutocompleteSessionToken();
      this.setState({
        sessionToken: token
      });
    } catch (error) {
      logError(error);
    }
  }

  updateGeoPosition(position: CoordsState): void {
    const { coords } = this.props;
    this.setState({
      locating: false,
      latitude: position.latitude,
      longitude: position.longitude,
      address: position.geocodeAddress.formatted_address ?? ''
    });

    if (coords && !coords.custom) {
      clearTimeout(timeout);
    }
  }

  render(): ReactElement {
    const { latitude, locating, sessionToken, address, longitude } = this.state;
    const { className } = this.props;
    const searchOptions = {} as any;
    if (latitude) {
      searchOptions.location = { lat: () => latitude, lng: () => longitude };
      searchOptions.radius = 10000;
    }
    searchOptions.sessionToken = sessionToken;
    const addressValue = locating ? '' : address;
    return (
      <PlacesAutocomplete
        value={addressValue}
        googleCallbackName='initMaps'
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Form.Group className={`${className} location-field`}>
              <Form.Control {...getInputProps({
                placeholder: 'zB. "4020 Linz" oder "Zeppelinstr 12, 4600 Wels"',
                className: 'location-search-input',
                size: 'lg'
              })} value={address} disabled={locating} />
              <button
                type={'button'}
                className='locate-me'
                onClick={this.handleLocateClick}
                disabled={locating}
              >
                <LineAwesome icon='location-arrow' /> <span>Standort bestimmen</span> {locating && '...'}
              </button>
              <div className='autocomplete-dropdown-container'>
                {loading && <div className='suggestion-item'>Lade...</div>}
                {suggestions.map((suggestion, index) => {
                  const itemClassName = suggestion.active
                    ? 'suggestion-item active'
                    : 'suggestion-item';
                  return (
                    <div {...getSuggestionItemProps(suggestion, {
                      className: itemClassName,
                      key: suggestion.id
                    })}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </Form.Group>
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

const mapStateToProps = (state: RootAppState): MappedProps => ({
  coords: state?.coords
});

const mapDispatchToProps = (dispatch: any): MappedDispatch => ({
  updateLocation: (latitude: number, longitude: number, custom: string) => dispatch(CoordsActions.updateLocation(latitude, longitude, custom)),
  setGeoAddress: () => dispatch(CoordsActions.setGeoAddress()),
  reset: () => dispatch(CoordsActions.reset())
});
const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);


export default connector(LocationField);

type PropsFromRedux = ConnectedProps<typeof connector>

interface State {
  address: string;
  locating: boolean;
  latitude: number;
  longitude: number;
  sessionToken: any;
}

type OwnProps = {
  className?: string;
  onHandleSelect?: () => void
}

type MappedDispatch = {
  updateLocation: any,
  reset: any
  setGeoAddress: any
}

type MappedProps = {
  coords?: CoordsState
};

export type LocationFieldProps = PropsFromRedux & MappedProps & OwnProps;

export type AddressObject = {
  // eslint-disable-next-line camelcase
  postal_code: string;
  route: string;
  // eslint-disable-next-line camelcase
  street_number: string;
  locality: string;
  country: string;
}
