import React, { Component } from 'react';
import LineAwesome from '../LineAwesome/LineAwesome';
import { HTMLService } from '../../services/HTMLService';

export default class CreditOverlay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenCredit: false
    };
  }

  render() {
    const { isOpenCredit } = this.state;
    const { credit } = this.props;
    return (
      <>
        <div className='credit-icon-for-Overlay'
             onClick={() => this.setState({ isOpenCredit: !isOpenCredit })}>
          <LineAwesome icon='copyright' />
        </div>
        {isOpenCredit &&
          <div className='credit-overlay'>
            <LineAwesome icon='times' className='close-icon'
                         onClick={() => this.setState({ isOpenCredit: !isOpenCredit })} />
            <p dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(credit) }} />
          </div>
        }
      </>
    );
  }
}
