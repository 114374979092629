import React,{Component} from "react";
import MetaTags from 'react-meta-tags';
import OGimgURL from '../../assets/img/logo.png'

export default class CustomMetaTags extends Component{

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title ?? 'Region Im Netz',
            desc: this.props.desc ?? 'Unternehmer aus der Nähe',
            img: this.props.img ?? OGimgURL,
        }
    }

    render() {
        return(
            <MetaTags>
                <title>{this.state.title}</title>
                <meta id="meta-description" name="description" content={this.state.desc}/>
                <meta id="og-title" property="og:title" content={this.state.title}/>
                <meta id="og-image" property="og:image" content={this.state.img}/>
            </MetaTags>
        )
    }
}
