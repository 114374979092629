import React, { Component } from 'react';
import ReactBreakpoints from '@kaunio/react-breakpoints';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Wrapper from './Wrapper';
import createStore from '../../stores';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { breakpoints } from '../../constants/DeviceBreakpoints';

const { store, persistor } = createStore();
export { store };

export default class Root extends Component {

  render() {
    return (
      <ReactBreakpoints breakpoints={breakpoints}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <div style={{ position: 'relative' }}>
              <AnimatePresence>
                <Router>
                  <Route path='/' component={Wrapper} />
                </Router>
              </AnimatePresence>
            </div>
          </PersistGate>
        </Provider>
      </ReactBreakpoints>
    );
  }
}
