import React, {Component} from 'react';
import {Badge, Image} from 'react-bootstrap';
import LineAwesome from "../LineAwesome/LineAwesome";

export default class DropDownTitle extends Component {
    static defaultProps = {
        icon: 'shopping-cart',
        className: '',
        imageAlt: '',
        image: '',
        imageClass: '',
        badgeVariant: '',
        badgeClass: '',
        badgeValue: 0,
    }

    render() {
        return (
            <div className={this.props.className}>
                {
                        this.props.image ?
                        <Image alt={this.props.imageAlt} src={this.props.image} className={this.props.imageClass}/>
                        : ''
                }

                {(this.props.icon && !this.props.image) ?
                    <LineAwesome icon={this.props.icon}/> : ''
                }

                {' ' + this.props.title}

                {this.props.badgeValue ?
                    <Badge variant={this.props.badgeVariant}
                           className={this.props.badgeClass}>{this.props.badgeValue}</Badge>
                    : ''
                }
            </div>
        );
    }
}
