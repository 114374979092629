import { ApiService } from './ApiService';
import DefaultImage from '../assets/img/platzhalter_produkt.jpg';
import { logError, logInfo } from '../utils/Logging';
import { toastr } from 'react-redux-toastr';

export function getLogoFromId(logoId: number, width: number = 300, height: number = 300): Promise<any> {
  return new Promise((resolve) => {
    if (logoId && logoId !== 0) {
      ApiService.client.get(`/Image/${logoId}/FitMax/${width}/${height}/`)
        .then((result) => {
          resolve(result.data.AbsoluteURL);
        })
        .catch((error) => {
          logError(error);
          resolve(DefaultImage);
        });
    } else {
      resolve(DefaultImage);
    }
  });
}

export async function getVendorPaymentID(vendorID: string): Promise<any> {
  try {
    if (vendorID) {
      const res = await ApiService.client.get(`/Vendor/${vendorID}`);
      return Promise.resolve(res.data.StripePaymentID);
    }
    return Promise.reject();
  } catch {
    return Promise.reject();
  }
}

export async function getVendor(vendorID: string): Promise<any> {
  try {
    if (vendorID) {
      const res = await ApiService.client.get(`/Vendor/${vendorID}`);
      return Promise.resolve(res);
    }
    return Promise.reject();
  } catch {
    return Promise.reject();
  }
}

export async function getVendorAddress(vendorID: string): Promise<any> {
  try {
    const vend = await getVendor(vendorID);
    try {
      const res = await ApiService.client.get(`Address/${vend.data.AddressID}`);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}



