import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { logError } from './Logging';
import packageJson from '../../package.json';

let enabled = false;

const captureException = (errorInfo: any): void => {
  if (enabled) {
    Sentry.captureException(errorInfo);
  } else {
    logError(errorInfo);
  }
};

const sentryInit = (): void => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      integrations: [new BrowserTracing()],
      release: packageJson.version
    });
    enabled = true;
  }
};

export { captureException, sentryInit };
