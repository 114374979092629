/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import {createReducer} from 'reduxsauce';
import {INITIAL_STATE} from './initialState';
import {CoordsActionTypes} from './actions';

export const updateLocation = (state, {longitude, latitude, custom}) => {
    if (state.custom === true && custom === false) {
        return state;
    }

    return {
        ...state,
        longitude: longitude,
        latitude: latitude,
        custom: custom,
    };
};

export const setGeocodeAddress = (state, {address}) => {

    return {
        ...state,
        geocodeAddress: address
    };
};
export const reset = (state) => {
    return {
        ...state,
        custom: false
    }
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
    [CoordsActionTypes.UPDATE_LOCATION]: updateLocation,
    [CoordsActionTypes.SET_GEOCODE_ADDRESS]: setGeocodeAddress,
    [CoordsActionTypes.RESET]: reset,
});
