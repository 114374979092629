import React, { Component, ReactNode } from 'react';
import { Badge, Col, Container, Image, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import ItemsCarousel from '../../components/ItemsCarousel/ItemsCarousel';
import { ApiService } from '../../services/ApiService';
import LazyImage from '../../components/LazyImage/LazyImage';
import MarketplaceBackground from '../../assets/img/marketplaceBackground.jpeg';
import SimpleGallery from '../../components/SimplleGallery/SimplleGallery';
import Map from '../../components/Map/Map';
import GoogleMarker from '../../components/GoogleMarker/GoogleMarker';
import ProductCardItem from '../../components/CardItem/ProductCardItem';
import DefaultImage from '../../assets/img/default_img.png';
import LineAwesome from '../../components/LineAwesome/LineAwesome';
import { HTMLService } from '../../services/HTMLService';
import CustomMetaTags from '../../components/CustomMetaTags/CustomMetaTags';
import NewsCardItem from '../RegionPage/NewsCardItem';
import CreditOverlay from '../../components/CreditContainer/CreditOverlay';
import { ContactButton } from '../../components/ContactComponents/ContactButton';
import DSGVOAction from '../../stores/dsgvo/actions';
import { RootAppState } from '../../stores/basket/initialState';
import { NotFound } from '../../components/NotFound/NotFound';
import JobCardItem from '../RegionPage/JobCardItem';
import { Trans } from 'react-i18next';

class VendorDetail extends Component<VendorDetailProps, State> {
  constructor(props: VendorDetailProps) {
    super(props);

    this.state = {
      vendor: {},
      logo: '',
      images: [],
      address: {},
      showAddressModal: false,
      products: [],
      saleProducts: [],
      loadingProducts: true,
      gastroMenus: [],
      showProductTab: false,
      startTab: 'first',
      followLoading: true,
      followed: false,
      isOpenCredit: false,
      imageLoading: true,
      events: [],
      jobs: [],
      news: [],
      isUnion: false,
      vendorservices: []
    };

    this.addVendorToCustomer = this.addVendorToCustomer.bind(this);
    this.removeVendorToCustomer = this.removeVendorToCustomer.bind(this);
  }

  loadVendor() {
    const { urlsegment } = this.props.match.params;
    if (urlsegment) {
      ApiService.client.get(`/Vendor/?filter[URLSegment]=${urlsegment}`)
        .then((result) => {
          if (result.data.length > 0) {
            let showProduct = false;
            if (!result.data[0].IsUnion) {
              showProduct = true;
            }
            this.setState({
              vendor: result.data[0],
              showProductTab: showProduct
            }, () => {
              this.loadLogo();
              this.loadMenus(result.data[0].ID);
              this.loadEvents(result.data[0].ID);
              this.loadJobs(result.data[0].ID);
              this.loadNews(result.data[0].ID);
              this.loadService(result.data[0].ID);
              this.checkFollow(result.data[0].ID);
              this.loadImages();
              this.loadAddress();
              this.loadProducts(result.data[0].ID);
            });
          } else {
            this.props.history.push('/vendor-not-found');
          }
        }).catch((error) => {
        let msg = 'Ein Fehler ist aufgetreten';
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({ loading: false });
      });
    }

  }

  loadJobs = (id) => {
    const filter = `?sort=Created DESC&filter[VendorID]=${id}&filter[Inactive]=0&filter[MenuPlan]=0&filter[Job]=1`;

    ApiService.client.get(`/RegionNews/${filter}`)
      .then((result) => {
        this.setState({
          jobs: result.data,
          isUnion: false,
          loading: false
        });
      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler laden der Produkte', msg);
      }
      this.setState({ loadingProducts: false });
    });
  };

  loadNews = (id) => {

    /* +  */
    let filter = `?sort=EndDate ASC&filter[VendorID]=${id}` /* + '&filter[Inactive]=0&filter[MenuPlan]=0&filter[Job]=0&filter[Service]=0' */;
    filter += `&filter[EndDate:GreaterThanOrEqual]=${moment().utc().format()}`;
    ApiService.client.get(`/RegionNews/${filter}`)
      .then((result) => {
        if (result.data.length) {
          this.setState({
            news: result.data,
            loading: false
          });
        } else {
          this.loadUnionNews(id);
        }

      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler laden der Produkte', msg);

      }
      this.setState({ loadingProducts: false });
    });
  };

  loadUnionNews = (id) => {
    const filter = `?sort=Created DESC&filter[VendorID]=${id}&filter[Inactive]=0`;
    ApiService.client.get(`/RegionUnionNews/${filter}`)
      .then((result) => {
        this.setState({
          news: result.data,
          isUnion: true,
          loading: false
        });
      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler laden der Produkte', msg);

      }
      this.setState({ loadingProducts: false });
    });
  };

  loadService = (id) => {
    const filter = `?sort=Created DESC&filter[VendorID]=${id}&filter[Inactive]=0&filter[MenuPlan]=0&filter[Job]=0&filter[Service]=1`;

    ApiService.client.get(`/RegionNews/${filter}`)
      .then((result) => {
        this.setState({
          vendorservices: result.data,
          loading: false
        });
      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler laden der Produkte', msg);

      }
      this.setState({ loadingProducts: false });
    });
  };

  loadEvents = (id) => {
    let filter = `&filter[EventEndDate:GreaterThanOrEqual]=${moment().utc().format()}`;
    ApiService.client.get(`/RegionEvent/?sort=EventDate ASC&filter[VendorID]=${id}` + filter)
      .then((result) => {
        this.setState({
          events: result.data ?? []
        });
      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler', msg);
      }
      this.setState({ loading: false });
    });
  };

  loadMenus(id) {
    ApiService.client.get(`/GastroMenu/?filter[VendorID]=${id}`)
      .then((result) => {
        if (result.data.length) {
          document.getElementById('infoTab').click();
        }
        this.setState({
          gastroMenus: result.data ?? []
        });
      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler', msg);
      }
      this.setState({ loading: false });
    });
  }

  loadProducts(vendorID) {
    ApiService.client.get(`/Product/?sort=Created%20DESC&filter[VendorID]=${vendorID}&filter[Inactive]=0`)
      .then((result) => {
        this.setState({
          products: result.data,
          saleProducts: result.data.filter((item) => item.Sale === 1),
          loadingProducts: false
        }, () => {
          if (this.state.products.length) {
            const params = queryString.parse(this.props.location.search);
            if (typeof params.p !== 'undefined') {
              document.getElementById('ptab').click();
            }
          }
        });

      }).catch((error) => {
      let msg = 'Ein Fehler ist aufgetreten';
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        toastr.error('Fehler laden der Produkte', msg);
      }
      this.setState({ loadingProducts: false });
    });
  }

  loadLogo() {
    if (this.state.vendor.LogoID) {
      ApiService.client.get(`/Image/${this.state.vendor.LogoID}/FitMax/400/400/`)
        .then((result) => {
          this.setState({
            logo: result.data.AbsoluteURL
          });
        }).catch((error) => {
        this.setState({
          logo: DefaultImage
        });
      });
    } else {
      this.setState({
        logo: DefaultImage
      });
    }

  }

  loadImages() {
    ApiService.client.get(`/Vendor/${this.state.vendor.ID}/getSortedImages/`)
      .then((response) => {
        this.setState({
          imageLoading: false,
          images: response.data
        });
      })
      .catch((error) => {
        let msg = 'Ein Fehler ist aufgetreten';
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({ loading: false });
        toastr.error('Fehler laden des Bildes', msg);
      });
  }

  loadAddress() {
    ApiService.client.get(`/Address/${this.state.vendor.AddressID}`)
      .then((result) => {
        this.setState({
          address: result.data
        });
      }).catch((error) => {
    });
  }

  getHeaderImage() {
    if (this.state.images.length > 0) {
      return this.state.images[0].AbsoluteURL;
    }
    return MarketplaceBackground;
  }

  componentDidMount() {
    this.loadVendor();
  }

  checkFollow(id) {
    if (id && this.props.memberID) {
      const me = this;
      ApiService.authorizedClient(me.props.authKey).get(`/Member/${me.props.memberID}/isAlreadyFollowing/${id}`)
        .then((response) => {

          if (typeof response.data.ID !== 'undefined') {
            me.setState({
              followed: true,
              followLoading: false
            });
          } else {
            me.setState({
              followed: false,
              followLoading: false
            });
          }
          // toastr.success('Händler folgen', 'Du folgst jetzt ' + me.state.vendor.Title);
        })
        .catch((error) => {
          let msg = 'Ein Fehler ist aufgetreten';
          if (error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message;
          }
          me.setState({ loading: false });
          toastr.error('Fehler laden des Bildes', msg);
        });
    }
  }

  addVendorToCustomer() {
    if (this.state.vendor.ID && this.props.memberID) {
      const me = this;
      ApiService.authorizedClient(me.props.authKey).put(`/Member/${me.props.memberID}/addVendor/${me.state.vendor.ID}`)
        .then((response) => {
          me.setState({
            followed: true
          });
          // toastr.success('Händler folgen', 'Du folgst jetzt ' + me.state.vendor.Title);
        })
        .catch((error) => {
          let msg = 'Ein Fehler ist aufgetreten';
          if (error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message;
          }
          me.setState({ loading: false });
          toastr.error('Fehler laden des Bildes', msg);
        });
    }
  }

  removeVendorToCustomer() {
    if (this.state.vendor.ID && this.props.memberID) {
      const me = this;
      ApiService.authorizedClient(me.props.authKey).put(`/Member/${me.props.memberID}/removeVendor/${me.state.vendor.ID}`)
        .then((response) => {
          me.setState({
            followed: false
          });
          // toastr.success('Händler folgen', 'Du folgst jetzt ' + me.state.vendor.Title);
        })
        .catch((error) => {
          let msg = 'Ein Fehler ist aufgetreten';
          if (error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message;
          }
          me.setState({ loading: false });
          toastr.error('Fehler laden des Bildes', msg);
        });
    }
  }

  render(): ReactNode {
    const { vendor, logo } = this.state;
    const ref = React.createRef();
    const displayVendor = !vendor.Suspended;

    return (
      <>
        {displayVendor ?
          <>
            {typeof vendor.Title !== 'undefined' && logo ?
              <CustomMetaTags
                title={`Region im Netz - ${vendor.Title ?? 'Unternehmerdetails'}`}
                desc={vendor.ShortDescription}
                img={logo}
              />
              :
              <CustomMetaTags
                title='Region im Netz - Unternehmerdetails'
              />
            }
            <section className='restaurant-detailed-banner'>
              {vendor.FotoCredits &&
                <CreditOverlay credit={vendor.FotoCredits} />
              }
              {(vendor.FotoCreditsRAW && !vendor.FotoCredits) &&
                <CreditOverlay credit={vendor.FotoCreditsRAW} />
              }

              <div className='text-center'>
                {!this.state.imageLoading ?
                  <Image fluid className='cover' src={this.getHeaderImage()} />
                  :
                  <div className='img-fluid cover' />
                }

              </div>
              <div className='restaurant-detailed-header'>
                <Container>
                  <Row className='d-flex align-items-end'>
                    <Col lg={12}>
                      <div
                        className='restaurant-detailed-header-right d-flex flex-wrap justify-content-end align-items-center'>
                        {vendor.Garden ?
                          <Badge variant='primary'
                                 className='p-2 category-tag ml-1 mt-1'>
                            Gastgarten
                          </Badge>
                          :
                          null
                        }
                        {vendor.DeliveryServiceText ?
                          <Badge variant='primary' onClick={() => {
                            document.getElementById('infoTab').click();
                            setTimeout(() => {
                              ref.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start'
                              });
                            }, 400);

                            // window.scrollTo(0, deliverInfo.offsetTop)
                          }
                          }
                                 className='p-2 category-tag ml-1 mt-1 cursor-pointer'>
                            Lieferservice
                          </Badge>
                          :
                          null
                        }
                        <div
                          className={`contact-icon-holder ${vendor.Garden || vendor.DeliveryServiceText ? 'show-border' : ''}`}>
                          {vendor.Tel &&
                            <a className='contact-icon phone'
                               href={`tel:${vendor.Tel}`}><LineAwesome
                              icon='phone' /></a>
                          }
                          {vendor.FBMessengerUser &&
                            <a className='contact-icon fb-m' target='_blank'
                               href={`https://fb.com/msg/ ${vendor.FBMessengerUser}`}
                               rel='noreferrer'>
                              <LineAwesome iconStyle='lab' icon='facebook-messenger' /></a>
                          }
                          {vendor.FBLink &&
                            <a className='contact-icon fb' target='_blank'
                               href={!vendor.FBLink.includes('http://') && !vendor.FBLink.includes('https://') ? `https://${vendor.FBLink}` : vendor.FBLink}
                               rel='noreferrer'>
                              <LineAwesome iconStyle='lab' icon='facebook' /></a>
                          }
                          {vendor.InstagramLink &&
                            <a className='contact-icon insta' target='_blank'
                               href={`https://instagram.com/${vendor.InstagramLink}`}
                               rel='noreferrer'>
                              <LineAwesome iconStyle='lab' icon='instagram' /></a>
                          }
                          {vendor.WhatsappNumber &&
                            <a className='contact-icon whatsapp' target='_blank'
                               href={`https://wa.me/${vendor.WhatsappNumber}`}
                               rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='whatsapp' /></a>
                          }
                          {vendor.TelegramUser &&
                            <a className='contact-icon telegram' target='_blank'
                               href={`https://telegram.me/${vendor.TelegramUser}`} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='telegram' /></a>
                          }

                          {vendor.YoutubeLink &&
                            <a className='contact-icon youtube' target='_blank'
                               href={vendor.YoutubeLink} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='youtube' /></a>
                          }
                          {vendor.TwitterLink &&
                            <a className='contact-icon twitter' target='_blank'
                               href={vendor.TwitterLink} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='twitter' /></a>
                          }
                          {vendor.LinkedInLink &&
                            <a className='contact-icon linkedin' target='_blank'
                               href={vendor.LinkedInLink} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='linkedin' /></a>
                          }
                          {vendor.XingLink &&
                            <a className='contact-icon xing' target='_blank'
                               href={vendor.XingLink} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='xing' /></a>
                          }
                          {vendor.PinterestLink &&
                            <a className='contact-icon pinterest' target='_blank'
                               href={vendor.PinterestLink} rel='noreferrer'><LineAwesome
                              iconStyle='la' icon='pinterest' /></a>
                          }
                          {vendor.Website &&
                            <a className='contact-icon web' target='_blank'
                               href={!vendor.Website.includes('http://') && !vendor.Website.includes('https://') ? `http://${vendor.Website}` : vendor.Website}
                               rel='noreferrer'>
                              <LineAwesome iconStyle='la' icon='globe-europe' /></a>
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
            <div className='vendor-details-title py-3 bg-white'>
              <Container>
                <Row className='d-flex align-items-center'>
                  <Col md={9}>
                    <div className='restaurant-detailed-header-left '>
                      <div className='d-flex align-items-center pb-3'>
                        <LazyImage fluid className='mr-3' alt='this.state.vendor.Title'
                                   src={this.state.logo} />
                        <h2 className='text-black'>{this.state.vendor.Title}</h2>
                        {this.props.user.VendorID === this.state.vendor.ID &&
                          <Link
                            to='/myaccount'
                            className='btn btn-primary'
                            style={{
                              fontSize: '16px',
                              padding: '5px',
                              paddingBottom: '3px',
                              marginLeft: '10px',
                              lineHeight: '16px'
                            }}
                          >
                            <LineAwesome icon='cog' />
                          </Link>
                        }
                      </div>

                    </div>
                  </Col>
                  <Col md={3}>
                    <div className='mb-1'>
                      {vendor.HideAddress ?
                        null
                        :
                        <>
                          <LineAwesome
                            icon='map-marker' /> {this.state.address.Title}

                        </>
                      }
                      {vendor.ContactPerson &&
                        <div className='d-block'>
                          <LineAwesome icon='id-card' /> <i>{vendor.ContactPerson}</i>
                        </div>
                      }
                      {(vendor.Tel && !vendor.HidePhone) &&
                        <ContactButton link={`tel:${vendor.Tel}`}
                                       info={vendor.Tel} icon='phone'
                                       obfuscate
                                       obfuscationString='Contact' />
                      }
                      {(vendor.Email && !vendor.HideEmail) &&
                        <ContactButton link={`mailto:${vendor.Email}`}
                                       info={vendor.Email}
                                       icon='envelope'
                                       obfuscate
                                       obfuscationString='Contact' />
                      }
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Tab.Container defaultActiveKey={this.state.startTab}>
              <section className='offer-dedicated-nav bg-white border-top-0 shadow-sm'>
                <Container>
                  <Row>
                    <Col md={12}>
                                     <span className='restaurant-detailed-action-btn float-right'>
                                         {this.props.isLoggedIn && !this.state.followLoading ?
                                           this.state.followed ?
                                             <button
                                               type='button'
                                               className='border-light-btn mr-1 btn btn-light btn-sm'
                                               onClick={this.removeVendorToCustomer}
                                             >
                                               <LineAwesome icon='star' className='mr-2 text-yellow' /> Als
                                               Favorit entfernen
                                             </button>
                                             :
                                             <button
                                               type='button'
                                               className='border-light-btn mr-1 btn btn-light btn-sm'
                                               onClick={this.addVendorToCustomer}
                                             >
                                               <LineAwesome icon='star' className='mr-2 text-primary' /> Als
                                               Favorit hinzufügen
                                             </button>
                                           :
                                           null
                                         }
                                    </span>
                      <Nav id='pills-tab'>
                        <Nav.Item>
                          <Nav.Link eventKey='first' id='infoTab'>Info</Nav.Link>
                        </Nav.Item>
                        {this.state.images.length ?
                          <Nav.Item>
                            <Nav.Link eventKey='second'>Bilder</Nav.Link>
                          </Nav.Item>
                          :
                          null
                        }
                        {this.state.vendor.AGB || this.state.vendor.ReturnPolicy ?
                          <Nav.Item>
                            <Nav.Link eventKey='agb'>AGB & Widerrufsrecht</Nav.Link>
                          </Nav.Item>
                          :
                          null
                        }

                        {this.state.showProductTab ?
                          <Nav.Item>
                            <Nav.Link eventKey='third' id='ptab'>Produkte</Nav.Link>
                          </Nav.Item>
                          : null
                        }
                        {this.state.vendorservices.length ?
                          <Nav.Item>
                            <Nav.Link eventKey='service'>Dienstleistungen</Nav.Link>
                          </Nav.Item>
                          :
                          null
                        }
                        {this.state.news.length ?
                          <Nav.Item>
                            <Nav.Link eventKey='news'>Beiträge</Nav.Link>
                          </Nav.Item>
                          :
                          null
                        }
                        {this.state.events.length ?
                          <Nav.Item>
                            <Nav.Link eventKey='events'>Veranstaltungen</Nav.Link>
                          </Nav.Item>

                          :
                          null
                        }
                        {this.state.jobs.length ?
                          <Nav.Item>
                            <Nav.Link eventKey='jobs'>Jobs</Nav.Link>
                          </Nav.Item>

                          :
                          null
                        }


                        {this.state.gastroMenus.length ?
                          <>
                            <Nav.Item>
                              <div className='gastromenu-devider'>Speisekarten:</div>
                            </Nav.Item>
                            {this.state.gastroMenus.map((item, index) => (
                              <Nav.Item>
                                <Nav.Link eventKey={index}>{item.Title}</Nav.Link>
                              </Nav.Item>
                            ))
                            }
                          </>

                          :
                          null
                        }
                      </Nav>

                    </Col>
                  </Row>
                </Container>
              </section>
              <section className='offer-dedicated-body pt-2 pb-2 mt-4 mb-4'>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className='offer-dedicated-body-left vendor-text'>
                        <Tab.Content className='h-100'>
                          <Tab.Pane eventKey='first'>
                            <div id='restaurant-info'
                                 className='bg-white rounded shadow-sm p-4 mb-4'>
                              <Row>
                                <Col xs={12}>
                                  {/* <h1>{this.state.vendor.Title}</h1> */}
                                  {/* {this.state.vendor.Tel ? */}
                                  {/*    <p className="mb-2 text-black"> */}
                                  {/*        <LineAwesome */}
                                  {/*            icon="phone" */}
                                  {/*            className='text-primary mr-2' */}
                                  {/*        /> {this.state.vendor.Tel} */}
                                  {/*    </p> */}
                                  {/*    : */}
                                  {/*    null */}
                                  {/* } */}
                                  {/* {this.state.vendor.Email ? */}
                                  {/*    <p className="mb-2 text-black"> */}
                                  {/*        <LineAwesome */}
                                  {/*            icon="envelope" */}
                                  {/*            className='text-primary mr-2' */}
                                  {/*        /> {this.state.vendor.Email} */}
                                  {/*    </p> */}
                                  {/*    : */}
                                  {/*    null */}
                                  {/* } */}
                                  <div
                                    className='mt-3 mb-3'
                                    dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(this.state.vendor.Description) }}
                                  />

                                </Col>
                              </Row>
                            </div>
                            {this.state.vendor.DeliveryServiceText ?
                              <div ref={ref}
                                   className='deliver-info bg-white rounded shadow-sm p-4 mb-4'>
                                <h4>Lieferservice</h4>
                                <span
                                  dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(this.state.vendor.DeliveryServiceText) }} />
                              </div>
                              :
                              null
                            }
                            {this.state.address.Latitude && !this.state.vendor.HideAddress ?
                              <Map
                                heightStyle='400px'
                                center={{
                                  lat: this.state.address.Latitude,
                                  lng: this.state.address.Longitude
                                }}
                                zoom={12}
                              >
                                <GoogleMarker
                                  lat={this.state.address.Latitude}
                                  lng={this.state.address.Longitude}
                                  text={this.state.vendor.Title}
                                  title={this.state.vendor.Title}
                                  image={this.state.logo}
                                  desc={this.state.vendor.ShortDescription}
                                  linkTo={`/haendler/${this.state.vendor.URLSegment}`}
                                  linkTitle='Unternehmerprofil anzeigen'
                                  address={this.state.address.Title}
                                />
                              </Map>

                              :
                              null
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey='second'>
                            <div className='p-4 mb-3'>
                              {this.state.images.length <= 0 ?
                                <p>Keine Bilder gefunden</p>
                                :
                                <SimpleGallery images={this.state.images} />
                              }

                            </div>
                          </Tab.Pane>
                          {this.state.showProductTab ?
                            <Tab.Pane eventKey='third'>
                              {this.state.loadingProducts ?
                                <Spinner animation='border' role='status'
                                         className='mx-auto d-block align-self-center'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner>
                                :
                                this.state.saleProducts.length ?
                                  <>
                                    <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                      <h6 className='mb-3'>Angebote</h6>
                                      <ItemsCarousel items={this.state.saleProducts} />
                                    </div>
                                  </>
                                  :
                                  null

                              }
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <Row>
                                  <h5 className='mb-4 mt-3 col-md-12'>Produkte</h5>
                                  {this.state.loadingProducts ?
                                    <Spinner animation='border' role='status'
                                             className='mx-auto d-block align-self-center'>
                                      <span className='sr-only'>Loading...</span>
                                    </Spinner>
                                    :
                                    this.state.products.length ?
                                      this.state.products.map((product, index) => (
                                        <Col xl={3} lg={4} sm={6}
                                             className='mb-4 pb-2'
                                             key={product.ID}>
                                          <ProductCardItem
                                            productID={product.ID}
                                            addressID={product.AddressID}
                                            priceInfoText={product.PriceInfoText}
                                            title={product.Title}
                                            subTitle=''
                                            imageAlt={product.Title}
                                            image={product.PreviewImage}
                                            imageClass='img-fluid item-img'
                                            linkUrl={`/produkt/${product.URLSegment}`}
                                            price={`€ ${product.Price.toFixed(2)}`}
                                            vendorID={product.VendorID}
                                            categoryID={product.CategoryID}
                                            distance='10km NOCH BERECHENEN!'
                                            latitude={product.Latitude}
                                            longitude={product.Longitude}
                                            showPromoted={product.Promoted}
                                            showSale={product.Sale}
                                            refPrice={product.RefPrice}
                                            requestOnly={product.RequestOnly}
                                            priceText={product.PriceText}
                                            favIcoIconColor='text-primary'
                                          />
                                        </Col>
                                      ))
                                      :
                                      <Col xs={12}>
                                        <p><Trans i18nKey={'vendorDetail.noProducts'}>Dieses Unternehmen hat noch keine
                                          Produkte in seinem Onlineshop</Trans></p>
                                      </Col>
                                  }
                                </Row>
                              </div>
                            </Tab.Pane>
                            : null
                          }
                          <Tab.Pane eventKey='agb'>
                            {this.state.vendor.AGB ?
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <div
                                  className='mt-3 mb-3'
                                  dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(this.state.vendor.AGB) }}
                                />
                              </div>
                              :
                              null
                            }
                            {this.state.vendor.ReturnPolicy ?
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <div
                                  className='mt-3 mb-3'
                                  dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(this.state.vendor.ReturnPolicy) }}
                                />
                              </div>
                              :
                              null
                            }

                          </Tab.Pane>
                          {this.state.jobs.length ?
                            <Tab.Pane eventKey='jobs'>
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <Row>
                                  {this.state.jobs.map((item) =>
                                    <div className='col-lg-4 mb-4' key={item.ID}>
                                      <JobCardItem
                                        title={item.Title}
                                        subTitle={item.CategoryTitle}
                                        imageAlt={item.Title}
                                        image={item.PreviewImage}
                                        imageClass='item-img'
                                        linkUrl={`/news/${item.URLSegment}`}
                                        date={item.Date}
                                        desc={item.Summary ?? ''}
                                        job={item.Job}
                                        vendorTitle={item.VendorTitle}
                                        vendorId={item.VendorID}
                                        cityText={item.Address ? item.Address.City ?? '' : ''}
                                        latitude={item.Address.Latitude}
                                        longitude={item.Address.Longitude}
                                        showPromoted={item.Promoted}
                                        refPrice={item.RefPrice}
                                        favIcoIconColor='text-primary'
                                      />
                                    </div>
                                  )}
                                </Row>

                              </div>
                            </Tab.Pane>

                            :
                            null
                          }
                          {this.state.news.length ?
                            <Tab.Pane eventKey='news'>
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <Row>
                                  {this.state.news.map((item, index) =>
                                    <div className='col-lg-4 mb-4' key={item.ID}>
                                      <NewsCardItem
                                        title={item.Title}
                                        subTitle=''
                                        imageAlt={item.Title}
                                        image={item.PreviewImage}
                                        imageClass='img-fluid item-img'
                                        linkUrl={('/news/') + item.URLSegment}
                                        date={item.Date}
                                        job={item.Job}
                                        vendorTitle={item.VendorTitle}
                                        desc={item.Summary ?? ''}
                                        cityText={item.Address ? item.Address.City ?? '' : ''}
                                        latitude={item.Address.Latitude}
                                        longitude={item.Address.Longitude}
                                        refPrice={item.RefPrice}
                                        favIcoIconColor='text-primary'
                                      />
                                    </div>
                                  )}
                                </Row>

                              </div>
                            </Tab.Pane>

                            :
                            null
                          }
                          {this.state.vendorservices.length ?
                            <Tab.Pane eventKey='service'>
                              <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                <Row>
                                  {this.state.vendorservices.map((item, index) =>
                                    <div className='col-lg-4 mb-4' key={item.ID}>
                                      <NewsCardItem
                                        title={item.Title}
                                        subTitle=''
                                        imageAlt={item.Title}
                                        image={item.PreviewImage}
                                        imageClass='img-fluid item-img'
                                        linkUrl={`/news/${item.URLSegment}`}
                                        date={item.Date}
                                        job={item.Job}
                                        vendorTitle={item.VendorTitle}
                                        desc={item.Summary ?? ''}
                                        cityText={item.Address ? item.Address.City ?? '' : ''}
                                        latitude={item.Address.Latitude}
                                        longitude={item.Address.Longitude}
                                        showPromoted={item.Promoted}
                                        refPrice={item.RefPrice}
                                        favIcoIconColor='text-primary'
                                      />
                                    </div>
                                  )}
                                </Row>

                              </div>
                            </Tab.Pane>

                            :
                            null
                          }
                          <Tab.Pane eventKey='events'>
                            <div className='bg-white rounded shadow-sm p-4 mb-3'>
                              {this.state.events.map((event, index) =>
                                <div className='vendor-event' key={index}>
                                  <Row className='mb-3'>
                                    <Col lg={2} className='vendor-event-image-holder'>
                                      {event.PreviewImage ?
                                        <Link to={`/events/${event.URLSegment}`}
                                              target='_blank'>
                                          <LazyImage fluid src={event.PreviewImage} />
                                        </Link>
                                        :
                                        null
                                      }
                                    </Col>
                                    <Col lg={10}>
                                      <p><b>{event.Title}</b></p>
                                      <i>{event.EventDateNice}{event.EventEndDateNice ? (` - ${event.EventEndDateNice}`) : null} / {event.LocationName}</i>
                                      <br />
                                      <br />
                                      <p className='pre-wrap'>{event.Summary}</p>

                                    </Col>
                                    <Col xs={12} className='text-right'>
                                      <Link to={`/events/${event.URLSegment}`}>
                                        mehr
                                      </Link>
                                    </Col>
                                    <Col xs={12}>
                                      <hr />
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </Tab.Pane>
                          {this.state.gastroMenus.length ?
                            this.state.gastroMenus.map((item, index) => (
                              <Tab.Pane eventKey={index}>
                                <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                  <div className='text-right'><a
                                    href='https://api.region-im-netz.com/assets/WKO_ALLERGENE_PLAKAT-A3_OOe.PDF'
                                    target='_blank'
                                    rel='noreferrer'><b>Allergeninfo <LineAwesome
                                    icon='info-circle' /></b></a></div>
                                  <h3 className='text-center'>{item.Title}</h3>
                                  <hr />
                                  <div className='gastro-menu-container'>
                                    {typeof item.Categories !== 'undefined' && item.Categories.length ?
                                      item.Categories.map((category) => (
                                        <div key={category.ID}
                                             className='gastro-category-container'>
                                          <h4>{category.Title}</h4>
                                          <div
                                            className='gastro-food-container pt-4 pb-3'>
                                            {typeof category.Foods !== 'undefined' && category.Foods.length ?
                                              category.Foods.map((food) => (
                                                <div key={food.ID}>
                                                  <Row
                                                    className='justify-content-between pb-3'>
                                                    <Col xs={9}>
                                                      <b>{food.Title}</b>
                                                      <div
                                                        dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(food.DescriptionRAW) }}
                                                      />
                                                      {food.AllergenString.length ?
                                                        <div
                                                          className='mt-2'>
                                                          {food.AllergenString}
                                                        </div>
                                                        :
                                                        null
                                                      }
                                                    </Col>
                                                    <Col xs={3}>
                                                      <div
                                                        className='gastro-price w-100 text-right'>
                                                        <b>{`€ ${food.Price.toFixed(2)}`}</b>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              ))
                                              :
                                              null
                                            }
                                            <hr />
                                          </div>
                                        </div>
                                      ))
                                      :
                                      null
                                    }
                                  </div>
                                </div>
                              </Tab.Pane>
                            ))
                            : null
                          }
                        </Tab.Content>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Tab.Container>
          </> :
          <>
            <NotFound text='Der Händler konnte leider nicht gefunden werden' />
          </>
        }
      </>

    );
  }
}

const mapStateToProps = (state: RootAppState): MappedProps => ({
  isLoggedIn: state?.user?.isLoggedIn,
  memberID: state?.user?.memberID,
  authKey: state?.user?.authKey,
  user: state?.user?.user
});

const mapDispatchToProps = (dispatch: any): MappedDispatch => ({
  setCookieOverlayState: (setCookieOverlayState: boolean) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState))
});
const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);


export default connector(VendorDetail);

type PropsFromRedux = ConnectedProps<typeof connector>

interface State {
  loading: boolean;
  vendor: any;
  jobs: Array<any>;
  gastroMenus: Array<any>;
}

type OwnProps = {}

type MappedDispatch = {
  setCookieOverlayState: any
}

type MappedProps = {};

export type VendorDetailProps = PropsFromRedux & MappedProps & OwnProps;
