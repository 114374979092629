import React, {Component} from "react";
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {logError} from "../../utils/Logging";

export default class FooterMenu extends Component {

    state = {
        items: []
    }

    componentDidMount() {
        ApiService.client.get('/Menu/' + this.props.id)
            .then((response) => {
                this.setState({items: response.data.Pages});
            })
            .catch((error) => {
                logError(error)
            })
    }

    render() {
        return (
            <Col md={2} sm={4} xs={6} className="pb-3">
                <h6 className="mb-3">{this.props.title}</h6>
                <ul>
                    {this.state.items.map((item, index) => {
                        return (
                            <li key={item.ID}>
                                <Link to={'/content/' + item.URLSegment}>{item.Title}</Link>
                            </li>
                        );
                    })}
                </ul>
            </Col>
        );
    }
}
