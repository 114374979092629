import React, { Component } from 'react';
import { Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import LineAwesome from '../../components/LineAwesome/LineAwesome';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { ApiService } from '../../services/ApiService';
import { toastr } from 'react-redux-toastr';
import UserActions from '../../stores/user/actions';
import { connect } from 'react-redux';
import SofortPaymentForm from './Stripe/SofortPaymentForm';
import { isAndroid, isChrome, isEdge, isIOS, isSafari } from 'react-device-detect';
import EPSForm from './Stripe/EPSForm';
import PaymentButton from './Stripe/PaymentButton';
import CreditCardPaymentForm from './Stripe/CreditCardPaymentForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stripeClientSecret: '',
      transfer_group: '',
      paymentIntentID: '',
      loading: true,
      error: ''
    };
  }

  componentDidMount() {
    if (this.props.basketID) {
      this.fetchPaymentIntent();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.user.StripeCustomerID !== prevProps.user.StripeCustomerID ||
      this.props.basketTotal !== prevProps.basketTotal
    ) {
      this.fetchPaymentIntent();
    }
  }

  fetchPaymentIntent() {
    if (typeof this.props.basketTotal !== 'undefined' && typeof this.props.basketID !== 'undefined' && this.props.basketTotal) {
      ApiService
        .authorizedClient(this.props.authKey)
        .get('/Basket/' + this.props.basketID + '/paymentIntent')
        .then(
          (results) => {
            this.setState({
              paymentIntentID: results.data.id,
              stripeClientSecret: results.data.client_secret,
              transfer_group: results.data.transfer_group
            });
          },
          (error) => {
            let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
            if (error.response && error.response.data && error.response.data.message) {
              errorMsg = error.response.data.message;
            }
            toastr.error('Hoppla', errorMsg);
            this.setState({ loading: false, error: '' });
          }
        );
    }
  }

  render() {
    return (
      <div className='osahan-payment'>
        <div className='offer-dedicated-body'>
          <h4 className='mb-1'>Zahlungsmethode wählen</h4>
          {this.state.stripeClientSecret ?
            <Elements stripe={stripePromise}>
              <Tab.Container id='left-tabs-example' defaultActiveKey={'billing_' + this.props.billingID}>
                <Row>
                  <Col sm={4} className='pr-0'>
                    <Nav variant='pills' className='flex-column'>
                      <Nav.Link eventKey='billing_1'>
                        <LineAwesome icon='credit-card' />
                        Kredit- / Debitkarte
                      </Nav.Link>
                      {isEdge || isChrome || isAndroid || isIOS || isSafari ?
                        <Nav.Link eventKey='billing_2'>
                          {isEdge ?
                            <><LineAwesome iconStyle={'lab'} icon='microsoft' /> Microsoft
                              Pay</>
                            :
                            isChrome || isAndroid ?
                              <><LineAwesome iconStyle={'lab'} icon='google' /> Google
                                Pay</>
                              :
                              isIOS || isSafari ?
                                <><LineAwesome iconStyle={'lab'}
                                               icon='apple-pay' /> Apple Pay</>
                                :
                                ''
                          }
                        </Nav.Link>
                        :
                        null
                      }
                      <Nav.Link eventKey='billing_3'><LineAwesome icon='university' /> Sofort</Nav.Link>
                      <Nav.Link eventKey='billing_4'><LineAwesome
                        icon='university' /> Onlinebanking</Nav.Link>
                    </Nav>
                  </Col>
                  <Col sm={8} className='pl-0'>
                    <Tab.Content className='h-100 d-flex align-items-center'>
                      <Tab.Pane transition={false} eventKey='billing_1' className='w-100'>
                        <ElementsConsumer>
                          {({ stripe, elements }) => (
                            <CreditCardPaymentForm
                              stripe={stripe}
                              elements={elements}
                              onSubmitAction={this.props.handleSubmit}
                              buttonDisabled={this.props.buttonDisabled}
                              stripeClientSecret={this.state.stripeClientSecret}
                            />
                          )}
                        </ElementsConsumer>
                      </Tab.Pane>
                      <Tab.Pane eventKey='billing_2' className='w-100'>
                        <ElementsConsumer>
                          {({ stripe, elements }) => (
                            <PaymentButton
                              stripe={stripe}
                              elements={elements}
                              onSubmitAction={this.props.handleSubmit}
                              buttonDisabled={this.props.buttonDisabled}
                              stripeClientSecret={this.state.stripeClientSecret}
                              basketTotal={this.props.basketTotal}
                            />
                          )}
                        </ElementsConsumer>
                      </Tab.Pane>
                      <Tab.Pane eventKey='billing_3' className='w-100'>
                        <ElementsConsumer>
                          {({ stripe, elements }) => (
                            <SofortPaymentForm
                              stripe={stripe}
                              elements={elements}
                              handleSoftSubmitOrder={this.props.handleSoftSubmitOrder}
                              buttonDisabled={this.props.buttonDisabled}
                              stripeClientSecret={this.state.stripeClientSecret}
                              transfer_group={this.state.transfer_group}
                            />
                          )}
                        </ElementsConsumer>
                      </Tab.Pane>
                      <Tab.Pane eventKey='billing_4' className='w-100'>
                        <ElementsConsumer>
                          {({ stripe, elements }) => (
                            <EPSForm
                              stripe={stripe}
                              elements={elements}
                              buttonDisabled={this.props.buttonDisabled}
                              stripeClientSecret={this.state.stripeClientSecret}
                              transfer_group={this.state.transfer_group}
                              paymentIntentID={this.state.paymentIntentID}
                              basketID={this.props.basketID}
                              handleSoftSubmitOrder={this.props.handleSoftSubmitOrder}
                            />
                          )}
                        </ElementsConsumer>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Elements>
            :
            <Spinner animation='border' role='status'
                     className='mx-auto d-block align-self-center'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basketID: state.basket.basketID,
  user: state.user.user,
  authKey: state.user.authKey
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(UserActions.fetchUser())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm);
