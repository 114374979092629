import React, {Component} from 'react';
import {EpsBankElement} from "@stripe/react-stripe-js";
import {Button, Form, Spinner} from "react-bootstrap";
import EpsBankSection from "./EpsBankSelection";

export default class EPSForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            disabled: false,
            succeeded: false,
            stripeError: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async ev => {
        ev.preventDefault();

        const epsBank = this.props.elements.getElement(EpsBankElement);
        const accountHolderName = ev.target['accountholder-name'];

        if (typeof this.props.handleSoftSubmitOrder === "function") {
            this.props.handleSoftSubmitOrder(false);
        } else {
            this.setState({
                processing: false
            });
            return;
        }

        const {error} = await this.props.stripe.confirmEpsPayment(this.props.stripeClientSecret, {
                payment_method: {
                    eps: epsBank,
                    billing_details: {
                        name: accountHolderName.value
                    }
                },
                return_url: window.location.origin + '/checkout?tgcode=' + this.props.transfer_group
            }
        )

        if (error) {
            this.setState({
                stripeError: 'Zahlung fehlgeschlagen ' + error.message,
                processing: false
            })
        }
    }

    render() {
        return (
            <Form id="eps-form" onSubmit={this.handleSubmit}>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="epsAccountHolder"
                        name="accountholder-name"
                        placeholder="Max Mustermann"
                        required
                        autoComplete="name"
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="epsAccountHolder">Name des Kontoinhabers</Form.Label>
                </div>
                <div className="form-group mt-2 mb-3 px-0">
                    <Form.Label>Bank</Form.Label>
                    <EpsBankSection/>
                </div>
                <Button
                    className='btn btn-success btn-block py-3 text-white text-center'
                    disabled={this.props.buttonDisabled || !this.props.stripe}
                    type='submit'
                >
                    {this.state.processing ? (
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        "Zahlungspflichtig bestellen"
                    )}
                </Button>
                <p className='text-center mt-2'>Sie werden auf die Webseite Ihrer Bank weitergeleitet.</p>
            </Form>
        );
    }
}
