import log, { LogLevelDesc } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

export const init = (): void => {
  const level = process.env.REACT_APP_LOG_LEVEL ?? 'debug' as LogLevelDesc;
  log.setLevel(level as LogLevelDesc);
  prefix.reg(log);
  prefix.apply(log, {
    template: '[%t] %l (%n) static text:',
    levelFormatter(logLevel: string) {
      return logLevel.toUpperCase();
    },
    timestampFormatter(date) {
      return date.toISOString();
    }
  });
};

export const logTrace = (...msg: any[]): void => {
  log.trace(msg);
};

export const logDebug = (...msg: any[]): void => {
  log.debug(msg);
};

export const logInfo = (...msg: any[]): void => {
  log.info(msg);
};

export const logWarn = (...msg: any[]): void => {
  log.warn(msg);
};

export const logError = (...msg: any[]): void => {
  log.error(msg);
};




