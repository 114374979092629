import React from 'react';
import PropTypes from 'prop-types';
import LineAwesome from "../LineAwesome/LineAwesome";
import {AnimatePresence, motion} from "framer-motion";
import {HTMLService} from "../../services/HTMLService";
import CreditContainer from '../CreditContainer/CreditContainer';

class PageTitle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpenCredit: false,
      isOpenInfo: false
    };
  }

  render() {
    return (
      <section
        id={'main-background-image'}
        className={'breadcrumb-osahan pt-5 pb-5 bg-white bg-image position-relative text-center' + (this.props.photo ? ' bg-photo' : '')}
        style={this.props.image ? { backgroundImage: `url(${this.props.image})` } : undefined}>
        <div className={(this.props.green ? 'header-bg-green' : 'header-bg') + (this.props.noMarginBottom ? '' : '')}>
          <div className='middle-circle' />
          <div className='text-container'>
            <h1
              className={'pb-2 font-weight-normal nexa ' + (this.props.green ? 'text-primaryDark' : 'text-white') + ' text-shadow'}>{this.props.title}</h1>
            {this.props.subTitle ?
              <h6
                className={'font-weight-normal nexa ' + (this.props.green ? 'text-primaryDark' : 'text-white') + ' text-shadow'}>{this.props.subTitle}</h6>
              : ''
            }
          </div>

                    {this.props.credit &&
                       <CreditContainer credit={this.props.credit}/>
                    }
                    {this.props.infobox &&
                        <div className='info-box-container'>
                            <AnimatePresence exitBeforeEnter={true}>
                                {this.state.isOpenInfo &&
                                    <motion.div key={2}
                                                initial={{width: 0,height: 0}}
                                                animate={{width: 'auto',height: 'auto'}}
                                                exit={{width: 0,height: 0}}
                                                className="info-box-desc"
                                                transition={{ type: "tween" }}
                                    >
                                        <div className='inner' dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.props.infobox)}}/>
                                    </motion.div>
                                }
                            </AnimatePresence>
                            <div className='info-box-icon' onClick={() => this.setState({isOpenInfo: !this.state.isOpenInfo})}><LineAwesome icon='info-circle'/></div>
                        </div>
                    }
                </div>
            </section>
        );
    }
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  image: PropTypes.string
};
PageTitle.defaultProps = {
  subTitle: '',
  photo: false
};

export default PageTitle;
