import React, { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LineAwesome from '../LineAwesome/LineAwesome';

export default class CreditContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenCredit: false
    };
  }

  render() {
    const { isOpenCredit } = this.state;
    const { credit } = this.props;
    return (
      <div className='credit-container d-flex'>
        <AnimatePresence exitBeforeEnter={true}>
          {isOpenCredit &&
            <motion.div key={1}
                        initial={{ width: 0 }}
                        animate={{ width: 'auto' }}
                        exit={{ width: 0 }}
                        className='credit-desc'
                        transition={{ type: 'tween' }}
            >
              <div className='inner'>{credit}</div>
            </motion.div>
          }
        </AnimatePresence>
        <div className='credit-icon' onClick={() => this.setState({ isOpenCredit: !isOpenCredit })}><LineAwesome
          icon='copyright' /></div>
      </div>
    );
  }
}
