/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {EpsBankElement} from '@stripe/react-stripe-js';

const EPS_ELEMENT_OPTIONS = {
    // Custom styling can be passed to options when creating an Element
    style: {
        base: {
            padding: '10px 10px',
            color: '#495057',
            fontSize: '13px',
            fontSmoothing: 'antialiased',
            fontFamily: 'Apple-System, Arial, Helvetica, \'PingFang SC\', \'Hiragino Sans GB\', \'Microsoft YaHei\', STXihei, sans-serif',
            backgroundColor: '#FFFFFF',
            '::placeholder': {
                color: '#848c92'
            },
            ':hover': {
                backgroundColor: '#deebff'
            },
            '::selection': {
                backgroundColor: '#4284ff',
                color: '#ffffff'
            }
        },
    },
};

function EpsBankSection() {
    return (
        <EpsBankElement options={EPS_ELEMENT_OPTIONS}/>
    );
};

export default EpsBankSection;
