import React, { ReactElement } from 'react';
import LineAwesome from '../LineAwesome/LineAwesome';

export const IconButton = (props: IconButtonProps): ReactElement => {
    const { info, icon } = props;
    return (
        <button type='button' className='text-left bg-white btn btn-light btn-sm border-0 p-0'>
            <LineAwesome icon={icon} className="pr-1"/>
            {info}
        </button>
    );
}

export interface IconButtonProps {
    icon: string;
    info: string;
}
