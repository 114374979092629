import React from 'react';
import { Badge, Button, Col, Container, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import './Product.style.scss';
import OwlCarousel from 'react-owl-carousel3';
import Select2 from 'react-select2-wrapper';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApiService } from '../../services/ApiService';
import { HTMLService } from '../../services/HTMLService';
import LazyImage from '../../components/LazyImage/LazyImage';
import Map from '../../components/Map/Map';
import GoogleMarker from '../../components/GoogleMarker/GoogleMarker';
import BasketAction from '../../stores/basket/actions';
import ProductRequestForm from './ProductRequestForm';
import AddToBookmarkCollectionModal from '../../components/Modals/AddToBookmarkCollectionModal';
import ProductCardItem from '../../components/CardItem/ProductCardItem';
import LineAwesome from '../../components/LineAwesome/LineAwesome';
import DefaultImage from '../../assets/img/default_img.png';
import { BasketService } from '../../services/BasketService';
import { logError } from '../../utils/Logging';
import CreditOverlay from '../../components/CreditContainer/CreditOverlay';
import { ContactButton } from '../../components/ContactComponents/ContactButton';
import { NotFound } from '../../components/NotFound/NotFound';
import { Loader } from '../../components/Loader/Loader';
import { HeadTags } from '../../components/Meta/HeadTags';
import { PhotoThumbnails } from '../../components/PhotoThumbnails/PhotoThumbnails';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { If, Then } from 'react-if';

class Product extends React.Component {

  constructor(props) {
    super(props);

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.getNearProducts = this.getNearProducts.bind(this);
    this.handleShippingRateChange = this.handleShippingRateChange.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.handleAdditionalInfoChange = this.handleAdditionalInfoChange.bind(this);

    this.state = {
      product: {},
      vendor: {},
      address: {},
      shippingRates: [],
      shippingRate: 0,
      shippingRateID: 0,
      images: [],
      logo: '',
      total: 0.00,
      quantity: 1,
      imgIndex: 0,
      showBookmarkModal: false,
      buttonDisabled: true,
      products: [],
      loadingProducts: true,
      productLoading: true,
      additionalInfo: ''
    };
  }

  hideBookmarkModal = () => this.setState({ showBookmarkModal: false });

  handleQuantityChange(e) {
    const val = e.target.value;
    this.setState({
      quantity: val
    }, () => {
      this.setTotal();
    });
  }

  updateGeoPosition() {
    if (this.props.coords.geocodeAddress) {
      this.setState({ localAddress: this.props.coords.geocodeAddress.formatted_address ?? '' },
        () => this.setState({
          geolocationAvailable: false
        })
      );
    }
  }

  handleAdditionalInfoChange(e) {
    const text = e.target.value;
    this.setState({ additionalInfo: text });
  }

  componentDidMount() {
    const { urlsegment } = this.props.match.params;
    if (urlsegment) {
      ApiService.client.get(`/Product/?filter[URLSegment]=${urlsegment}`)
        .then((result) => {
          this.setState({
            product: result.data[0],
            total: result.data[0].Price,
            productLoading: false
          });
          this.getHeaderImage(result.data[0].CategoryID);
          this.getVendor(result.data[0].VendorID);
          this.getAddress(result.data[0].AddressID);
          this.getShippingRates(result.data[0].ID);
          this.getImages(result.data[0].ID);
        }).catch((error) => {
        let msg = 'Ein Fehler ist aufgetreten';
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({
            loading: false
          }
        );

        toastr.error('Fehler beim laden des Produktes', msg);
        this.props.history.push('/produkt-not-found');
      });
    }
    if (this.props.coords) {
      this.updateGeoPosition();
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
      this.updateGeoPosition();
    }
  }

  getHeaderImage(catID) {
    if (catID) {
      ApiService.client.get(`/Category/${catID}/headerImage/`)
        .then((response) => {
          this.setState({
            headerImg: response.data.AbsoluteURL
          });
        })
        .catch((error) => {
        });
    }

  }

  getShippingRates(productID) {
    ApiService.client.get(`/Product/${productID}/many/ShippingRates/`)
      .then((response) => {
        let buttonDisabled = true;
        if (!response.data.length) {
          buttonDisabled = false;
        }
        this.setState({
          shippingRates: response.data,
          buttonDisabled
        });
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({ error: t('errors.generalError'), loading: false });
        }
      });
  }

  getImages(productID) {
    ApiService.client.get(`/Product/${productID}/allImages/`)
      .then((response) => {
        this.setState({
          images: response.data
        });
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          this.setState({ error: error.response.data.message, loading: false });
        } else {
          this.setState({ error: t('errors.generalError'), loading: false });
        }
      });

  }

  getVendor(vendorID) {
    if (vendorID) {
      ApiService.client.get(`/Vendor/${vendorID}`)
        .then((result) => {
          this.setState({
            vendor: result.data
          });
          this.loadLogo(result.data.LogoID);
        }).catch((error) => {
        let msg = t('errors.generalError');
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({
            loading: false
          }
        );
        toastr.error(t('errors.product.vendorLoadingError'), msg);
      });
    }
  }

  loadLogo(logoID) {
    if (logoID) {
      ApiService.client.get(`/Image/${logoID}/FitMax/400/400/`)
        .then((result) => {
          this.setState({
            logo: result.data.AbsoluteURL
          });
        }).catch((error) => {
        let msg = t('errors.generalError');
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({ loading: false });
        toastr.error(t('errors.product.imageLoadingError'), msg);
      });
    } else {
      this.setState({
        logo: DefaultImage
      });
    }

  }

  getAddress(addressID) {
    if (addressID) {
      ApiService.client.get(`/Address/${addressID}`)
        .then((result) => {
          this.setState({
            address: result.data,
            center: {
              lat: result.data.Latitude,
              lng: result.data.Longitude
            }
          });
          this.getNearProducts(result.data);
        }).catch((error) => {
        let msg = t('errors.generalError');
        if (error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
        }
        this.setState({
            loading: false
          }
        );
        toastr.error(t('errors.product.addressLoadingError'), msg);
      });
    }
  }

  getNearProducts(address) {
    let initFilter = `?filter[VendorID:not]=0&filter[Inactive]=0&limit=2&filter[ID:not]=${this.props.match.params.id}`;
    if (address.Latitude && address.Longitude) {
      initFilter += `&Distance ASC&lat=${address.Latitude}&lng=${address.Longitude}`;
    }

    ApiService.client.get(`/Product/${initFilter}`)
      .then((result) => {
        this.setState({
          products: result.data,
          loadingProducts: false
        });
        if (typeof this.props.setCountFunction === 'function') {
          this.props.setCountFunction(result.data.length);
        }

      })
      .catch((error) => {
        this.setState({ loadingProducts: false });
      });
  }

  handleShippingRateChange(shippingRate, ID) {
    this.setState(
      {
        shippingRate,
        shippingRateID: ID,
        buttonDisabled: false
      }
      , () => {
        this.setTotal();
      });
  }

  setTotal() {
    this.setState(
      { total: (this.state.quantity * this.state.product.Price) + this.state.shippingRate }
    );
  }

  handleAddToBasket = (basketID) => {
    const payload = [];

    payload.push({
      ProductID: this.state.product.ID,
      BasketID: basketID,
      Quantity: this.state.quantity,
      ShippingRate: this.state.shippingRateID,
      AdditionalInfo: this.state.additionalInfo
    });


    BasketService.modifyItems(
      payload,
      'add',
      basketID,
      this.props.authKey
    ).then((result) => {
      toastr.success('Hinzugefügt', `${this.state.product.Title} wurde zum Warenkorb hinzugefügt`);
      this.props.fetchBasket();
    }).catch((error) => {
      logError(error);
    });
  };

  addToBasket() {
    if (this.props.basketID) {
      this.handleAddToBasket(this.props.basketID);
    } else {
      BasketService.fetchBasket(this.props.authKey).then((result) => {
        if (result?.data?.ID) {
          this.props.setAndFetchBasket(result.data.ID);
          this.handleAddToBasket(result.data.ID);
        }
      }).catch((error) => {
        logError(error);
      });
    }
  }

  render() {
    const {
      loadingProducts,
      product,
      products,
      vendor,
      logo,
      showBookmarkModal,
      address,
      images,
      imgIndex,
      quantity,
      additionalInfo,
      loading,
      shippingRates,
      buttonDisabled,
      total,
      productLoading


    } = this.state;
    const displayProduct = product.VendorID && !vendor.Suspended;
    const { PriceInfoText } = product;

    return (
      <>
        <HeadTags metaDescription={product.title} imageUrl={product.PreviewImage ?? logo} title={product.title} />
        {loadingProducts ? <Loader text='Produkt wird geladen...' /> :
          <>
            {displayProduct ?
              <>
                <>
                  <AddToBookmarkCollectionModal
                    show={showBookmarkModal}
                    productID={product.ID}
                    productTitle={product.Title}
                    onHide={this.hideBookmarkModal}
                  />
                  <section className='restaurant-detailed-banner'>
                    <div className='text-center' />
                    <div className='restaurant-detailed-header'>
                      <Container>
                        <Row className='d-flex align-items-end'>
                          <Col md={12}>
                            <div className='restaurant-detailed-header-right text-right'>
                              <Button
                                variant='primary'
                                type='button'
                                onClick={
                                  () => {
                                    window.scrollTo({
                                      top: document.getElementById('order-form').offsetTop,
                                      behavior: 'smooth'
                                    });
                                  }
                                }
                              >
                                {product.RequestOnly ?
                                  product.PriceText ?
                                    <>{product.PriceText}</>
                                    :
                                    <>{typeof product.Price !== 'undefined' ? product.Price.toFixed(2) : '--'}  </>
                                  :
                                  <>€ {typeof product.Price !== 'undefined' ? product.Price.toFixed(2) : '--'}</>
                                }
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </section>
                  <div className='product-detail-title py-3 bg-white'>
                    <Container>
                      <Row className='d-flex align-items-center'>
                        <Col md={9}>
                          <div className='restaurant-detailed-header-left'>
                            <div className='d-flex align-items-center'>
                              <LazyImage fluid className='mr-3' alt='{product.Title'
                                         src={logo} />
                              <h2>{product.Title ?? ''}</h2>
                            </div>

                          </div>
                        </Col>
                        <Col md={3}>
                          <p className='mb-0'>
                            <LineAwesome icon='store' /> {vendor.Title ?? ''}
                          </p>
                          {vendor.HideAddress && vendor.Title ?
                            null
                            :
                            <p className='mb-1'>
                              <LineAwesome icon='map-marker' /> {address.Title ?? ''}
                            </p>
                          }

                        </Col>

                      </Row>
                    </Container>
                  </div>
                  <Tab.Container defaultActiveKey='description'>
                    <section className='offer-dedicated-nav bg-white border-top-0 shadow-sm'>
                      <Container>
                        <Row>
                          <Col md={12}>
                                <span className='restaurant-detailed-action-btn float-right'>
                                    <Button variant='light' size='sm' className='border-light-btn mr-1' type='button'
                                            onClick={() => this.setState({ showBookmarkModal: true })}>
                                        <LineAwesome icon='heart' className='text-danger' /> Zur Merkliste hinzufügen
                                    </Button>
                                </span>
                            <Nav id='pills-tab'>
                              <Nav.Item>
                                <Nav.Link eventKey='description'><Trans
                                  i18nKey={'product.description'}>Beschreibung</Trans></Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey='vendor'><Trans
                                  i18nKey={'product.vendorInformation'}>Unternehmerinfos</Trans></Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <section className='mt-2'>
                      <Container>
                        <Row>
                          <Col xs={6}>
                            <Button variant='link' size='sm' onClick={() => window.history.back()}>
                              <LineAwesome icon='angle-double-left' /> <Trans i18nKey={'product.backToOverView'}>Zurück
                              zur Übersicht</Trans>
                            </Button>
                          </Col>
                          <Col xs={6} className='text-right'>
                            <Button as={Link}
                                    to={`/haendler/${vendor.URLSegment}/?p=1`} size='sm'>
                              <Trans i18nKey={'product.moreProducts'}>weitere Produkte</Trans> <LineAwesome
                              icon='angle-double-right' />
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <section className='offer-dedicated-body pt-2 pb-2 mb-4'>
                      <Container>
                        <Row>
                          <Col lg={8} md={7}>
                            <div className='offer-dedicated-body-left'>
                              <Tab.Content className='h-100'>
                                <Tab.Pane eventKey='description'>
                                  {images.length ?
                                    <div id='images' className='bg-white rounded shadow-sm p-4 mb-3'>
                                      <div className='outer position-relative'>
                                        <OwlCarousel
                                          className='owl-theme'
                                          ref={ref => this.big = ref}
                                          id='big'
                                          startPosition={imgIndex}
                                          items={1}
                                          nav
                                          dots
                                          loop={false}
                                          responsiveRefreshRate={200}
                                          navText={[
                                            '<i class="la la-arrow-left" aria-hidden="true"/>',
                                            '<i class="la la-arrow-right" aria-hidden="true"/>'
                                          ]}
                                        >
                                          {images.map((item, index) => (
                                            <div className='item' key={index}>
                                              <LazyImage src={item.AbsoluteURL} />
                                            </div>
                                          ))}
                                        </OwlCarousel>
                                        {product.FotoCredits &&
                                          <CreditOverlay credit={product.FotoCredits} />
                                        }
                                        {(product.FotoCreditsRAW && !product.FotoCredits) &&
                                          <CreditOverlay credit={product.FotoCreditsRAW} />
                                        }
                                        <PhotoThumbnails images={images} onThumbnailClick={(index) => {
                                          this.big.to(index, 300);
                                          this.setState({ imgIndex: index });
                                        }} />
                                      </div>
                                    </div>
                                    :
                                    null
                                  }
                                  {product.Description ?
                                    <div id='description'
                                         className='bg-white rounded shadow-sm p-4 mb-5'>
                                      <h5 className='mb-4'><Trans
                                        i18nKey={'product.productInformation'}> Produktinfos</Trans></h5>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: HTMLService.parseVideo(product.Description) }}
                                      />

                                    </div>
                                    :
                                    null
                                  }

                                </Tab.Pane>
                                <Tab.Pane eventKey='vendor'>
                                  <div id='restaurant-info'
                                       className='bg-white rounded shadow-sm p-4 mb-4'>
                                    <Row>
                                      <Col md={7}>
                                        <h5 className='mb-4'>{vendor.Title}</h5>
                                        {(vendor.Tel && !vendor.HideTel) &&
                                          <ContactButton link={`tel:${vendor.Tel}`}
                                                         info={vendor.Tel}
                                                         icon='phone'
                                                         obfuscate
                                                         obfuscationString={'Contact'} />
                                        }
                                        {(vendor.Email && !vendor.HideEmail) &&
                                          <ContactButton link={`mailto:${vendor.Email}`}
                                                         info={vendor.Email}
                                                         icon='envelope'
                                                         obfuscate
                                                         obfuscationString={'Contact'} />
                                        }

                                        <hr className='clearfix' />
                                        <p className='text-black mb-0'>
                                          {vendor.ShortDescription ?? ''}
                                        </p>
                                      </Col>
                                      <Col md={5}>
                                        <div className='gmap_canvas'
                                             style={{ width: '100%', height: '350px' }}>
                                          {vendor.Latitude && !vendor.HideAddress ?
                                            <Map
                                              center={{
                                                lat: vendor.Latitude,
                                                lng: vendor.Longitude
                                              }}
                                              zoom={10}
                                              heightStyle='100%'
                                            >
                                              <GoogleMarker
                                                key={vendor.ID}
                                                lat={vendor.Latitude}
                                                lng={vendor.Longitude}
                                                text={vendor.Title}
                                                title={vendor.Title}
                                                image={vendor.PreviewImage}
                                                desc={vendor.ShortDescription}
                                                linkTo={`/haendler/${vendor.URLSegment}`}
                                                linkTitle={'Unternehmerprofil anzeigen'}
                                              />
                                            </Map>
                                            :
                                            null
                                          }

                                        </div>
                                      </Col>
                                      <Col xs={12}>
                                        <hr className='clearfix' />
                                        <Button as={Link}
                                                to={`/haendler/${vendor.URLSegment}`}
                                                block
                                                variant='outline-primary'><Trans i18nKey={'product.toVendorPage'}> Zur
                                          Unternehmerseite</Trans></Button>
                                      </Col>
                                    </Row>

                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Col>
                          <Col lg={4} md={5} id='order-form'>
                            {!loadingProducts ?
                              <div
                                className='position-relative bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap'>
                                {product.RequestOnly ?
                                  <ProductRequestForm product={product} />
                                  :
                                  <>
                                    <div className='d-flex justify-content-end'>
                                      {product.Promoted ? (
                                          <div className={'text-right'}>
                                            <Badge variant='primary' className='mt-1 mb-1 bigger-badge'>
                                              <Trans i18nKey={'product.topOffers'}> Top Angebote</Trans>
                                            </Badge>
                                          </div>
                                        )
                                        : ''
                                      }
                                      {product.Sale ? (
                                          <div className={'text-right'}>
                                            <Badge variant='danger'
                                                   className='ml-2 mt-1 mb-1 bigger-badge'>
                                              <Trans i18nKey={'product.sale'}> SALE %</Trans>
                                            </Badge>
                                          </div>
                                        )
                                        : ''
                                      }
                                    </div>
                                    <div className='mb-3'>
                                      <h4
                                        className='mb-0'>€ {typeof product.Price !== 'undefined' ? product.Price.toFixed(2) : '--'}</h4>
                                      {product.RefPrice && product.Promoted ?
                                        <del className='text-muted'><h5>statt
                                          €{product.RefPrice.toFixed(2)}</h5></del>
                                        : null
                                      }
                                      <If condition={PriceInfoText}>
                                        <Then>
                                          <div
                                            className='text-black align-self-center'>{PriceInfoText}</div>
                                        </Then>
                                      </If>
                                      <small className='text-black'>inkl.
                                        MwSt {product.TaxRateFormatted ? `(${product.TaxRateFormatted})` : ''}</small>
                                    </div>
                                    <span className='text-black'>Menge:</span>
                                    <Select2
                                      data={Array.from(Array(20), (_, i) => i + 1)}
                                      value={quantity}
                                      options={{
                                        minimumResultsForSearch: Infinity
                                      }}
                                      onChange={this.handleQuantityChange}
                                    />
                                    {product.AdditionalInfoEnabled ?
                                      <div className='form-group m-0 mt-1'>
                                        <div
                                          className='custom-label'>{product.AdditionalInfo}</div>
                                        <Form.Control as='textarea' rows='4'
                                                      placeholder='Zusatzinfo'
                                                      onChange={this.handleAdditionalInfoChange}
                                                      disabled={loading}
                                                      value={additionalInfo}
                                                      className='input-foreground'
                                        />
                                      </div>
                                      :
                                      null
                                    }
                                    <hr />
                                    <p className='text-black'>
                                      <Trans i18nKey={'product.shipping.shipping'}> Lieferung</Trans><br />
                                    </p>
                                    {shippingRates.map((item, index) => (
                                      <Form.Check
                                        key={item.ID}
                                        className='text-black'
                                        type='radio'
                                        label={`${item.Title} (€ ${item.Rate.toFixed(2)})`}
                                        id={item.ID}
                                        onChange={() => this.handleShippingRateChange(item.Rate, item.ID)}
                                        name='deliverOpt'
                                      />
                                    ))}
                                    {buttonDisabled && shippingRates.length ?
                                      <p className='mt-2 text-muted'><Trans
                                        i18nKey={'product.shipping.selectMethod'}> Bitte wähle eine Versandoption
                                        aus</Trans></p>
                                      :
                                      null
                                    }
                                    <hr />
                                    <div className='mb-3'>
                                      <h4 className='mb-0'>Gesamt:
                                        € {total.toFixed(2) ?? '--'}</h4>
                                      <small className='text-black'>inkl.
                                        MwSt {product.TaxRateFormatted ? `(${product.TaxRateFormatted})` : ''}</small>
                                    </div>
                                    <hr />
                                    <Button variant='primary' block onClick={this.addToBasket}
                                            disabled={buttonDisabled}>
                                      <Trans i18nKey={'product.toBasket'}> In den Warenkorb</Trans>
                                    </Button>
                                  </>
                                }
                              </div>
                              :
                              <div
                                className={'position-relative bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap'}>
                                <div className='notAvailable'><Trans i18nKey={'product.notExistingAnymore'}> Das
                                  gesuchte Produkt existiert leider nicht mehr.</Trans></div>
                              </div>
                            }
                            {productLoading ?
                              <Spinner animation='border' role='status' className='mx-auto my-3 d-block'>
                                <span className='sr-only'><Trans
                                  i18nKey={'loadingIndicator.loading'}>Wird geladen...</Trans></span>
                              </Spinner>
                              :
                              <>
                                {products.length > 0 ?
                                  <>
                                    <h4 className='mb-2'><Trans i18nKey={'product.productsNearby'}> Produkte in der
                                      Nähe</Trans></h4>
                                    {

                                      products.map((product, index) => (
                                        <ProductCardItem
                                          addressID={product.AddressID}
                                          priceInfoText={product.PriceInfoText}
                                          key={product.ID}
                                          noHeight100
                                          productID={product.ID}
                                          title={product.Title}
                                          subTitle=''
                                          imageAlt={product.Title}
                                          image={product.PreviewImage}
                                          imageClass='img-fluid item-img'
                                          linkUrl={`/produkt/${product.URLSegment}`}
                                          price={`€ ${product.Price.toFixed(2)}`}
                                          vendorID={product.VendorID}
                                          categoryID={product.CategoryID}
                                          distance='10km NOCH BERECHENEN!'
                                          latitude={product.Latitude}
                                          longitude={product.Longitude}
                                          showPromoted={product.Promoted}
                                          showSale={product.Sale}
                                          refPrice={product.RefPrice}
                                          requestOnly={product.RequestOnly}
                                          priceText={product.PriceText}
                                          favIcoIconColor='text-primary'
                                        />
                                      ))
                                    }
                                  </>
                                  : null
                                }
                              </>

                            }
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  </Tab.Container>
                </>
              </>
              : <NotFound text={'Das Produkt konnte leider nicht gefunden werden'} />}
          </>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  coords: state.coords,
  authKey: state.user.authKey,
  basketID: state.basket.basketID,
  memberID: state.user.memberID
});

const mapDispatchToProps = (dispatch) => ({
  fetchBasket: () => dispatch(BasketAction.fetchBasket()),
  setAndFetchBasket: (basketID) => dispatch(BasketAction.setAndFetchBasket(basketID))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Product));

