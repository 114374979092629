import React, { Component, ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import { ImageProps } from 'react-bootstrap/Image';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class LazyImage extends Component<LazyImageProps, State> {

  constructor(props: LazyImageProps) {
    super(props);
    this.state = {
      loading: true
    };
  }

  handleLoad = (): void => {
    this.setState({ loading: false });
  };

  render(): ReactElement {
    const { loading } = this.state;
    const { className } = this.props;
    return (
      <>
        {loading ?
          <Spinner animation='border' role='status' className='mx-auto my-3 d-block'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          : null
        }

        <LazyLoadImage
          {...this.props}
          className={`${className} img-fluid d-block`}
          // @ts-ignore
          onLoad={this.handleLoad}
        />
      </>
    );
  }
}

export type LazyImageProps = ImageProps & {
  className: string;
}

type State = {
  loading: boolean;
}
