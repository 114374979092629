import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { animations } from 'react-animation';
import {  Row } from 'rsuite';
import DefaultImage from '../../assets/img/platzhalter_produkt.jpg';
import LazyImage from '../../components/LazyImage/LazyImage';
import { getDistanceFromLatLonInKm } from '../../utils/CalculationUtils';
import { ApiService } from '../../services/ApiService';
import { logInfo } from '../../utils/Logging';
import './JobCartItem.scss';
import { getLogoFromId } from '../../services/ApiRequestService';
import { CardItemButtons } from '../../components/CardItem/CardItemButtons';

class JobCardItem extends Component {
  static defaultProps = {
    imageAlt: '',
    imageClass: '',
    imageID: 0,
    vendorID: 0,
    cityText: 0,
    categoryID: 0,
    offerText: '',
    offerColor: 'success',
    subTitle: '',
    distance: '',
    date: '',
    favIcoIconColor: '',
    rating: '',
    latitude: 0,
    longitude: 0,
    showBookmarkModal: false
  };

  state = {
    img: null,
    logoId: 0,
    subLine: '',
    address: {}
  };

  componentDidMount() {
    let vendorFilter = '';
    vendorFilter += `?filter[ID]=${this.props.vendorId}`;
    ApiService.client.get(`/Vendor/${vendorFilter}`)
      .then((result) => {
        if (result.data[0]?.LogoID) {
          this.setState({
              logoId: result.data[0].LogoID
            }, () => {
              if (this.state.logoId !== 0) {
                getLogoFromId(this.state.logoId).then((img) => {
                  this.setState({ img });
                });
              }
            }
          );
        } else {
          this.setState({
            img: DefaultImage
          });
        }
      }).catch((error) => {
      this.setState({
        img: DefaultImage
      });
      logInfo(error);
    });
  }

  render() {
    const { noHeight100, imageAlt, imageClass, linkUrl, vendorTitle } = this.props;
    return (
      <>
        <div
          className={`${!noHeight100 ? 'h-100' : 'mb-3'} list-card bg-white rounded overflow-hidden position-relative shadow-sm job-card-item-wrapper job`}
          style={PRODUCT_ITEM}>
          <div className={`${!noHeight100 ? 'h-100' : ''} d-flex flex-column job-card-item`}>
            <div className='job-card-image-container'>
              <Link to={linkUrl}>
                <LazyImage src={this.state.img}
                           className={`job-card-image ${imageClass}`}
                           alt={imageAlt} />
              </Link>
            </div>
            <div
              className='pt-3 px-3 position-relative d-flex flex-column justify-content-between flex-grow-1'>
              <div className='list-card-body flex-grow-1 d-flex flex-column job-card-body-content'>
                <Row>

                  <div className='justify-content-between d-flex'>
                    <div>
                      <Link to={this.props.linkUrl}>
                        <div className='card-body-text job-card-grey-text'>
                          <span>{this.props.vendorTitle}</span>
                        </div>
                      </Link>
                      <h6 className='mb-1 card-title-header'>
                        <Link
                          to={this.props.linkUrl}
                          className='text-black'>
                          {this.props.title}
                        </Link>
                      </h6>
                    </div>
                  </div>

                </Row>
                <div className='card-body-text'>
                  {this.props.desc &&
                    <p className='job-card-grey-text mb-3'>{this.props.desc}</p>
                  }
                </div>

              </div>
            </div>
            <div className='job-card-bottom product-card-bottom d-flex justify-content-between '>
              {this.props.coords.latitude ?
                <div
                  className='rounded-sm  align-self-center font-bigger'
                >
                                                <span className='font-weight-bold'>
                                                    {
                                                      getDistanceFromLatLonInKm(
                                                        this.props.latitude,
                                                        this.props.longitude,
                                                        this.props.coords.latitude,
                                                        this.props.coords.longitude
                                                      )
                                                    } km
                                                </span>
                  <span className='pr-1 pl-1 font-weight-bold'>|</span>
                  {this.props.cityText &&
                    <span className='font-weight-bold'>{this.props.cityText}</span>
                  }
                </div>
                :
                null
              }
              <CardItemButtons linkUrl={linkUrl} title={this.props.title} previewImage={this.props.image} icon='info-circle'></CardItemButtons>

            </div>
          </div>
        </div>
      </>

    );
  }
}

const mapStateToProps = (state) => ({
  coords: state.coords
});


export default connect(
  mapStateToProps
)(JobCardItem);

const PRODUCT_ITEM = { animation: animations.fadeIn };
