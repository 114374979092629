import React from "react";
import {ApiService} from "../../services/ApiService";
import {Alert, Form} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import UserActions from "../../stores/user/actions";
import BasketAction from "../../stores/basket/actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";

class CheckoutLoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            isPasswordRestMode: false,
            password: '',
            loading: false,
            error:'',
            errors: [],
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordRestSubmit = this.handlePasswordRestSubmit.bind(this);
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({username: text});
    }


    handleSubmit() {
        const me = this;
        me.setState({loading: true});
        ApiService.client.post(
            'Member/authorize',
            {
                'Email': me.state.username,
                'Password': me.state.password,
            }
        ).then(
            (response) => {
                if (response.data.valid === false) {
                    me.setState({loading: false, errors: response.data.messages});
                } else {
                    me.props.login(response.data.Key, response.data.ValidUntil, response.data.MemberID);
                    setTimeout(() => {
                        me.props.fetchBasket();
                    },500)
                    this.props.history.push('/checkout');
                }
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.messages[0].message) {
                    errorMsg = error.response.data.messages[0].message
                }
                me.setState({loading: false, error: errorMsg});
            },
        );
    }

    handlePasswordRestSubmit() {
        const me = this;
        if (!me.state.username) {
            me.setState({loading: false, error: 'Bitte gib deine E-Mail ein '});
            return;
        }
        me.setState({loading: true, error: ''});
        ApiService.client.get('/Member/sendResetEmail?email='+this.state.username)
            .then((response) => {
                me.setState({loading: false, error: '', username: '', isPasswordRestMode: false});
                toastr.success('E-Mail verschickt!', response.data.message)
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            me.setState({loading: false, error: msg});
        })
    }

    render() {
        return (
            <>
                <h4 className="login-heading mb-4">Servus und Hallo!</h4>
                <Form>
                    <div className="form-label-group">
                        <Form.Control
                            type="email"
                            id="inputEmail"
                            placeholder="E-Mail-Adresse"
                            onChange={this.handleEmailChange}
                            value={this.state.username}
                            disabled={this.state.loading}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor="inputEmail">E-Mail-Adresse</Form.Label>
                    </div>
                    {!this.state.isPasswordRestMode ?
                        <div className="form-label-group">
                            <Form.Control
                                type="password"
                                id="inputPassword"
                                placeholder="Passwort"
                                onChange={this.handlePasswordChange}
                                value={this.state.password}
                                disabled={this.state.loading}
                                className='input-foreground'
                            />
                            <Form.Label htmlFor="inputPassword">Passwort</Form.Label>
                        </div>
                        :
                        null
                    }
                    {/* <Form.Check
                                                className='mb-3'
                                                custom
                                                type="checkbox"
                                                id="custom-checkbox"
                                                label="Passwort merken"
                                            />*/}
                    {this.state.error ?
                        <Alert variant="danger">{this.state.error}</Alert>
                        : null
                    }
                    {this.state.isPasswordRestMode ?
                        <div onClick={this.handlePasswordRestSubmit}
                             className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
                            Passwort zurücksetzen
                        </div>
                        :
                        <div onClick={this.handleSubmit}
                             className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Login
                        </div>
                    }
                    {this.state.isPasswordRestMode ?
                        <div className="text-center pt-3">
                                                    <span
                                                        className='text-primary cursor-pointer'
                                                        onClick={() => this.setState({
                                                            isPasswordRestMode: !this.state.isPasswordRestMode
                                                        })}
                                                    ><b>Anmelden</b></span>
                        </div>
                        :
                        <>
                            <div className="text-center pt-3">
                                                        <span
                                                            className='text-primary cursor-pointer'
                                                            onClick={() => this.setState({
                                                                isPasswordRestMode: !this.state.isPasswordRestMode
                                                            })}
                                                        ><b>Passwort vergessen?</b></span>
                            </div>
                        </>
                    }

                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    login: (authKey, validUntil, memberID) => dispatch(UserActions.login(authKey, validUntil, memberID)),
    fetchBasket: () => dispatch(BasketAction.fetchBasket())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CheckoutLoginForm));
