import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import MayLikeItem from '../MayLikeItem/MayLikeItem';
import {withRouter} from "react-router-dom";
import { OWL_CAROUSEL_ITEM_CAROUSEL_OPTIONS } from '../../constants/OwlCarouselSettings';

class ItemsCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.handleRedirect = this.handleRedirect.bind(this)
    }

    handleRedirect(urlsegment) {
        this.props.history.push('/produkt/' + urlsegment);
    }

    render() {
        if (!this.props.items.length) {
            return null;
        }
        return (
            <OwlCarousel nav loop {...OWL_CAROUSEL_ITEM_CAROUSEL_OPTIONS} className="owl-theme owl-carousel-five offers-interested-carousel">
                {this.props.items.map((product, index) => {
                    return (
                        <div className="item cursor-pointer" key={product.ID} onClick={() => this.handleRedirect(product.URLSegment)}>
                            <MayLikeItem
                                title={product.Title}
                                urlsegment={product.URLSegment}
                                produktID={product.ID}
                                price={'€ ' + product.Price.toFixed(2)}
                                image={product.PreviewImage}
                                imageClass='img-fluid'
                                imageAlt={(typeof product.Images !== 'undefined' && typeof product.Images[0] !== 'undefined') ? product.Images[0].Title : product.Title}
                            />
                        </div>
                    )
                })}
            </OwlCarousel>
        );
    }
}



export default (withRouter(ItemsCarousel));
