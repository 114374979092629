import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Col, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
// @ts-ignore
import { animations } from 'react-animation';
import { Else, If, Then } from 'react-if';
import { ApiService } from '../../services/ApiService';
import LazyImage from '../LazyImage/LazyImage';
import DefaultImage from '../../assets/img/platzhalter_produkt.jpg';
import AddToBookmarkCollectionModal from '../Modals/AddToBookmarkCollectionModal';
import LineAwesome from '../LineAwesome/LineAwesome';
import { logError } from '../../utils/Logging';
import { getDistanceFromLatLonInKm } from '../../utils/CalculationUtils';
import { CoordsState, RootAppState } from '../../stores/basket/initialState';
import UserActions from '../../stores/user/actions';
import './ProductCardItem.scss';
import { CardItemButtons } from './CardItemButtons';


class ProductCardItem extends Component<ProductCardItemProps, State> {
  static defaultProps = {
    imageAlt: '',
    imageClass: '',
    productID: 0,
    vendorID: 0,
    categoryID: 0,
    subTitle: '',
    price: '',
    showPromoted: false,
    favIcoIconColor: '',
    rating: '',
    latitude: 0,
    longitude: 0,
    showSale: false,
    cityText: undefined,
    noHeight100: false,
    desc: undefined,
    showDescription: true
  };

  constructor(props: ProductCardItemProps) {
    super(props);
    this.state = {
      subLine: '',
      vendorTitle: '',
      showBookmarkModal: false,
      showSocialModal: false
    };
  }

  componentDidMount(): void {
    const { vendorID, categoryID } = this.props;
    if (vendorID) {
      ApiService.client.get(`/Vendor/${vendorID}`)
        .then((result) => {
          this.setState({
            vendorTitle: result.data.Title
          });

        }).catch((error) => {
        logError(error);
      });
    }

    if (categoryID) {
      ApiService.client.get(`/Category/${categoryID}`)
        .then((result) => {
          this.setState({
            subLine: result.data.Title
          });
        }).catch((error) => {
        logError(error);
      });
    }

  }

  hideBookmarkModal = (): void => this.setState({ showBookmarkModal: false });


  render(): ReactNode {
    const { showSocialModal, showBookmarkModal, vendorTitle, subLine } = this.state;
    const {
      productID,
      title,
      noHeight100,
      rating,
      showPromoted,
      favIcoIconColor,
      showSale,
      linkUrl,
      refPrice,
      image,
      imageClass,
      imageAlt,
      subTitle,
      desc,
      price,
      priceInfoText,
      priceText,
      requestOnly,
      coords,
      latitude,
      longitude,
      showDescription,
      cityText
    } = this.props;

    return (
      <>
        <AddToBookmarkCollectionModal
          show={showBookmarkModal}
          productID={productID}
          productTitle={title}
          onHide={this.hideBookmarkModal}
        />
        <div
          className={`${!noHeight100 ? 'h-100' : 'mb-3'} list-card bg-white rounded overflow-hidden position-relative shadow-sm product-card-item-wrapper`}
          style={PRODUCT_ITEM}>
          <div className={`${!noHeight100 ? 'h-100' : ''} d-flex flex-column product-card-item`}>
            <div className='list-card-image'>
              {rating ? (
                  <div className='star position-absolute'>
                    <Badge variant='success'>
                      <LineAwesome iconStyle='lar' icon='star' /> {rating}
                    </Badge>
                  </div>
                )
                : ''
              }
              <div className={`favourite-heart position-absolute ${favIcoIconColor}`}>
                <LineAwesome icon='heart' className='cursor-pointer'
                             onClick={() => this.setState({ showBookmarkModal: true })} />
              </div>
              {showPromoted ? (
                  <div className='member-plan position-absolute'
                  >
                    <Badge variant='primary'>TOP</Badge>
                  </div>
                )
                : ''
              }
              {showPromoted ?
                showSale ?
                  <div className='member-plan position-absolute'
                       style={{ left: 50 }}>
                    <Badge variant='danger'>SALE %</Badge>
                  </div>
                  : ''

                :
                showSale ?
                  <div className='member-plan position-absolute'>
                    <Badge variant='danger'>SALE %</Badge>
                  </div>
                  : ''

              }
              <Link to={linkUrl}>
                <LazyImage src={image ?? DefaultImage}
                           className={`product-card-img ${imageClass}`}
                           alt={imageAlt} />
              </Link>
            </div>
            <div
              className='pt-3 px-3 pb-0 position-relative d-flex flex-column justify-content-between flex-grow-1'>
              <div className='list-card-body'>
                <Row className='card-body-text'>
                  <Col lg={12}>
                    {refPrice && showSale ? (
                        <div className='list-card-badge mb-1 d-flex justify-content-end'>
                                        <span>
                                            <Badge variant='success'>ANGEBOT</Badge> <small><del className='text-muted'>statt €{refPrice.toFixed(2)}</del></small>
                                        </span>
                        </div>
                      )
                      : ''
                    }
                  </Col>
                  <Col lg={12}>
                    <div className='justify-content-between d-flex'>
                      <div>
                        {vendorTitle &&
                          <p className='header-small'>{vendorTitle}</p>
                        }
                        <h6 className='mb-1 card-title-header'><Link to={linkUrl}
                                                                     className='text-black'>{title}</Link></h6>
                      </div>
                    </div>
                  </Col>
                </Row>

                {subLine ?
                  <p className='text-gray mb-3 header-small'>{subLine}</p>
                  :
                  subTitle ? (
                      <p className='text-gray mb-3 header-small'>{subTitle}</p>
                    )
                    : ''
                }

                {(showDescription && desc) ?
                  <p className='text-gray mb-3'>{desc}</p>
                  :
                  null
                }

              </div>
              <div className='list-card-body'>
                <div className='text-gray time mb-0 product-card-bottom'>
                  <div className='justify-content-between align-items-center d-flex'>
                    <If condition={requestOnly}>
                      <Then>
                        <Link to={linkUrl} className='request-link'>Anfrage stellen</Link>
                      </Then>
                      <Else>
                        <If condition={price}>
                          <Then>
                                <div
                                  className='d-flex flex-column justify-content-center align-items-center'>
                                                <div
                                                  className='font-weight-bold align-self-start font-bigger'> {price}</div>
                                   <If condition={priceInfoText}>
                                     <Then>
                                    <div
                                      className='product-price-info-text align-self-center'>{priceInfoText}</div>
                                       </Then>
                                   </If>
                                </div>
                          </Then>
                        </If>
                      </Else>
                    </If>
                    <CardItemButtons linkUrl={linkUrl} title={title} previewImage={image}
                                     icon={requestOnly ? 'info-circle' : 'shopping-cart'} />
                  </div>
                  {coords.latitude && getDistanceFromLatLonInKm(
                    latitude,
                    longitude,
                    coords.latitude,
                    coords.longitude
                  ) !== '---' ?
                    <span
                      className='rounded-sm pb-1 pt-1 pr-2- align-self-center text-center distance-text '>
                                        <span className='font-weight-bold'>
                                            {
                                              getDistanceFromLatLonInKm(
                                                latitude,
                                                longitude,
                                                coords.latitude,
                                                coords.longitude
                                              )
                                            } km
                                        </span>
                      {cityText &&
                        <>
                          <span className='pr-1 pl-1 font-weight-bold'>|</span>
                          <span className='font-weight-bold'>{cityText}</span>
                        </>
                      }

                      </span>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

const PRODUCT_ITEM = { animation: animations.fadeIn };


const mapStateToProps = (state: RootAppState): MappedProps => ({
  coords: state?.coords
});

const mapDispatchToProps = (dispatch: any): MappedDispatch => ({
  fetchUser: () => dispatch(UserActions.fetchUser())
});
const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(ProductCardItem);

type PropsFromRedux = ConnectedProps<typeof connector>

type MappedProps = {
  coords?: CoordsState,
};

type MappedDispatch = {
  fetchUser: any
}

type State = {
  subLine: string;
  vendorTitle: string;
  showBookmarkModal: boolean;
  showSocialModal: boolean;
}

type OwnProps = {
  title: string,
  productID?: number,
  imageAlt?: string,
  image: string,
  imageClass?: string,
  linkUrl: string,
  subTitle?: string,
  price?: string,
  showPromoted?: boolean,
  favIcoIconColor?: string,
  rating?: string,
  showSale?: boolean,
  vendorID?: string;
  categoryID?: string;
  noHeight100?: boolean;
  refPrice: number;
  desc?: string;
  priceInfoText: string;
  priceText: string
  requestOnly: boolean,
  coords: any; // TODO: replace with correct type
  latitude?: number;
  longitude?: number;
  cityText?: string;
  showDescription?: boolean;
}

export type ProductCardItemProps = PropsFromRedux & MappedProps & OwnProps;
