import React from 'react';
import {Col, Row} from 'react-bootstrap';
import LazyImage from "../LazyImage/LazyImage";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class SimpleGallery extends React.Component {
    static defaultProps = {
        images: []
    };

    state = {
        resizedImages: [],
        lightBoxOpen: false,
        imgIndex:0,
    }


    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let images = [];
        /*if (JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images)) {
            for(let i = 0;i < this.props.images.length; i++){
                let img = this.props.images[i];
                ApiService.client.get('/Image/'+img.ID+'/Pad/600/600/FFFFFF/100')
                    .then((result) => {
                        images.push(result.data);
                        this.setState({
                            resizedImages: images
                        })
                    }).catch((error) => {
                        images.push({AbsolutURL: '', ID:9999999999})
                        this.setState({
                            resizedImages: images
                        })
                    console.log(error)
                })
            }
        }*/

    }

    openLightBoxAtImg(imgIndex){
        this.setState({
            lightBoxOpen: !this.state.lightBoxOpen,
            imgIndex: imgIndex
        })
    }


    render() {
        return (
            <Row>
                {
                    this.props.images.map((img, index) => {
                        return (
                            <Col md={4} key={img.ID} className='mb-3'>
                                <div  className='bg-white d-flex align-items-center h-100 shadow p-2'>
                                    <LazyImage className='cursor-pointer' fluid src={img.AbsoluteURL} onClick={() => this.openLightBoxAtImg(index)}/>
                                </div>
                            </Col>
                        )

                    })
                }
                {this.state.lightBoxOpen && (
                    <Lightbox
                        mainSrc={this.props.images[this.state.imgIndex].AbsoluteURL}
                        nextSrc={this.props.images[(this.state.imgIndex + 1) % this.props.images.length].AbsoluteURL}
                        prevSrc={this.props.images[(this.state.imgIndex + this.props.images.length - 1) % this.props.images.length].AbsoluteURL}
                        onCloseRequest={() => this.setState({ lightBoxOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                imgIndex: (this.state.imgIndex + this.props.images.length - 1) % this.props.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                imgIndex: (this.state.imgIndex + 1) % this.props.images.length,
                            })
                        }
                    />
                )}
            </Row>

        );
    }
}

export default SimpleGallery;
