import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Col, Row} from "react-bootstrap";

type Props = {
    title: string,
    subTitle: string,
    className: string,
    image: string,
    imageClass: string,
    imageAlt: string,
    badgeClass: string,
    badgeValue: number,
    linkUrl: string,
    linkText: string,
};

export default class CartDropdownHeader extends Component<Props> {
    static defaultProps = {
        subTitle: '',
        className: '',
        imageAlt: '',
        image: '',
        imageClass: '',
        badgeClass: '',
        badgeValue: 0,
        linkUrl: '',
        linkText: '',
    }

    render() {
        return (
            <div className={this.props.className}>
                <Row className='align-items-center'>
                    <Col lg={3}>
                        {
                            this.props.image ?
                                <img alt="" src={this.props.image} className={this.props.imageClass}/> : ''
                        }
                    </Col>
                    <Col lg={9}>
                        <h6 className="mb-0">{this.props.title}</h6>
                        {this.props.linkUrl ?
                        <small>
                            <Link className="text-primary font-weight-bold"
                                  to={this.props.linkUrl}>{this.props.linkText}</Link>
                        </small> : ''
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}
