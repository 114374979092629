import {combineReducers} from 'redux';
import rootSaga from '../sagas';
import {reducer as UserReducer} from './user/reducers';
import {reducer as CoordsReducer} from './coords/reducers';
import {reducer as BasketReducer} from './basket/reducers';
import {reducer as CheckoutReducer} from './checkout/reducers';
import {reducer as BookmarkCollectionReducer} from './bookmarkCollections/reducers';
import {reducer as DSGVOReducer} from './dsgvo/reducers';
import configureStore from './CreateStore';
import {reducer as toastrReducer} from 'react-redux-toastr'
import {locationReducer as location} from "redux-saga-location";

export default () => {
    const appReducer = combineReducers({
        /**
         * Register your reducers here.
         * @see https://redux.js.org/api-reference/combinereducers
         */
        user: UserReducer,
        toastr: toastrReducer,
        coords: CoordsReducer,
        dsgvo: DSGVOReducer,
        location: location,
        basket: BasketReducer,
        checkout: CheckoutReducer,
        bookmarkCollection: BookmarkCollectionReducer,
    });

    const rootReducer = (state, action) => {
        return appReducer(state, action);
    };
    return configureStore(rootReducer, rootSaga);
}
