import { ApiService } from '../services/ApiService';

const geocodeLocation = async (latitude: number, longitude: number): Promise<any> => {
  const response: any = await ApiService.geocodeFromLatLng(latitude, longitude);
  if (response.data && response.data.results && response.data.results[0]) {
    return Promise.resolve(response.data.results[0]);
  }
  return Promise.reject();
};

export { geocodeLocation };
