import React, { ReactElement } from 'react';
import Obfuscation from './Obfuscation';
import { IconButton } from '../Button/IconButton';

export const ContactButton = (props: ContactButtonProps): ReactElement => {
    const { icon, info, link, obfuscate, obfuscationString } = props;
    return (
        <div>
            <Obfuscation link={link} obfuscate={obfuscate} obfuscationString={obfuscationString}>
                <IconButton info={info} icon={icon} />
            </Obfuscation>
        </div>
    );
}

export interface ContactButtonProps {
    icon: string;
    info: string;
    link: string;
    obfuscate: boolean;
    obfuscationString: string;
}
