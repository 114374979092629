import { put, select } from 'redux-saga/effects';
import UserActions from '../stores/user/actions';
import { ApiService } from '../services/ApiService';
import { logError, logInfo } from '../utils/Logging';

const getKey = (state) => state.user.authKey;
const getID = (state) => state.user.memberID;

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* fetchUser() {
  // Dispatch a redux action using `put()`
  // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
  yield put(UserActions.fetchUserLoading());
  const key = yield select(getKey);
  const id = yield select(getID);

  if (id && key) {
    // Fetch user informations from an API
    try {
      const user = yield ApiService.authorizedClient(key).get(`/Member/${id}`);
      yield put(UserActions.fetchUserSuccess(user.data));
    } catch (e) {
      logError(e);
      yield put(UserActions.fetchUserFailure(e.message));
    }
  }
}
