import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
// @ts-ignore
import { animations } from 'react-animation';
import DefaultImage from '../../assets/img/platzhalter_produkt.jpg';
import LazyImage from '../../components/LazyImage/LazyImage';
import { getDistanceFromLatLonInKm } from '../../utils/CalculationUtils';
import { CoordsState, RootAppState } from '../../stores/basket/initialState';
import UserActions from '../../stores/user/actions';
import './NewsCardItem.scss';
import { CardItemButtons } from '../../components/CardItem/CardItemButtons';

class NewsCardItem extends Component<NewsCardItemProps, State> {
  static defaultProps = {
    imageAlt: '',
    imageClass: '',
    cityText: undefined,
    latitude: 0,
    longitude: 0,
    noHeight100: false,
    job: false,
    showDescription: true,
    showDate: false,
    subTitle: undefined
  };

  render(): ReactNode {
    const {
      title,
      noHeight100,
      linkUrl,
      image,
      imageClass,
      imageAlt,
      vendorTitle,
      desc,
      coords,
      latitude,
      longitude,
      cityText,
      job,
      date,
      showDate,
      showDescription,
      subTitle
    } = this.props;
    return (
      <>
        <div
          className={`${!noHeight100 ? 'h-100' : 'mb-3'} list-card bg-white rounded overflow-hidden position-relative shadow-sm news-card-item-wrapper ${job ? 'job' : ''}`}
          style={PRODUCT_ITEM}>
          <div className={`${!noHeight100 ? 'h-100' : ''} d-flex flex-column news-card-item`}>
            <div className='list-card-image'>
              {job ?
                <Link to={linkUrl}>
                  <div className='job-vendor-title'>
                    <span>{title}</span>
                  </div>
                </Link>
                :
                <Link to={linkUrl}>
                  <LazyImage src={image ?? DefaultImage}
                             className={`product-card-img ${imageClass}`}
                             alt={imageAlt} />
                </Link>
              }
            </div>
            <div
              className='pt-3 px-3 position-relative d-flex flex-column justify-content-between flex-grow-1'>
              <div className='list-card-body flex-grow-1 d-flex flex-column justify-content-between'>
                <div className='card-body-text'>
                  {!job && (vendorTitle || title) &&
                    <Row className='card-body-text'>
                      <Col lg={12}>
                        <div className='justify-content-between d-flex'>
                          <div>
                            {vendorTitle &&
                              <p className='header-small'>{vendorTitle}</p>
                            }
                            <h6 className='mb-1 card-title-header'>
                              <Link
                                to={linkUrl}
                                className='text-black'
                              >
                                {job ?
                                  vendorTitle
                                  :
                                  title
                                }
                              </Link>
                            </h6>
                          </div>

                        </div>
                      </Col>
                    </Row>}

                  {subTitle ? (
                      <p className='text-gray mb-3 header-small'>{subTitle}</p>
                    )
                    : ''}
                  {showDate && <p>{date}</p>}
                  {showDescription &&
                    <>
                      <p className='text-gray mb-3'>{desc}</p>
                    </>
                  }
                </div>


                <div className='product-card-bottom d-flex justify-content-between '>
                  {coords.latitude ?
                    <div
                      className='rounded-sm pb-1 pt-1 pr-2- align-self-center font-bigger'
                    >
                                                <span className='font-weight-bold'>
                                                    {
                                                      getDistanceFromLatLonInKm(
                                                        latitude,
                                                        longitude,
                                                        coords.latitude,
                                                        coords.longitude
                                                      )
                                                    } km
                                                </span>
                      {cityText &&
                        <>
                          <span className='pr-1 pl-1 font-weight-bold'>|</span>
                          <span className='font-weight-bold'>{cityText}</span>
                        </>
                      }
                    </div>
                    :
                    null
                  }
                  <CardItemButtons linkUrl={linkUrl} title={title} previewImage={image}
                                   icon='info-circle'></CardItemButtons>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

const mapStateToProps = (state: RootAppState): MappedProps => ({
  coords: state?.coords
});
const mapDispatchToProps = (dispatch: any): MappedDispatch => ({
  fetchUser: () => dispatch(UserActions.fetchUser())
});
const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(NewsCardItem);

type PropsFromRedux = ConnectedProps<typeof connector>

type MappedProps = {
  coords?: CoordsState,
};

type MappedDispatch = {
  fetchUser: any
}


export type OwnProps = {
  title: string,
  imageAlt?: string,
  image: string,
  imageClass?: string,
  linkUrl: string,
  noHeight100?: boolean;
  desc: string;
  coords: any; // TODO: replace with correct type
  latitude?: number;
  longitude?: number;
  cityText?: string;
  job?: boolean;
  vendorTitle: String;
  date: String;
  showDate?: boolean;
  showDescription?: boolean;
  subTitle?: string;
};

type State = {}

export type NewsCardItemProps = PropsFromRedux & MappedProps & OwnProps;


const PRODUCT_ITEM = { animation: animations.fadeIn };
