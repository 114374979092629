import React, {Component} from 'react';
import '../../assets/lineawesome/css/line-awesome.min.css';
import PropTypes from "prop-types";

export default class LineAwesome extends Component {
    render() {
        if (!this.props.icon || typeof this.props.icon != 'string') return null;
        const {icon, size, className, iconStyle, ...restOfProps} = this.props;
        // Look for prefix in icon. Don't prefix it if it's already prefixed
        let iconValue = icon.match(/^la-/) ? ' ' + icon : ` la-${icon}`;
        // Manage Size value
        const totalSize = size ? ` la-${size}` : "";

        const styleVariant = iconStyle ? ` ${iconStyle}` : " las";
        // Manage Flip
        // Handle ClassName
        const cName = className ? `${className} ` : "";
        // Manage Spin value
        return (
            <i {...restOfProps} className={`${cName}${styleVariant}${iconValue}${totalSize}`}/>
        )
    }
}

LineAwesome.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['lg', 'xs', 'sm', 'lx', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x', 'fw']),
    className: PropTypes.string,
    iconStyle: PropTypes.oneOf(['lar', 'las', 'lab', 'la']),
};
