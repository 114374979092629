/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
    latitude: 0,
    longitude: 0,
    custom: false,
    geocodeAddress:{
        formatted_address: '',
        address_components: []
    }
};
