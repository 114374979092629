import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './containers/Root/Root';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'rsuite/dist/styles/rsuite-default.css';
import 'react-select2-wrapper/css/select2.css';
import './theme/main.scss';

import { sentryInit } from './utils/sentry';
import { init } from './utils/Logging';
import { ErrorBoundary } from './utils/ErrorBoundary';
import { initTranslations } from './translations/translations';

init();
sentryInit();
initTranslations();

ReactDOM.render(<ErrorBoundary><Root /></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
