import React, {Component} from 'react';
import {Button, Spinner} from "react-bootstrap";

export default class SofortPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            disabled: false,
            succeeded: false,
            stripeError: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (event) => {
        this.setState({
            disabled: event.empty,
            stripeError: event.error ? event.error.message : "",
        })
    };

    handleSubmit = async ev => {
        ev.preventDefault();
        this.setState({
            processing: true
        })

        if (typeof this.props.handleSoftSubmitOrder === "function") {
            this.props.handleSoftSubmitOrder(false);
        } else {
            this.setState({
                processing: false
            });
            return;
        }

        const {error} = await this.props.stripe.confirmSofortPayment(
            this.props.stripeClientSecret,
            {
                payment_method: {
                    sofort: {
                        country: 'AT'
                    }
                },
                return_url: window.location.origin + '/checkout?tgcode=' + this.props.transfer_group
            }
        )

        if (error) {
            this.setState({
                stripeError: 'Zahlung fehlgeschlagen ' + error.message,
                processing: false
            })
        }
    }

    render() {
        return (
            <form id="sofort-card-form" onSubmit={this.handleSubmit}>
                <Button
                    className='btn btn-success btn-block py-3 text-white text-center'
                    disabled={this.state.processing || this.state.disabled || this.state.succeeded || this.props.buttonDisabled}
                    type='submit'
                >
                    {this.state.processing ? (
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        "Zahlungspflichtig bestellen"
                    )}
                </Button>
                {this.state.stripeError && (
                    <div className="card-error" role="alert">
                        {this.state.stripeError}
                    </div>
                )}
                <p className='text-center mt-2'>Sie werden zu "SOFORT" weitergeleitet.</p>
            </form>
        );
    }
}
